// vendor
import React from 'react';
import cc from 'classcat';

// style
import style from './careersBulletPoints.scss';

function CareersBulletPoints({ bullets }) {
  const bulletsList = bullets.filter((bullet) => !!bullet.trim());

  return (
      <ul className={cc([style.listing, bulletsList.length > 5 || style.small])}>
        {bulletsList.map((bullet, i) => (
          <li className={style.item} key={i.toString()}>
            {bullet}
          </li>
        ))}
      </ul>
  );
}

export default CareersBulletPoints;
