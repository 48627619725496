// vendor
import React, { memo, useState } from 'react';

import Page from '../../components/page';
import StoriesHeader from '../../components/sections/storiesHeader/storiesHeader';
// components
import StoriesFeatured from '../../components/sections/storiesFeatured/storiesFeatured';
import StoriesGrid from '../../components/storiesGrid/storiesGrid';
import StoriesMost from '../../components/sections/storiesMost/storiesMost';
// utils
import { get } from '../../utils/request';
// hooks
// static content
import Tabs from '../../components/sections/Tabs/Tabs';
import StoriesChips from '../../components/sections/storiesChips/storiesChips';
import StoryWhitepaper from '../../components/sections/storyWhitepaper/storyWhitepaper';
import ContactBanner from '../../components/contactBanner/contactBanner';
import dlv from 'dlv';

const Featured = memo(({ data }) => {
  const navLabels = data.navigationLabels || [];
  const categories = data.categories || [];
  const featured = data.featured || [];
  const stories = data.stories || [];
  const whitepaper = data.whitepaper || [];
  const contact = data.contact || [];
  const featured_title = dlv(data, 'featured_title');
  const topics_title = dlv(data, 'topics_title');
  const topics_subtitle = dlv(data, 'topics_subtitle');
  const selectedCategory = dlv(data, 'selectedCategory');
  const whitepaper_title = dlv(data, 'whitepaper_title');
  const most_read_title = dlv(data, 'most_read_title');
  const image = dlv(data, 'most_read_image', null);
  const [category] = useState(selectedCategory);
  const contactCaption = data.contactCaption || null;
  const contactPerson = contact || null;
  const featuredItemsLarge = featured.slice(0, 2).map(item => item.data);
  const featuredItemsSmall = featured.slice(2, 5).map(item => item.data);

  const pages = [
    {
      href: '/insights',
      label: 'Featured',
      type: 'featured',
    },
    {
      href: '/stories',
      label: 'Stories',
      type: 'stories',
    },
    {
      href: '/whitepapers',
      label: 'Downloads',
      type: 'whitepapers',
    },
  ];

  return (
    <Page newsletterHidden={true}>
      <Tabs current="featured" labels={navLabels} pages={pages} has1400MaxWidth />
      <StoriesHeader subHeader={featured_title} />

      {typeof featured !== undefined && true && (
        <>
          <StoriesFeatured coloredBackground={false}>
            <StoriesGrid items={featuredItemsLarge} variation="wider" />
            <StoriesGrid items={featuredItemsSmall} variation="wide" />
          </StoriesFeatured>

          {typeof stories !== 'undefined' && (
            <StoriesFeatured coloredBackground={false}>
              <StoriesMost
                items={stories}
                image={image}
                storiesHomePage={true}
                title={most_read_title}
              />
            </StoriesFeatured>
          )}
        </>
      )}

      <StoriesFeatured coloredBackground={false}>
        <StoryWhitepaper whitepaper={whitepaper} title={whitepaper_title} viewAll />
      </StoriesFeatured>

      <StoriesHeader bg="white" subHeader={topics_title} mainHeader={topics_subtitle} addSpacing />

      <StoriesFeatured coloredBackground={false}>
        <StoriesChips categories={categories} selected={category} whiteBackground={true} />
      </StoriesFeatured>

      {contactPerson && (
        <ContactBanner
          caption={contactCaption ? contactCaption : contactPerson.contactCaption}
          title={contactPerson.contactFormTitle}
          person={contactPerson}
          hubspotFormId={contactPerson.hubspotFormId}
          noMarginBottom
        />
      )}
    </Page>
  );
});

Featured.getPageData = (match, env, location) => {
  return get(`/api/page/insights`);
};

export default Featured;
