// vendor
import React from 'react';

// components
import { Image } from 'dpdk-react-library';
import Button from '../../button/button';
import Heading from '../../heading/heading';
import HTML from '../../html/html';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './storyWhitepaper.scss';
import StoriesHeaderItem from '../storiesHeader/storiesHeaderItem/storiesHeaderItem';
import Link from '../../link';
import Time from '../../time';
import Icon from '../../icon/icon';
import cc from 'classcat';

function StoryWhitepaper({ whitepaper, title, viewAll = false }) {
  const wpTitle = whitepaper && whitepaper.title;
  const image = whitepaper && whitepaper.image;
  const slug = whitepaper && whitepaper.slug;
  const summary = whitepaper && whitepaper.summary;
  const ctaLabel = whitepaper && whitepaper.cta_label;
  const publishedDate = whitepaper && whitepaper.publish_date;

  return (
    <RevealGroup>
      {title && (
        <div>
          <BasicRevealItem duration={1000} variation="left">
            <div className={style.headerContainer}>
              <StoriesHeaderItem title={title} />
              <Link
                className={cc([style.readmoreLink, style.readMoreLinkDesktop])}
                href="/whitepapers"
              >
                View all white papers
                <Icon className={style.icon} type="arrowRight" />
              </Link>
            </div>
          </BasicRevealItem>
        </div>
      )}

      <BasicRevealItem duration={1000} variation="left">
        <div className={style.whitepaper}>
          <div className={style.imageWrapper}>
            {image && <Image {...image} width="100%" height="100%" />}
          </div>

          <div className={style.content}>
            <BasicRevealItem variation="right">
              <Heading level="h3" size="h6" weight="semibold" caps color="green">
                white paper
              </Heading>
              {publishedDate && (
                <Heading
                  className={style.pdate}
                  level="h6"
                  size="h6"
                  weight="semibold"
                  color="black"
                >
                  <Time timestamp={publishedDate} day month year />
                </Heading>
              )}
            </BasicRevealItem>

            {wpTitle && (
              <BasicRevealItem variation="right">
                <Heading level="h2" size="h4" className={style.heading}>
                  {wpTitle}
                </Heading>
              </BasicRevealItem>
            )}

            {summary && (
              <BasicRevealItem variation="right">
                <HTML className={style.text}>{summary}</HTML>
              </BasicRevealItem>
            )}

            <BasicRevealItem variation="right">
              <Button href={slug} icon="arrowRight" boxed className={style.button}>
                {ctaLabel !== null ? ctaLabel : 'Download the PDF'}
              </Button>
            </BasicRevealItem>
          </div>
        </div>
      </BasicRevealItem>

      {viewAll && (
        <Link className={cc([style.readmoreLink, style.readMoreLinkMobile])} href="/whitepapers">
          View all white papers
          <Icon className={style.icon} type="arrowRight" />
        </Link>
      )}
    </RevealGroup>
  );
}

export default StoryWhitepaper;
