// vendor
import React, { useEffect } from 'react';
import { Redirect } from '@reach/router';

// context
import routeContext from '../context/routeContext';

// hooks
import useData from '../hooks/useData';
import useDataFetcher from '../hooks/useDataFetcher';

function PageRenderer({ Comp, ...routeProps }) {
  const { pathname, search } = routeProps.location;
  const uri = `${pathname}${search}`;
  const { data, pending, error } = useData(uri);
  const fetchData = useDataFetcher();
  const hasData = data !== undefined;
  const shouldHaveDataButDoesnt = !pending && !error && !hasData;

  useEffect(() => {
    // Pages without data will have 'null' as data when server rendered
    // So this shouldn't happen unless client rendered for some reason
    if (shouldHaveDataButDoesnt) {
      fetchData(uri);
    }
  }, []);

  // TODO: Maybe move to generic Data component
  let child = <div>loading...</div>;
  if (error) {
    child = (
      <div>
        <Redirect to="/404" noThrow />
        {/* Error fetching data <button onClick={() => fetchData(uri)}>Retry</button> */}
      </div>
    );
  } else if (hasData) {
    child = <Comp data={data} />;
  }

  return <routeContext.Provider value={routeProps}>{child}</routeContext.Provider>;
}

export default PageRenderer;
