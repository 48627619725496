import React from 'react';
import dlv from 'dlv';
import RevealGroup from '../components/animation/revealGroup';

// components
import ContactBanner from '../components/contactBanner/contactBanner';
import Heading from '../components/heading/heading';
import StoriesAuthor from '../components/sections/storiesAuthor/storiesAuthor';
import StoriesFeatured from '../components/sections/storiesFeatured/storiesFeatured';
import StoriesGrid from '../components/storiesGrid/storiesGrid';
import StoriesHeader from '../components/sections/storiesHeader/storiesHeader';
import StoriesHeaderItem from '../components/sections/storiesHeader/storiesHeaderItem/storiesHeaderItem';
import WorkGrid from '../components/workGrid/workGrid';
import Page from '../components/page';

// static content
import { useGlossary } from '../context/glossaryContext';
import { get } from '../utils/request';

import style from './authorDetail.scss';

function AuthorDetail({ data }) {
  const { t } = useGlossary();

  const authorCasesTitle = dlv(data, 'authorDetails.casesTitle', null);
  const authorCasesSubtitle = dlv(data, 'authorDetails.casesSubtitle', null);
  const authorCases = dlv(data, 'authorCases', []);
  const featuredStoriesTitle = dlv(data, 'authorDetails.featuredTitle', null);
  const featuredStories = dlv(data, 'featuredStories', []);
  const authorDetails = dlv(data, 'authorDetails', {});
  const biography = dlv(data, 'authorDetails.biography', '');
  const authorPhoto = dlv(data, 'authorDetails.photo', '');
  const authorFullName = dlv(data, 'authorDetails.name', '');
  const authorFirstName = dlv(data, 'authorDetails.firstname', '');
  const authorFunction = dlv(data, 'authorDetails.function', '');
  const quote = dlv(data, 'authorDetails.quote', '');
  const contactFormTitle = dlv(data, 'authorDetails.contactFormTitle', '');
  const hubspotFormId = dlv(data, 'authorDetails.hubspotFormId', '');
  const contactCaption = dlv(data, 'authorDetails.contactCaption', '');
  const socialMedia = dlv(data, 'authorDetails.socialMedia', '');

  return (
    <Page newsletterHidden>
      <RevealGroup>
        <StoriesHeader backBtn addSpacing />

        {authorDetails && (
          <StoriesFeatured>
            <StoriesAuthor
              bio={biography}
              photo={authorPhoto}
              fullName={authorFullName}
              firstName={authorFirstName}
              authorFunction={authorFunction}
              quote={quote}
              socialMedia={socialMedia}
            />
          </StoriesFeatured>
        )}

        {featuredStories && (
          <StoriesFeatured addSpacing={true}>
            <StoriesHeaderItem
              title={featuredStoriesTitle ? featuredStoriesTitle : 'LATEST STORIES'}
            />
            <StoriesGrid items={featuredStories.slice(0, 3)} variation="wide" grayLabel={false} />
          </StoriesFeatured>
        )}

        {authorCases && (
          <StoriesFeatured noFlex={true}>
            <StoriesHeaderItem title={authorCasesTitle ? authorCasesTitle : 'HIS WORKS'} />
            <Heading className={style.relatedCasesHeading}>
              {authorCasesSubtitle ? authorCasesSubtitle : t('stories.author.heading')}
            </Heading>
            <WorkGrid removeHighlightedItems items={authorCases} />
          </StoriesFeatured>
        )}

        {authorDetails && (
          <div className={style.contactBanner}>
            <ContactBanner
              title={contactFormTitle}
              caption={contactCaption}
              person={authorDetails}
              hubspotFormId={hubspotFormId}
            />
          </div>
        )}
      </RevealGroup>
    </Page>
  );
}

AuthorDetail.getPageData = (match, env, location) => {
  return get(`/api/page/author/${match.params.slug}`);
};

export default AuthorDetail;
