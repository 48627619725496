// vendor
import React from 'react';
// components
import Icon from '../icon/icon';
// style
import style from './scrollToTopButton.scss';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

function ScrollToTopButton() {
  return (
    <button className={style.container} onClick={() => scrollToTop()}>
      <span className={style.label}>Back to top</span>
      <Icon className={style.icon} type="arrowBottom" />
    </button>
  );
}

export default ScrollToTopButton;
