// vendor
import React from 'react';

// components
import Container from '../../container/container';
import Disclaimer from '../../disclaimer/disclaimer';
import Link from '../../link';
import Socials from '../../socials/socials';

// style
import style from './footerNavigation.scss';

const offices = [
  {
    location: 'Rotterdam',
    href: '/digital-agency/rotterdam',
  },
  {
    location: 'San Francisco',
    href: '/digital-agency/san-francisco',
  },
  {
    location: 'New York',
    href: '/digital-agency/new-york',
  },
];

function FooterNavigation() {
  return (
    <Container className={style.container} classNameInner={style.nav} padding="large">
      <hr className={style.separator} />
      <div className={style.offices}>
        {offices.map(office => (
          <Link href={office.href} className={style.office} key={office.location}>
            {office.location}
          </Link>
        ))}
      </div>
      <hr className={style.separator} />
      <Socials className={style.socials} />
      <Disclaimer className={style.disclaimer} />
    </Container>
  );
}

export default FooterNavigation;
