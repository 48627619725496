// vendor
import React, { useRef, useState } from 'react';
import cc from 'classcat';
import anime from 'animejs';

// animation
import BlockingRevealItem from '../../animation/blockingRevealItem';

// utils
import { getCSS } from '../../../utils/animation';
import createDeferredPromise from '../../../utils/createDeferredPromise';

// style
import style from './homeHero.scss';

let firstTime = true;

function HomeHero({ video }) {
  const [first] = useState(firstTime);
  const cover = useRef();
  const inner = useRef();
  const vid = useRef();

  const innerStyles = {
    from: {
      // translateY: '15vh',
      // scale: '0.65',
    },
    to: {
      translateY: 0,
      scale: 1,
    },
  };

  const vidStyles = {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 1,
    },
  };

  const coverStyles = {
    from: {
      scaleX: 0,
    },
    to: {},
  };

  const [hasAnimated, setHasAnimated] = useState(false);
  function animate() {
    const reduced = matchMedia('(prefers-reduced-motion: reduce)').matches;
    firstTime = false;
    const promise = createDeferredPromise();
    const tl = anime.timeline({
      easing: 'easeInOutCubic',
      duration: reduced ? 0 : 400,
      complete() {
        setHasAnimated(true);
      },
    });

    tl.set({
      targets: vid.current,
      ...vidStyles.from,
    });

    if (first) {
      tl.set({
        targets: inner.current,
        ...innerStyles.from,
      });
    }

    tl.add({
      targets: cover.current,
      scaleX: 0,
      duration: reduced ? 0 : 1,
    })
      .add({
        targets: cover.current,
        scaleX: 1,
        delay: first || reduced ? 500 : 0,
        complete() {
          if (cover.current) {
            cover.current.style['transform-origin'] = 'right';
          }
        },
      })
      .add({
        targets: vid.current,
        opacity: 1,
        duration: reduced ? 0 : 1,
      })
      .add({
        targets: cover.current,
        scaleX: 0,
      });

    if (first) {
      tl.add({
        targets: inner.current,
        scale: 1,
        delay: reduced ? 0 : 300,
      });
    }

    tl.add({
      targets: inner.current,
      translateY: 0,
      begin() {
        promise.resolve();
      },
    });
    return promise;
  }

  const innerStyle = first ? getCSS(hasAnimated ? innerStyles.to : innerStyles.from) : undefined;
  const vidStyle = getCSS(hasAnimated ? vidStyles.to : vidStyles.from);
  const coverStyle = getCSS(hasAnimated ? coverStyles.to : coverStyles.from);

  return (
    // <BlockingRevealItem animate={animate}>
    <div className={style.container}>
      <div className={cc([style.inner])} ref={inner} style={innerStyle}>
        <div className={cc([style.cover])} ref={cover} style={coverStyle} />
        <div className={cc([style.videoWrapper])} ref={vid} style={vidStyle}>
          <video className={cc([style.video])} src={video} autoPlay playsInline muted loop />
        </div>
      </div>
    </div>
    // </BlockingRevealItem>
  );
}

export default HomeHero;
