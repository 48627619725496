// vendor
import React from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import Icon from '../../icon/icon';
import { Image } from 'dpdk-react-library';
import Link from '../../link';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './capabilityServices.scss';

function CapabilityServices({ images, items, title }) {
  const { t } = useGlossary();

  return (
    <RevealGroup>
      <Container
        className={style.container}
        classNameInner={cc([style.content, images && images.length > 0 && style.hasMedia])}
        padding="small"
      >
        {items && items.length > 0 && (
          <div className={style.services}>
           { title && (
            <BasicRevealItem
              variation="left"
              as={Heading}
              className={style.heading}
              level="h2"
              size="h2"
            >
              {title || t('capability.services.heading')}
            </BasicRevealItem>)}
            {items && items.length > 0 && (
              <div className={style.list}>
                {items.map((item, i) => (
                  <BasicRevealItem
                    as={Link}
                    href={item.slug ? `/services/${item.slug.replace(/\/service[^s]/, '')}` : '/'}
                    className={style.item}
                    key={i.toString()}
                  >
                    {item.title &&(
                      <>
                        {item.title}
                      </>
                    )}
                    <Icon className={style.icon} type="arrowRightSmall" />
                  </BasicRevealItem>
                ))}
              </div>
            )}
          </div>
        )}
        {images && images.length > 0 && (
          <div className={style.media}>
            {images.map((image, i) => (
              <>
              {image.original &&(
              <div className={style.inner} key={i.toString()}>
                <Image {...image} width={undefined} height={undefined} />
              </div>
              )}
              {image.image &&(
              <div className={style.inner} key={i.toString()}>
                <Image {...image.image} width={undefined} height={undefined} />
              </div>
              )}
              </>
            ))}
          </div>
        )}
        {/* {images[0].image && images.length > 0 && (
          <div className={style.media}>
            {images.map((image, i) => (
              <div className={style.inner} key={i.toString()}>
                <Image {...image.image} width={undefined} height={undefined} />
              </div>
            ))}
          </div>
        )} */}
      </Container>
    </RevealGroup>
  );
}

export default CapabilityServices;
