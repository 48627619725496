// vendor
import React, { useRef, useState, useEffect } from 'react';
import cc from 'classcat';
import { isMobile } from 'dpdk-devicedetection';
import dlv from 'dlv';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';

// components
import Icon from '../../icon/icon';
import { Image } from 'dpdk-react-library';
import Link from '../../link';
import WorkInfo from '../workInfo/workInfo';

// style
import style from './workGridItem.scss';

function WorkGridItem({
  item,
  video = true,
  portrait = false,
  className = '',
  workDetailAnimation = true,
  removeHighlightedItems = false,
  hasHigherLevelHeadings = false,
}) {
  const { category, client, caption, slug, images, awards, image } = item;
  const isHighlighted = removeHighlightedItems ? false : item.highlighted;
  const [highlighted, setHighlighted] = useState(isHighlighted);
  const [started, setStarted] = useState(false);
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  const videoRef = useRef(null);
  const [tm, setTm] = useState(null);

  let videoUrl = false;
  if (dlv(item, 'video.src.0.value', false)) {
    videoUrl = item.video.src[0].value.match(/^(http(s)?|ftp):\/\/|(www\.)/)
      ? item.video.src[0].value
      : `/video/work/${item.video.src[0].value}.mp4`;
  }
  // item.videoFile (file upload fied) takes precedence
  // over item.video.src (text field)
  if (item && item.videoFile) {
    videoUrl = item.videoFile;
  }

  useEffect(() => {
    isMobile() && setHover(true);
    window.innerWidth < 800 && setHighlighted(false);
  });

  function playVideo() {
    if (video !== true) return;
    if (started === false) {
      setStarted(true);
      requestAnimationFrame(() => {
        if (videoRef.current !== null) {
          videoRef.current.addEventListener('loadeddata', () => {
            if (videoRef.current !== null) {
              videoRef.current.style.opacity = 1;
            }
          });
        }
      });
      return;
    }
    if (videoRef.current !== null) {
      if (tm !== null) clearTimeout(tm);
      videoRef.current.style.opacity = 1;
      videoRef.current.play();
    }
  }

  function pauseVideo() {
    if (video !== true || active === true) return;
    if (videoRef.current !== null) {
      videoRef.current.style.opacity = 0;
    }
    setTm(
      setTimeout(() => {
        if (videoRef.current !== null) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      }, 400)
    );
  }
  return (
    <BasicRevealItem
      className={cc([
        style.work,
        highlighted && style.landscape,
        portrait && style.portrait,
        hover && style.hover,
        className,
      ])}
    >
      <Link
        href={slug}
        onMouseEnter={() => {
          playVideo();
          setHover(true);
        }}
        onMouseLeave={() => {
          pauseVideo();
          setHover(false);
        }}
        onClick={() => {
          setActive(true);
        }}
        animation={workDetailAnimation ? 'toWorkDetail' : 'general'}
      >
        <>
          {highlighted ? (
            <Image
              {...images.landscape}
              className={style.workcaseImage}
              width={undefined}
              height={undefined}
            />
          ) : (
            <>
              {images?.square && (
                <Image
                  {...images.square}
                  className={cc([style.workcaseImage, slug])}
                  width={undefined}
                  height={undefined}
                />
              )}
              {image && (
                <Image
                  {...image}
                  className={cc([style.workcaseImage, slug])}
                  width={undefined}
                  height={undefined}
                />
              )}
            </>
          )}
        </>
        {started && videoUrl && !portrait && (
          <video
            src={videoUrl}
            autoPlay
            muted
            loop
            playsInline
            className={style.video}
            ref={videoRef}
          />
        )}
        {awards?.length > 0 && !portrait && (
          <Icon className={style.awards} type="award" awards={awards} hovered={hover} />
        )}
        {!portrait && (
          <WorkInfo
            category={category}
            client={client}
            caption={caption}
            landscape={highlighted}
            hovered={hover && !portrait}
            hasHigherLevelHeadings={hasHigherLevelHeadings}
          />
        )}
      </Link>
    </BasicRevealItem>
  );
}

export default WorkGridItem;
