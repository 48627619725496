// vendor
import React from 'react';
import cc from 'classcat';
import { bool, node, object, oneOf, string } from 'prop-types';

// style
import style from './heading.scss';

Heading.propTypes = {
  level: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  size: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  weight: oneOf(['thin', 'light', 'semibold', 'bold', 'extrabold']),
  cut: oneOf(['normal', 'italic']),
  color: oneOf(['white', 'black', 'green', 'grey']),
  align: oneOf(['left', 'center', 'right']),
  caps: bool,
  children: node.isRequired,
  component: string,
  className: string,
  innerRef: object,
  multiLine: bool,
};

function Heading({
  level = 'h1',
  size = 'h1',
  weight = 'light',
  cut,
  align = 'left',
  color = 'black',
  caps = false,
  component,
  children,
  className = '',
  innerRef,
  multiLine = false,
  dangerousHTML = false,
  ...rest
}) {
  const Comp = component || level;
  const props = {
    className: cc([
      style.heading,
      style[size],
      style[weight],
      style[cut],
      style[align],
      style[color],
      caps && style.caps,
      className,
    ]),
    ref: innerRef,
    ...rest,
  };

  if (dangerousHTML || multiLine) {
    return (
      <Comp
        {...props}
        dangerouslySetInnerHTML={{
          __html: multiLine ? children.replace(/\r?\n|\r/g, '<br />') : children,
        }}
      />
    );
  }

  return <Comp {...props}>{children}</Comp>;
}

export default React.forwardRef((props, ref) => <Heading {...props} innerRef={ref} />);
