// vendor
import React from 'react';

// components
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';

// style
import style from './cultureIntro.scss';

function CultureIntro({ data }) {
  const heading = data.intro.heading;
  const body = data.intro.body;

  return (
    <RevealGroup>
      <Container mw="medium" padding="small">
        <div className={style.content}>
          <BasicRevealItem
            variation="left"
            as={Heading}
            className={style.heading}
            level="h1"
            size="h1"
          >
            {heading}
          </BasicRevealItem>
          <BasicRevealItem variation="left" as={HTML} className={style.body}>
            {body}
          </BasicRevealItem>
        </div>
      </Container>
    </RevealGroup>
  );
}

export default CultureIntro;
