// vendor
import React, { useState } from 'react';
import cc from 'classcat';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import Link from '../../link';
import dlv from 'dlv';
import ProgressButtons from '../../progressButtons/progressButtons';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';

// style
import style from './capabilitiesSelection.scss';

function CapabilitiesSelection({ items, className }) {
  const [activeIndex, setActiveIndex] = useState(0);
  if (items === null) return null;

  return (
    <>
      <Container
        className={style.container}
        classNameInner={style.content}
        innerAs={BasicRevealItem}
      >
        <ProgressButtons className={style.buttons} count={items.length} onChange={setActiveIndex} />
        {items.map((item, i) =>
          item === null ? (
            item
          ) : (
            <Link
              className={cc([style.card, i === activeIndex && style.active])}
              href={item.slug ? item.slug.replace('story', 'stories') : item.link}
              key={i.toString()}
            >
              {item.title && <Heading className={style.heading}>
                {item.logo && <img src={dlv(item, 'logo.srcSet.320', '')} className={style.headingImage} />} 
                {item.title}
              </Heading>}
            </Link>
          )
        )}
      </Container>
    </>
  );
}

export default CapabilitiesSelection;
