import BasicRevealItem from '../animation/basicRevealItem';
import { Image } from 'dpdk-react-library';
import Link from '../link';
import React from 'react';
import dlv from 'dlv';
import style from './storyExpert.scss';

function StoryExpert({ item }) {
  const title = dlv(item, 'title', '');
  const slug = dlv(item, 'slug', '');
  const authorData = dlv(item, 'authors.0', {});
  const images = dlv(item, 'images', []);
  let authorImage;
  let name;

  if (authorData) {
    authorImage = dlv(authorData, 'photo', '');
    name = dlv(authorData, 'name', '');
  }

  return (
    <BasicRevealItem
      as={Link}
      className={style.container}
      href={`/stories${slug ? slug.replace('/story', '') : ''}`}
    >
      <div className={style.imageContainer}>
        {authorImage && (
          <Image className={style.image} srcSet={authorImage.srcSet} {...images} noWidth />
        )}
      </div>

      <div className={style.copyContainer}>
        <h1 className={style.title}>{title}</h1>
        <p className={style.author}>{name}</p>
      </div>
    </BasicRevealItem>
  );
}

export default StoryExpert;
