import React from 'react';
import style from './storiesButtons.scss';

function StoriesButtons({ loadMore }) {
  // TODO: Include the StoriesButton inside the StoriesGrid component instead
  return (
    <section className={style.container}>
      <div className={style.content}>
        <button className={style.sb_Load} onClick={loadMore}>
          Load more
        </button>
      </div>
    </section>
  );
}

export default StoriesButtons;
