import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import style from './officeStories.scss';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import Icon from '../../icon/icon';
import StoriesGridItem from '../../storiesGrid/storiesGridItem/storiesGridItem';

function OfficeStories({ storiesContentArray = [], title = '' }) {
  SwiperCore.use(Navigation);

  return (
    storiesContentArray &&
    storiesContentArray.length !== 0 && (
      <Container padding="small">
        {title && (
        <Heading level="h2" size="h2" className={style.title}>
          {title}
        </Heading>
          )}
        <div className={style.swiperContainer}>
          <Swiper
            spaceBetween={60}
            slidesPerView={3}
            className={style.swiper}
            onSwiper={swiper => setTimeout(() => swiper.update())}
            navigation={{
              nextEl: '.button__next',
              prevEl: '.button__prev',
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1.25,
              },
              700: {
                slidesPerView: 2,
              },
              1000: {
                slidesPerView: 3,
              },
            }}
          >
            {storiesContentArray.map(story => {
              return (
                <SwiperSlide className={style.swiperItem} key={story.title}>
                  <StoriesGridItem item={story} grayBackground />
                </SwiperSlide>
              );
            })}

            <div className={style.sliderNavigation}>
              <Icon fill="transparent" type="arrowRight" className="button__prev" />
              <Icon fill="transparent" type="arrowRight" className="button__next" />
            </div>
          </Swiper>
        </div>
      </Container>
    )
  );
}

export default OfficeStories;
