// vendor
import React from 'react';

// components
import ChallengeSelection from '../../challengeSelection/challengeSelection';

// style
import style from './homeChallengesSelection.scss';

function HomeChallengeSelection({items}) {
  return (
    <section className={style.container}>
      <ChallengeSelection items={items} className={style.challenges} />
    </section>
  );
}

export default HomeChallengeSelection;
