import { get } from './request';

// ES6 version of async generator, so we don't need to transpile/polyfill as much
function createFetcher({ url, skip: initialSkip = 0, limit = 1, params: extraParams = {} } = {}) {
  let skip = initialSkip;

  function getNext() {
    const params = {
      skip,
      limit,
      ...extraParams,
    };

    const promise = get(url, params).then(res => ({
      value: res.items,
      done: res.items.length === 0,
    }));

    skip += limit;

    return promise;
  }

  return {
    next() {
      return getNext();
    },
  };
}

export default createFetcher;
