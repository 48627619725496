import anime from 'animejs';

function general(ref, promise) {
  const tl = anime.timeline();
  const clone = ref.parentNode.cloneNode(true);

  clone.style.cssText = `
    opacity: 0;
  `;

  ref.parentNode.parentNode.style.cssText = `
    position: relative;
  `;

  ref.parentNode.style.cssText = `
    z-index: 11;
    width: ${ref.parentNode.clientWidth}px;
    height: ${ref.parentNode.clientHeight}px;
    top: ${ref.parentNode.offsetTop -
      (ref.parentNode.offsetTop === 0 ? 0 : 60) / (window.innerWidth > 1199 ? 1 : 2)}px;
    left: 0;
    transform: translateX(${ref.parentNode.offsetLeft}px);
    position: absolute;
  `;

  ref.parentNode.classList.add('background');
  ref.parentNode.parentNode.insertBefore(clone, ref.parentNode);

  const plane = document.createElement('div');
  plane.classList.add('plane');
  plane.style.cssText = `
    width: 100%;
    height: ${document.body.scrollHeight}px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: white;
    z-index: 10;
  `;

  ref.classList.add('interacted');
  document.querySelector('.page').appendChild(plane);

  tl.add({
    targets: ref.querySelectorAll('video'),
    easing: 'easeInOutQuad',
    duration: 400,
    translateX: ['-50%', '-50%'],
    translateY: ['-50%', '-50%'],
    scale: [1, 1.3],
  });

  tl.add(
    {
      targets: ref.querySelectorAll('*:not(video)'),
      easing: 'easeInOutQuad',
      duration: 400,
      opacity: [1, 0],
    },
    '-=400'
  );
  tl.add({
    targets: ref.parentNode,
    translateX: `-${ref.parentNode.parentNode.getBoundingClientRect().left}px`,
    height: '700px',
    width: `${document.body.scrollWidth}`,
    easing: 'easeInOutQuad',
    duration: 400,
  });

  tl.add(
    {
      targets: '.plane',
      easing: 'easeInOutQuad',
      duration: 400,
      opacity: [0, 1],
    },
    '-=400'
  );

  tl.add({
    targets: ref.parentNode,
    translateY: `${(ref.parentNode.getBoundingClientRect().top - 55) * -1}px`,
    easing: 'easeInOutQuad',
    duration: 400,
    complete: () => {
      if (ref.querySelector('video')) {
        window.currentVideoTime = ref.querySelector('video').currentTime;
        window.currentVideo = ref.querySelector('video');
      }

      promise.resolve();
    },
  });
}

export default general;
