// vendor
import React, { useLayoutEffect, useRef } from 'react';
import dlv from 'dlv';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';

// style
import style from './workHero.scss';

function WorkHero({ data }) {
  const { t } = useGlossary();
  const video = useRef(null);
  const videoFile = (data && data.videoFile) || undefined;

  if (typeof window !== 'undefined') {
    useLayoutEffect(() => {
      if (video.current === null || typeof window.currentVideo === 'undefined') return;
      video.current.appendChild(window.currentVideo);
    });
  }

  // split impact body in two parts
  const impact = dlv(data, 'template.impact.caption', null);
  let impactFirstPart = '';
  let impactLastPart = '';

  if (typeof impact === 'string') {
    var index = impact.indexOf(' ');
    impactFirstPart = impact.substr(0, index);
    impactLastPart = impact.substr(index + 1);
  }

  function getTextWithNewTabAnchor(string) {
    const anchorTagRegex = /<a/gi;
    return string.replace(anchorTagRegex, `<a target="_blank"`);
  }

  return (
    <>
      <div className={style.hero}>
        {typeof data.video !== 'undefined' && (
          <>
            {typeof window !== 'undefined' && typeof window.currentVideo !== 'undefined' ? (
              <div
                style={{
                  opacity: '1',
                  position: 'absolute',
                  height: 'calc(100vh - 52px)',
                  maxHeight: '700px',
                  width: '100%',
                  overflow: 'hidden',
                }}
                ref={video}
              />
            ) : (
              typeof data.video !== 'undefined' &&
              (data.video.src.length > 0 || videoFile) && (
                <div
                  style={{
                    opacity: '1',
                    position: 'absolute',
                    height: 'calc(100vh - 52px)',
                    maxHeight: '700px',
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <video
                    className={style.video}
                    src={videoFile ? videoFile : `/video/work/${data.video.src[0].value}.mp4`}
                    autoPlay
                    loop
                    playsInline
                    muted
                    style={{ transform: 'translateX(-50%) translateY(-50%) scale(1.3)' }}
                  />
                </div>
              )
            )}
          </>
        )}

        <div className={style.info}>
            <BasicRevealItem
              variation={'fade'}
            >
              <Image
                className={style.logo}
                {...data.logo}
                width={120}
                height={120}
                removePlaceholderOnLoad
              />
            </BasicRevealItem>
          <Heading align="center" level="h1" size="h1" color="white">
            {data.title}
          </Heading>
          <Heading
            className={style.category}
            align="center"
            level="h2"
            size="h6"
            color="white"
            weight="semibold"
            caps
          >
            {data.category}
          </Heading>
        </div>
      </div>
      <Container className={style.banner} padding="small">
        <Heading className={style.bannerTitle} align="center" level="h2" size="h3">
          {data.template.caption || data.caption || 'Work Case'}
        </Heading>
      </Container>

      <Container classNameInner={style.intro} mw="medium" padding="small">
        <BasicRevealItem className={style.challenge}>
          <Heading className={style.heading} level="h2" size="h2">
            {t('work.detail.challenge')}
          </Heading>
          <HTML>{data.template.challenge}</HTML>
        </BasicRevealItem>
        <BasicRevealItem className={style.outcome}>
          <Heading className={style.heading} level="h2" size="h2">
            {t('work.detail.outcome')}
          </Heading>
          {data.template && data.template.outcome && (
            <HTML>{getTextWithNewTabAnchor(data.template.outcome)}</HTML>
          )}
        </BasicRevealItem>
      </Container>

      <Container classNameInner={style.impact} padding="small">
        <BasicRevealItem className={style.impactText}>
          <div>
            <Heading className={style.heading} level="h2" size="h2">
              {t('work.detail.impact')}
            </Heading>
            <HTML>{data.template.impact.body}</HTML>
          </div>
        </BasicRevealItem>
        <BasicRevealItem className={style.impactImage}>
          <Image
            className={style.image}
            {...data.template.impact.image}
            width={undefined}
            height="100%"
          />
          <span className={style.overlay} style={{ backgroundColor: data.template.color }} />
          <div className={style.content}>
            <HTML className={style.large} align="center" color="white">
              {impactFirstPart}
            </HTML>
            <HTML className={style.small} align="center" color="white">
              {impactLastPart}
            </HTML>
          </div>
        </BasicRevealItem>
      </Container>
    </>
  );
}

export default WorkHero;
