import React, { useEffect, useRef, useState } from 'react';
import { handleIntersection } from '../../utils/dom';
import Video from '../video/video';

function AutoPlayingVideo(props) {
  const [playing, setPlaying] = useState(false);

  const ref = useRef();
  useEffect(() => {
    handleIntersection(ref.current, {
      onEnter: () => setPlaying(true),
      onLeave: () => setPlaying(false),
    });
  }, []);

  return <Video {...props} playing={playing} setPlaying={setPlaying} innerRef={ref} />;
}

export default AutoPlayingVideo;
