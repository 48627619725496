// vendor
import React from 'react';
import cc from 'classcat';

// components
import Container from '../../container/container';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';
import Video from '../../video/video';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './cultureGrid.scss';

function CultureGrid({ data }) {
  const { exceptionalism, transformation, craftmanship, drive, sincerity } = data;
  const items = [exceptionalism, transformation, craftmanship, drive, sincerity];

  return (
    <>
      <Container className={style.container} mw="large" padding="small">
        {items.map(function(item, i) {
          const name = item.heading.toLowerCase();

          return (
            <RevealGroup className={cc([style.section, style[name]])} key={i.toString()}>
              <Container mw="medium" className={style.textWrap}>
                {item.heading && <span className={style.heading}>{item.heading}</span>}
                <BasicRevealItem variation="left" as={HTML} className={style.text}>
                  {item.body}
                </BasicRevealItem>
              </Container>

              <BasicRevealItem variation="fade" className={style.grid}>
                <div className={cc([style.imageWrap, style.small])}>
                  <Image {...item.images.small} />
                </div>
                <div className={cc([style.imageWrap, style.medium])}>
                  <Image {...item.images.medium} />
                </div>
                <div className={cc([style.imageWrap, style.big])}>
                  <Image {...item.images.big} />
                </div>
              </BasicRevealItem>
            </RevealGroup>
          );
        })}
      </Container>

      <Container className={style.videoContainer} classNameInner={style.videoInner} padding="small">
        <Video
          source="/media/videos/culture-video.mp4"
          placeholder="/media/images/videoPoster/culture-video-poster.png"
          expanding
        />
      </Container>
    </>
  );
}

export default CultureGrid;
