function getBrandingStructuredData() {
  let question1 = "Why is branding so important?";
  let question2 = "Why do you need a branding agency?";
  let question3 = "Why choose DPDK as a branding agency?";
  let answer1 =
    "Contrary to popular belief, marketing should not begin with your customers. Your first priority should be building your brand. If you don’t know who you’re as a brand, what you stand for, or why you’re in business, how can you communicate and connect with customers? You can’t. At least not in a memorable and meaningful way.";
  let answer2 =
    "Building a meaningful brand that stands for something in your customer’s mind is tough. You need skills to understand your brand, what it stands for, and how to connect this with an audience. These are precisely the skills that a branding agency like DPDK is equipped with.";
  let answer3 =
    "As a branding agency, we help you identify, fix, and remove potential gaps and weaknesses within your current branding strategy. We will make your brand future-proof, and equip you with all the fundamentals and design elements that you will need to stay competitive.";

  return {
    type: 'application/ld+json',
    innerHTML: `
      {
          "@context": "http://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
               "@type": "Question",
               "name": "${question1}",
               "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${answer1}"
               }
            },
            {
               "@type": "Question",
               "name": "${question2}",
               "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${answer2}"
               }
            },
            {
               "@type": "Question",
               "name": "${question3}",
               "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${answer3}"
               }
            }
          ]
      }
    `,
  };
}

export default getBrandingStructuredData;
