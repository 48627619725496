// vendor
import React, { memo } from 'react';

// components
import ContactForms from '../components/sections/contactForms/contactForms';
import ContactJoinOurTeam from '../components/sections/contactJoinOurTeam/contactJoinOurTeam';
import ContactOffice from '../components/sections/contactOffice/contactOffice';
import ContactQuote from '../components/sections/contactQuote/contactQuote';
import ContactWhitepaper from '../components/sections/contactWhitepaper/contactWhitepaper';
import Page from '../components/page';

// utils
import { get } from '../utils/request';

const Contact = memo(({ data }) => {
  return (
    <Page>
      <ContactForms data={data} />
      <ContactJoinOurTeam data={data} />
      <ContactOffice data={data} />
      <ContactQuote data={data} />
      <ContactWhitepaper data={data} />
    </Page>
  );
});

Contact.getPageData = (match, env, location) => {
  return get(`/api/page/contact`);
};

export default Contact;
