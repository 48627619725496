// vendor
import React from 'react';

function Basic({ ...props }) {
  return (
    <svg viewBox="0 0 100 63" {...props}>
      <path d="M91.049,32.641l7.687-7.612c3.827-3.787-1.914-9.479-5.751-5.686l-7.367,7.289V4.272c0-5.361-8.131-5.361-8.131,0V18.82c-2.336-1.289-5.047-1.999-7.917-1.951c-6.731,0.12-12.405,4.391-14.557,10.304c-0.132-0.045-0.274-0.075-0.422-0.075c-0.151,0-0.295,0.029-0.429,0.076C51.937,21.065,45.98,16.748,39.05,16.87c-4.505,0.079-8.534,2.016-11.35,5.059V4.272c0-5.361-8.137-5.361-8.137,0v13.05C18.26,17,16.895,16.844,15.49,16.87C6.782,17.023-0.152,24.128,0.003,32.737c0.151,8.616,7.331,15.479,16.037,15.329c4.347-0.074,8.246-1.884,11.057-4.75v15.412c0,5.361,8.13,5.361,8.13,0V47.535c1.39,0.37,2.859,0.56,4.375,0.531c6.719-0.115,12.385-4.374,14.549-10.272c0.138,0.049,0.285,0.079,0.44,0.079c0.151,0,0.294-0.028,0.429-0.075c2.236,6.085,8.189,10.387,15.1,10.269c6.745-0.116,12.426-4.404,14.577-10.332l8.29,8.202c3.837,3.796,9.579-1.899,5.751-5.687L91.049,32.641z M15.899,40.024c-4.219,0.072-7.695-3.255-7.767-7.427c-0.076-4.17,3.282-7.611,7.5-7.685c4.22-0.074,7.695,3.254,7.769,7.427C23.471,36.507,20.114,39.952,15.899,40.024 M39.462,40.024c-4.219,0.072-7.699-3.255-7.771-7.427c-0.076-4.17,3.285-7.611,7.504-7.685c4.214-0.074,7.692,3.254,7.771,7.427C47.036,36.507,43.677,39.952,39.462,40.024M57.847,33.737h-1.992v1.971c0,0.66-0.566,1.196-1.265,1.196c-0.699,0-1.266-0.536-1.266-1.196v-1.971h-1.992c-0.665,0-1.207-0.559-1.207-1.252c0-0.691,0.542-1.251,1.207-1.251h1.992v-1.971c0-0.659,0.567-1.196,1.266-1.196c0.7,0,1.265,0.537,1.265,1.196v1.971h1.992c0.667,0,1.209,0.56,1.209,1.251C59.057,33.179,58.515,33.737,57.847,33.737M69.978,40.024c-4.221,0.072-7.695-3.255-7.769-7.427c-0.075-4.17,3.284-7.612,7.501-7.685c4.214-0.074,7.701,3.254,7.772,7.427C77.557,36.507,74.197,39.952,69.978,40.024" />
    </svg>
  );
}

export default Basic;
