// vendor
import cc from 'classcat';
import React, { useEffect, useRef, useState } from 'react';
import useControlProp from '../../hooks/useControlProp';
// animation
import BasicRevealItem from '../animation/basicRevealItem';
// components
import Icon from '../icon/icon';
// style
import style from './video.scss';

function Video({
  source,
  placeholder,
  expanding,
  playing: externalPlaying,
  setPlaying: externalSetPlaying,
  innerRef: ref = useRef(),
  disableReveal,
  className,
  style: inlineStyle,
  autoPlay = true,
  muted,
  blackPlayer = false,
  disableStart = false,
}) {
  const [playing, setPlaying] = useControlProp(externalPlaying, externalSetPlaying, false);
  const [started, setStarted] = useState(false);

  if (playing && !started) {
    setStarted(true);
  }

  function changeVideoState(e) {
    e.preventDefault();
    setPlaying(!playing);
  }

  useEffect(() => {
    try {
      ref.current[playing ? 'play' : 'pause']();
    } catch (e) {
      console.error('Error interacting with video', e);
    }
  }, [playing]);

  const Comp = disableReveal ? 'div' : BasicRevealItem;

  return (
    <Comp
      onClick={changeVideoState}
      className={cc([
        style.videoContainer,
        playing && style.playing,
        expanding && style.expanding,
        className,
      ])}
      style={inlineStyle}
      delay={disableReveal ? 0 : 600}
    >
      <video
        className={style.video}
        src={disableStart ? source : started ? source : placeholder}
        autoPlay={autoPlay}
        playsInline
        loop
        muted={!playing || muted}
        ref={ref}
        poster={placeholder}
        controlsList="nodownload"
      />
      <Icon className={cc([style.icon, blackPlayer && style.blackPlayer, 'js-only'])} type="play" />
    </Comp>
  );
}

export default Video;
