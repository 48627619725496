import React from 'react';
import style from './whitepaper.scss';
import StoriesHeaderItem from '../storiesHeader/storiesHeaderItem/storiesHeaderItem';
import WhitepaperCard from '../whiteaperCard/whiteaperCard';

function Whitepaper({
  whitepaper = {},
  wpTitle = '',
  wpSummary = '',
  wpSlug = '',
  wpCtaLabel = '',
  hideLabel = false,
  sectionTitle = false,
}) {
  const title = (whitepaper && whitepaper.title) || null;
  const summary = (whitepaper && whitepaper.summary) || null;
  const storiesSlug = (whitepaper && whitepaper.slug) || null;
  const ctaLabel = (whitepaper && whitepaper.cta_label) || null;
  const image = (whitepaper && whitepaper.image) || null;

  return (
    <div className={style.wp_container}>
      <StoriesHeaderItem title={sectionTitle ? sectionTitle : title} />
      <WhitepaperCard
        summary={summary || wpSummary}
        title={title || wpTitle}
        slug={storiesSlug || wpSlug}
        cta_label={ctaLabel || wpCtaLabel}
        image={image || null}
        hideLabel={hideLabel}
      />
    </div>
  );
}

export default Whitepaper;
