// vendor
import React from 'react';
import cc from 'classcat';

// components
import { Image } from 'dpdk-react-library';
import Link from '../link';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './partnerGrid.scss';

function PartnerGrid({ items, size = 'wide', hasNoRedirectToOverview = false, className }) {
  const Comp = hasNoRedirectToOverview ? 'div' : Link;

  return (
    <>
      {items && items.length > 0 && (
        <BasicRevealItem className={cc([style.grid, style[size], className])}>
          {items.map((item, i) => (
            <Comp
              className={style.item}
              href={hasNoRedirectToOverview ? '' : '/partners'}
              key={i.toString()}
            >
              <div className={style.wrapper}>
                {item.logo && item.logo.default && (
                  <Image
                    className={cc([style.image, style.default])}
                    srcSet={item.logo.default.srcSet}
                    src={item.logo.default.src}
                    aspectRatio={item.logo.default.aspectRatio}
                  />
                )}
                {item.logo && item.logo.hover && (
                  <Image
                    className={cc([style.image, style.hover])}
                    srcSet={item.logo.hover.srcSet}
                    src={item.logo.hover.src}
                    aspectRatio={item.logo.hover.aspectRatio}
                  />
                )}
              </div>
            </Comp>
          ))}
        </BasicRevealItem>
      )}
    </>
  );
}

export default PartnerGrid;
