// vendor
import React, { useContext } from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';
import domainContext from '../../../context/domainContext';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import ReadMore from '../../readMore/readMore';
import Video from '../../video/video';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './contactJoinOurTeam.scss';

function ContactJoinOurTeam({ data }) {
  const title = data.team_cta_title;
  const description = data.team_cta_description;
  const btnLabel = data && data.team_cta_btn_text;
  const { t } = useGlossary();
  const context = useContext(domainContext);

  return (
    <RevealGroup>
      <div className={style.outerContainer}>
        <BasicRevealItem duration={1200} variation="left">
          <div className={style.greenBackground} />
        </BasicRevealItem>

        <Container padding="small" innerRelative>
          <BasicRevealItem duration={1200} className={style.join} variation="left">
            {title && (
              <Heading level="h1" size="h3" className={style.heading} color="white">
                {title}
              </Heading>
            )}
            {description && (
              <BasicRevealItem variation="left">
                <HTML className={style.text} color="white">
                  {description}
                </HTML>
              </BasicRevealItem>
            )}
            <BasicRevealItem variation="left">
              <HTML className={style.label} color="white">
                {t('contact.join.label', context.vacancyCount)}
              </HTML>
            </BasicRevealItem>

            <BasicRevealItem variation="left">
              <ReadMore
                className={style.button}
                label={btnLabel !== null ? btnLabel : t('contact.join.button.label')}
                color="white"
                href="/careers"
              />
            </BasicRevealItem>

            <div className={style.mobileView}>
              <div className={style.video}>
                <Video
                  source="/media/videos/culture-video.mp4"
                  placeholder="/media/images/videoPoster/culture-video-poster.png"
                  expanding
                  autoPlay={false}
                  disableStart
                />
              </div>
            </div>
          </BasicRevealItem>

          <div className={style.desktopView}>
            <div className={style.video}>
              <Video
                source="/media/videos/culture-video.mp4"
                placeholder="/media/images/videoPoster/culture-video-poster.png"
                expanding
                autoPlay={false}
                disableStart
              />
            </div>
          </div>
        </Container>
      </div>
    </RevealGroup>
  );
}

export default ContactJoinOurTeam;
