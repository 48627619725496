// vendor
import React from 'react';

function Twitter({ ...props }) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M100,18.996c-3.681,1.631-7.63,2.737-11.779,3.231c4.237-2.537,7.48-6.561,9.017-11.342c-3.974,2.35-8.361,4.056-13.035,4.98c-3.743-3.993-9.067-6.48-14.972-6.48c-11.329,0-20.515,9.186-20.515,20.509c0,1.606,0.181,3.174,0.531,4.674C32.2,33.712,17.084,25.545,6.967,13.135c-1.768,3.025-2.775,6.549-2.775,10.311c0,7.117,3.624,13.398,9.123,17.072c-3.362-0.112-6.524-1.037-9.292-2.575v0.256c0,9.936,7.074,18.228,16.453,20.115c-1.718,0.462-3.531,0.719-5.405,0.719c-1.325,0-2.606-0.131-3.862-0.381c2.612,8.155,10.186,14.085,19.159,14.247c-7.017,5.499-15.866,8.767-25.477,8.767c-1.656,0-3.287-0.1-4.893-0.281c9.08,5.83,19.859,9.23,31.444,9.23c37.737,0,58.364-31.257,58.364-58.364l-0.069-2.656C93.77,26.72,97.257,23.108,100,18.996z" />
    </svg>
  );
}

export default Twitter;
