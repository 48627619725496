// vendor
import React, { useState } from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../context/glossaryContext';

// components
import Heading from '../heading/heading';
import HTML from '../html/html';
import Link from '../link';
import ReadMore from '../readMore/readMore';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './capability.scss';

function Capability({ href, label, heading, summary }) {
  const { t } = useGlossary();
  const [hover, setHover] = useState(false);

  return (
    <BasicRevealItem
      as={Link}
      className={style.item}
      href={href}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {heading && (
        <Heading className={style.heading} level="h3" size="h5" weight="semibold" color="black">
          {heading}
        </Heading>
      )}
      {summary && <HTML className={style.body}>{summary}</HTML>}
      <ReadMore className={style.button} label={label || t('label.readMore')} hovered={hover} />
    </BasicRevealItem>
  );
}

export default Capability;
