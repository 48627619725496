// vendor
import React, { useState } from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext.js';

// components
import Button from '../../button/button';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import Link from '../../link';

// animation
import BasicRevealItem from '../../animation/basicRevealItem.js';

// style
import style from './homeLinkBar.scss';

function HomeLinkBar({ title, cta }) {
  const { t } = useGlossary();
  const [hover, setHover] = useState(false);

  return (
    <BasicRevealItem className={style.reveal}>
      <Container className={style.container} classNameInner={style.content} padding="small">
        <Link
          className={style.anchor}
          href="/approach"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Heading className={style.heading} level="h1" size="h4" color="white">
            {title ? title : t('home.hero.heading')}
          </Heading>
          <Button className={style.button} display="start" hovered={hover}>
            {cta ? cta : t('home.hero.button.label')}
          </Button>
        </Link>
      </Container>
    </BasicRevealItem>
  );
}

export default HomeLinkBar;
