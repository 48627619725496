// vendor
import React from 'react';

function ArrowBottom({ ...props }) {
  return (
    <svg viewBox="0 0 100 40" {...props}>
      <line x1="60" y1="20" x2="93" y2="20" />
      <polyline points="77.438,5.076 92.362,20 77.438,34.924 	" />
    </svg>
  );
}

export default ArrowBottom;
