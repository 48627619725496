// vendor
import React, { useEffect, useRef } from 'react';
import { func, node, number, object, oneOfType, string } from 'prop-types';

// utils
import { handleIntersection } from '../utils/dom';

/**
 * Component that checks whether it is visible within the viewport.
 */
IntersectionChecker.propTypes = {
  // The margin in pixels to grow or shrink the bounds of the element, when checking the visibility.
  marginTop: number,
  marginBottom: number,

  // Callbacks
  onChange: func,
  onEnter: func,
  onLeave: func,
  onEnterOnce: func,

  className: string,
  children: node,
  innerRef: object,
  as: oneOfType([string, object]),
};

function IntersectionChecker({ as: Comp = 'div', className, children, style, innerRef, ...rest }) {
  const ref = useRef();
  useEffect(() => {
    if (innerRef) innerRef.current = ref.current;
    handleIntersection(ref.current, rest);
  }, []);

  return (
    <Comp className={className} ref={ref} style={style}>
      {children}
    </Comp>
  );
}

export default IntersectionChecker;
