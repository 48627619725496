// vendor
import React from 'react';

// components
import Button from '../../button/button';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import PartnerGrid from '../../partnerGrid/partnerGrid';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// style
import style from './companyPartners.scss';

function CompanyPartners({ items, title, desc }) {
  const { t } = useGlossary();

  return (
    <Container
      className={style.container}
      classNameInner={style.content}
      padding="small"
      mw="small"
    >
      <BasicRevealItem as={Heading} className={style.heading} level="h3" size="h3">
        {title ? title : t('company.story.partner.heading')}
      </BasicRevealItem>
      <BasicRevealItem as={HTML} className={style.intro}>
          {desc ? `<p>${desc}</p>` : t('company.story.partner.body')}
      </BasicRevealItem>
      {items && items.length > 0 && <PartnerGrid className={style.grid} items={items} />}
      <BasicRevealItem as="p" className={style.buttonWrap}>
        <Button href="/partners" className={style.button}>
          {t('label.readMore')}
        </Button>
      </BasicRevealItem>
    </Container>
  );
}

export default CompanyPartners;
