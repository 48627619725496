import BasicRevealItem from '../../../animation/basicRevealItem';
import { Image } from 'dpdk-react-library';
import Link from '../../../link';
import React from 'react';
import dlv from 'dlv';
import Time from '../../../time';
import cc from 'classcat';
import style from './shortStorie.scss';

function ShortStorie({ item, defaultStyle = false, hasMinHeight = false }) {
  const title = dlv(item, 'title', '');
  const slug = dlv(item, 'slug', '');
  const publishedDate = dlv(item, 'publishedDate', '');
  const categories = dlv(item, 'categories', {});

  const image = dlv(item, 'images.landscape', null);
  const category = categories && categories[0] && categories[0].title ? categories[0].title : '';

  return (
    <BasicRevealItem as={Link} className={cc([style.story])} href={slug}>
      <div className={style.ss_withImageContainer}>
        {!defaultStyle && (
          <div className={style.ss_imageContainer}>
            {image && <Image className={style.ss_image} srcSet={image.srcSet} {...image} noWidth />}
          </div>
        )}
        <div className={style.ss_container}>
          <h2 className={cc([style.ss_header, hasMinHeight && style.hasMinHeight])}>{title}</h2>
          <p className={style.ss_subContent}>
            {category} &#160;| &#160;
            <Time timestamp={publishedDate} day month year />
          </p>
        </div>
      </div>
    </BasicRevealItem>
  );
}

export default ShortStorie;
