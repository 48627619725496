// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import CapabilitiesGridItem from '../../capabilitiesGridItem/capabilitiesGridItem';
import Container from '../../container/container';
import OfferingBlock from '../../offeringBlock/offeringBlock';
import SectionHeading from '../../sectionHeading/sectionHeading';

// animation
import RevealGroup from '../../animation/revealGroup';

// style
import style from './capabilitiesOverview.scss';

function CapabilitiesOverview({ headerTitle, items, offerings }) {
  const { t } = useGlossary();

  return (
    <>
      <SectionHeading
        preheading={t('capabilities.preheading')}
        heading={headerTitle ? headerTitle : t('capabilities.heading')}
        mw="medium"
        padding="large"
        className={style.header}
      />
      <Container className={style.gridContainer} padding="small" spaced>
        {Array.isArray(items) && (
          <RevealGroup>
            <div className={style.capability}>
              {items.map(item => (
                <CapabilitiesGridItem
                  className={style.item}
                  title={item.title}
                  summary={item.summary}
                  linkText={item.linkText}
                  slug={item.slug}
                  key={item.title}
                />
              ))}
            </div>
          </RevealGroup>
        )}
      </Container>
      <OfferingBlock className={style.offerings} offerings={offerings} />
    </>
  );
}

export default CapabilitiesOverview;
