// vendor
import React from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import StoriesGridItem from '../../storiesGrid/storiesGridItem/storiesGridItem';
import WorkGridItem from '../../workGrid/workGridItem/workGridItem';

// style
import style from './capabilityRelated.scss';

function CapabilityRelated({ relatedContent, title }) {
  const { t } = useGlossary();

  return (
    <>
      {relatedContent && relatedContent.length > 0 && (
        <Container className={style.container} classNameInner={style.content} padding="small">
          <Heading className={style.heading} level="h3" size="h2" weight="light">
            {t('capability.related.heading', title)}
          </Heading>

          <div className={style.related}>
            {relatedContent.map((item, i) =>
              item.type === 'work' ? (
                <WorkGridItem
                  item={item}
                  className={cc([style.work, style.unit])}
                  key={i.toString()}
                  highlighted={false}
                />
              ) : (
                <StoriesGridItem
                  item={item}
                  className={cc([style.story, style.unit])}
                  key={i.toString()}
                />
              )
            )}
          </div>
        </Container>
      )}
    </>
  );
}

export default CapabilityRelated;
