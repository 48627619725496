import React, { memo } from 'react';
import ContactBanner from '../components/contactBanner/contactBanner';
import Page from '../components/page';
import ServiceContent from '../components/sections/serviceContent/serviceContent';
import ServiceHero from '../components/sections/serviceHero/serviceHero';
import ServiceRelated from '../components/sections/serviceRelated/serviceRelated';
import { get } from '../utils/request';

const ServiceDetail = memo(({ data }) => {
  return (
    <Page>
      <ServiceHero image={data.headerImage} />
      {data.sections && data.sections.length > 0 && (
        <ServiceContent
          sections={data.sections}
          contactPerson={data.contactPerson}
          title={data.title}
          contactCaption={data.contactCaption}
        />
      )}
      {data.contactPerson && (
        <ContactBanner
          caption={data.contactPerson.contactCaption}
          title={data.title}
          person={data.contactPerson}
          hubspotFormId={data.hubspotFormId}
          detail
        />
      )}
      <ServiceRelated stories={data.stories} capabilities={data.capabilities} />
    </Page>
  );
});

ServiceDetail.getPageData = (match, env, location) => {
  return get(`/api/page/services/${match.params.slug}`);
};

export default ServiceDetail;
