import React, { useEffect, useState } from 'react';
import dlv from 'dlv';
import style from './officeCareers.scss';
import Heading from '../../heading/heading';
import Button from '../../button/button';
import Container from '../../container/container';
import CareerItem from '../../careerItem/careerItem';
import Link from '../../link';
import cc from 'classcat';
import { get } from '../../../utils/request';

const OfficeCareers = props => {
  useEffect(() => {
    getVacancyData();
  }, []);

  const [currentVacancies, setVacancies] = useState(null);

  const resultText = dlv(props, 'careerProps.vacancies_results', false);

  async function getVacancyData() {
    const careersPageData = await get('/api/page/company/careers');
    if (!careersPageData.vacancies || !careersPageData.vacancies.length) return;
    const jobs = careersPageData.vacancies.filter(
      vacancy =>
        vacancy.location &&
        vacancy.location.city &&
        vacancy.location.city.includes(props.careerProps.city)
    ).sort((a,b)=>{
      return new Date(b.created_at) - new Date(a.created_at);
    });
    setVacancies(jobs);
  }

  function getResultText() {
    if (resultText){
      const resultNumber = currentVacancies && currentVacancies.length ? currentVacancies.length : 'no';
      return resultText.replace('__number__', resultNumber);
    } else {
      return <>We currently have{' '}
      {currentVacancies && currentVacancies.length ? currentVacancies.length : 'no'} positions
      in {props.careerProps.city}</>;
    }
  }

  return (
    <Container
      padding="small"
      className={cc([
        style.outer__wrapper,
        (!currentVacancies || !currentVacancies.length) && style['wrapper--grey'],
      ])}
    >
      <div className={style.careers__container}>
        <div className={style.careers__information}>
          <Heading level="h2" size="h2" weight="light" className={cc(style.careers__heading)}>
            {props.careerProps && props.careerProps.vacancies_title}
          </Heading>
          <Heading
            level="h5"
            size="h5"
            weight="bold"
            color="green"
            className={cc(style.careers__subheading)}
          >
            {getResultText()}
          </Heading>
          <p>{props.careerProps && props.careerProps.vacancies_desc}</p>
          <Link href="/careers">
            <Button className={style.careers__button} boxed>
              {props.careerProps && props.careerProps.vacancies_btn_text}
            </Button>
          </Link>
        </div>
        <div className={style.careers__list}>
          {currentVacancies &&
            currentVacancies.length !== 0 &&
            currentVacancies.slice(0, 5).map(positionData => {
              return (
                <CareerItem
                  key={positionData.shortcode}
                  className="careers__position"
                  item={positionData}
                />
              );
            })}
        </div>
      </div>
    </Container>
  );
};

export default OfficeCareers;
