// vendor
import React, { useMemo, useReducer } from 'react';
import dataContext from '../context/dataContext';

const actions = {
  setPending: (state, payload) => ({
    pending: { ...state.pending, [payload.key]: payload.promise },
    failed: { ...state.failed, [payload.key]: undefined },
  }),
  receive: (state, payload) => ({
    data: { ...state.data, [payload.key]: payload.data },
    pending: { ...state.pending, [payload.key]: undefined },
  }),
  err: (state, payload) => ({
    pending: { ...state.pending, [payload.key]: undefined },
    failed: { ...state.failed, [payload.key]: payload.error || true },
  }),
};

function reducer(state, { type, payload }) {
  return { ...state, ...actions[type](state, payload) };
}

function DataProvider({ initialData = {}, children }) {
  const [state, dispatch] = useReducer(reducer, { data: initialData, pending: {}, failed: {} });

  const value = useMemo(
    () => ({
      ...state,
      load: (key, loadCallback) => {
        if (state.data[key] !== undefined) return Promise.resolve();
        const pending = state.pending[key];
        if (pending) return pending;
        const promise = Promise.resolve(loadCallback())
          .then(data => {
            dispatch({ type: 'receive', payload: { key, data } });
          })
          .catch(e => {
            dispatch({ type: 'err', payload: { key, error: e && e.message } });
          });
        dispatch({ type: 'setPending', payload: { key, promise } });
        return promise;
      },
    }),
    [state]
  );
  return <dataContext.Provider value={value}>{children}</dataContext.Provider>;
}

export default DataProvider;
