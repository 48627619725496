// vendor
import React from 'react';
import { Image } from 'dpdk-react-library';
import cc from 'classcat';

// static content
import { useGlossary } from '../../../../context/glossaryContext';

// animation
import BasicRevealItem from '../../../animation/basicRevealItem';
import RevealGroup from '../../../animation/revealGroup';

// components
import Container from '../../../container/container';
import Heading from '../../../heading/heading';
import HTML from '../../../html/html';
import PartnerGrid from '../../../partnerGrid/partnerGrid';

// style
import style from './workTechnology.scss';

function WorkTechnology({ data, className }) {
  const { t } = useGlossary();
  const { body, image, integrations, partners } = data;

  return (
    <RevealGroup>
      <Container
        className={cc([style.container, className])}
        classNameInner={style.content}
        padding="small"
        mw="medium"
      >
        <BasicRevealItem className={style.textContent}>
          <Heading level="h2" size="h2" className={style.heading}>
            {t('work.detail.technology')}
          </Heading>
          {body && (
            <HTML className={style.text} as="div">
              {body}
            </HTML>
          )}

          {integrations && (
            <div className={style.unit}>
              <HTML className={style.subtitle} as="p">
                {t('work.detail.integrations')}
              </HTML>
              <HTML as="div">{integrations}</HTML>
            </div>
          )}

          {partners && partners.length > 0 && (
            <div className={style.unit}>
              <HTML className={style.subtitle} as="p">
                {t('work.detail.partners')}
              </HTML>
              <PartnerGrid className={style.partners} items={partners} size="small" />
            </div>
          )}
        </BasicRevealItem>
        {image && (
          <BasicRevealItem className={style.media}>
            <Image className={style.image} {...image} />
          </BasicRevealItem>
        )}
      </Container>
    </RevealGroup>
  );
}

export default WorkTechnology;
