// vendor
import cc from 'classcat';
import dlv from 'dlv';
import { Image } from 'dpdk-react-library';
import React, { useState } from 'react';
// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import { useGlossary } from '../../../context/glossaryContext';
// components
import Heading from '../../heading/heading';
import Link from '../../link';
import Time from '../../time';
// style
import style from './storiesGridItem.scss';

function StoriesGridItem({ item, i, variation = 'wide', className, grayLabel, grayBackground = false }) {
  const [hover, setHover] = useState(false);
  const { t } = useGlossary();

  const title = dlv(item, 'title', '');
  const slug = dlv(item, 'slug', '');
  const type = dlv(item, 'type', '');
  const summary = dlv(item, 'summary', '');
  const storyPublishedDate = dlv(item, 'publishedDate', '');
  const whitePaperPublishDate = dlv(item, 'publish_date', '');
  const publishedDate = storyPublishedDate || whitePaperPublishDate;
  const categories = dlv(item, 'categories', []);
  const readTime = dlv(item, 'readTime', []);
  const url = type === 'whitepaper' ? slug : slug.replace('/story', '/stories');

  const image = dlv(item, 'images', []);
  const whitepaperImage =
    variation === 'wider' || variation === 'whitepaper'
      ? dlv(item, 'landscapeImage', [])
      : dlv(item, 'image', []);
  const storyImage = variation === 'wider' ? image && image.landscape : image && image.square;

  return (
    <BasicRevealItem
      as={Link}
      className={cc([style.story, hover && style.hover, style[variation], className])}
      href={url}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <div className={style.preview}>
        <div className={style.overlay} />
        {type ? (
          <span className={style.catLabel}>Download PDF</span>
        ) : (
          <span className={style.catLabel}>{t('stories.readTime', readTime)}</span>
        )}
        {item.type === 'whitepaper' ? (
          <Image
            {...whitepaperImage}
            className={style.storyImage}
            noWidth
            width={undefined}
            height={undefined}
          />
        ) : (
          <div>
            <div className={style.desktopView}>
              <Image
                {...storyImage}
                className={style.storyImage}
                noWidth
                width={undefined}
                height={undefined}
              />
            </div>
            <div className={style.mobileView}>
              <Image
                {...image.landscape}
                className={style.storyImage}
                noWidth
                width={undefined}
                height={undefined}
              />
            </div>
          </div>
        )}
      </div>

      <div className={cc([style.info, grayBackground && style.grayBackground])}>
        <div className={style.content}>
          {categories && (
            <span className={cc([style.label, grayLabel && style.grayLabel])}>
              {categories.map(categories => (
                <span key={categories.title}>{categories.title} </span>
              ))}
            </span>
          )}
          {publishedDate && (
            <Heading className={style.pdate} level="h6" size="h6" weight="semibold" color="black">
              <Time timestamp={publishedDate} day month year />
            </Heading>
          )}
          {type && (
            <Heading
              className={style.type}
              level="h4"
              size="h6"
              weight="semibold"
              color="green"
              caps
            >
              {type}
            </Heading>
          )}
          {title && (
            <Heading className={style.title} level="h3" size="h4" weight="light" color="black">
              {title}
            </Heading>
          )}
          {summary && (
            <Heading className={style.summary} level="h3" size="h4" weight="light" color="black">
              {summary}
            </Heading>
          )}
        </div>
      </div>
    </BasicRevealItem>
  );
}

export default StoriesGridItem;
