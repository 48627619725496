// vendor
import React from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Button from '../../button/button';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';

// utils
import scroll from '../../../utils/scroll';
import getFirstName from '../../../utils/getFirstName';

// style
import style from './offeringContactBanner.scss';

function OfferingContactBanner({ className, contact, body }) {
  const { t } = useGlossary();

  return (
    <Container
      className={cc([style.card, className])}
      classNameInner={style.inner}
      mw="medium"
      padding="small"
    >
      {contact && (
        <div className={style.media}>
          {contact.photo && <Image {...contact.photo} className={style.image} aspectRatio={1.2} />}
          <div className={style.info}>
            {contact.name && (
              <HTML color="white" className={style.contactName}>
                {contact.name}
              </HTML>
            )}
            {contact.function && (
              <HTML color="green" className={style.contactFunction}>
                {contact.function}
              </HTML>
            )}
          </div>
        </div>
      )}
      <div className={style.content}>
        {body && (
          <Heading size="h4" level="h4" className={style.body}>
            {body}
          </Heading>
        )}
        <Button onClick={() => scroll('.js-section-contact')} icon="arrowBottom" boxed>
          {t('cta.contact.card.button', getFirstName(contact.name))}
        </Button>
      </div>
    </Container>
  );
}

export default OfferingContactBanner;
