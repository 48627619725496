// vendor
import React, { useEffect, useState } from 'react';

// utils
import emitter from '../../../utils/emitter';

// style
import style from './pageProgressBar.scss';

function PageProgressBar() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    function handlePageChange() {
      setProgress(0);
    }

    function handlePageScroll() {
      const progress =
        window.scrollY / (document.body.getBoundingClientRect().height - window.innerHeight);
      setProgress(progress);
    }

    emitter.on('pageChange', handlePageChange);
    window.addEventListener('scroll', handlePageScroll);

    return () => {
      emitter.off('pageChange', handlePageChange);
      window.removeEventListener('scroll', handlePageScroll);
    };
  }, []);

  return <span className={style.bar} style={{ transform: `scaleX(${progress})` }} />;
}

export default PageProgressBar;
