// animation
import BasicRevealItem from '../animation/basicRevealItem';
// components
import Container from '../container/container';
import HTML from '../html/html';
// vendor
import React from 'react';
// style
import style from './basicQuote.scss';

function BasicQuote({ quote, author, authorFunction }) {
  return (
    <Container className={style.container}>
      <BasicRevealItem>
        {quote && <p className={style.quote}>{quote}</p>}
        {author && (
          <HTML className={style.author} size="large" component="p">
            {`- ${author}${authorFunction ? `, ${authorFunction} at DPDK` : ''}`}
          </HTML>
        )}
      </BasicRevealItem>
    </Container>
  );
}

export default BasicQuote;
