// vendor
import React from 'react';

function ArrowRightSmall({ ...props }) {
  return (
    <svg viewBox="0 0 100 40" {...props}>
      <polyline points="77.438,5.076 92.362,20 77.438,34.924 	" />
    </svg>
  );
}

export default ArrowRightSmall;
