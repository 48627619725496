// vendor
import React from 'react';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import OfferingWorkItem from './offeringWorkItem/offeringWorkItem';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './offeringRelatedWork.scss';

function OfferingRelatedWork({ data }) {
  const { title, body, items } = data;
  return (
    <Container className={style.related} mw="medium" padding="small">
      <div className={style.content}>
        {title && (
          <BasicRevealItem as={Heading} className={style.heading} size="h2" level="h2">
            {title}
          </BasicRevealItem>
        )}
        {body && (
          <BasicRevealItem as={HTML} className={style.body}>
            {body}
          </BasicRevealItem>
        )}
      </div>
      <RevealGroup className={style.grid}>
        {items &&
          items.length > 0 &&
          items.slice(0, 3).map((item, i) => <OfferingWorkItem item={item} key={i.toString()} />)}
      </RevealGroup>
    </Container>
  );
}

export default OfferingRelatedWork;
