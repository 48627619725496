import React, { useRef } from 'react';
import dlv from 'dlv';
import Page from '../components/page';
import { get } from '../utils/request';
import BrandingHeader from '../components/sections/alternativeOfficeHeader/alternativeOfficeHeader';
import ContactBanner from '../components/contactBanner/contactBanner';
import CapabilityServices from '../components/sections/capabilityServices/capabilityServices';
import BrandingLocation from '../components/sections/brandingLocation/brandingLocation';
import OfficeStories from '../components/sections/officeStories/officeStories';
import ClientSlider from '../components/sections/contentSlider/clientSlider';
import HomeWorkGrid from '../components/sections/homeWorkGrid/homeWorkGrid';
import { useGlossary } from '../context/glossaryContext';

const Branding = ({ data }) => {
  const contactRef = useRef();

  const { t } = useGlossary();

  const selectedStories = dlv(data, 'cases', false);

  if (selectedStories && selectedStories.length > 3) {
    selectedStories.splice(3);
  }
  if (selectedStories && selectedStories[0] && selectedStories[0].highlighted !== true) {
    selectedStories[0].highlighted = true;
  }

  if (
    (selectedStories && selectedStories[1] && selectedStories[1].highlighted) ||
    (selectedStories[2] && selectedStories[2].highlighted) === true
  ) {
    selectedStories[1].highlighted = false;
    selectedStories[2].highlighted = false;
  }

  return (
    <Page>
      {data.header_image && (
        <BrandingHeader
          scrollToOfficeSlider={() =>
            contactRef.current && contactRef.current.scrollIntoView({ behavior: 'smooth' })
          }
          headerProps={{
            title: data.pageTitle,
            titleClean: data.pageTitle,
            address: data.address,
            email: data.email,
            contactCTALabel: data.contactButtonLabel,
            telephone: data.telephone,
            timezoneOffset: data.timezoneOffset,
            header_image: data.header_image,
            card_image: data.card_image,
            where_we_started_title: data.introTitle,
            where_we_started: data.intro,
          }}
        />
      )}
      {data.services && (
        <CapabilityServices
          images={data.servicesImages}
          items={data.services}
          title={data.servicesTitle}
        />
      )}
      {data.testimonials && (
        <ClientSlider testimonials={data.testimonials} title={data.testimonialsTitle} />
      )}
      {data.cases && <HomeWorkGrid data={selectedStories} title={data.casesTitle} />}
      {data.stories && (
        <OfficeStories storiesContentArray={data.stories} title={data.storiesTitle} />
      )}
      {data.directionsImage && data.directionsTitle && (
        <BrandingLocation
          locationProps={{
            directions_title: data.directionsTitle,
            directions_desc: data.directionsDesc,
            directions_image: data.directionsImage,
            directions_link: data.directionsLink,
            directions_cta_link: data.directionsCtaLink,
          }}
        />
      )}
      {data.contactPerson && (
        <ContactBanner
          ref={contactRef}
          caption={data.contactCaption}
          customTitle={data.contactFormTitle}
          index={1}
          person={data.contactPerson}
          noMarginBottom={true}
        />
      )}
    </Page>
  );
};

Branding.getPageData = (match, env, location) => {
  return get(`/api/page/branding/webdesign/${match.params.city}`);
};

export default Branding;
