import BasicRevealItem from '../animation/basicRevealItem';
import Container from '../container/container';
import HTML from '../html/html';
import Heading from '../heading/heading';
import Hubspot from './../sections/hubspot/Hubspot';
import { Image } from 'dpdk-react-library';
import Link from '../link';
import React, { forwardRef } from 'react';
import cc from 'classcat';
import style from './contactBanner.scss';
import { useGlossary } from '../../context/glossaryContext';
import { sendGa } from '../../utils/sendGa';

function ContactBanner(
  {
    caption,
    title,
    customTitle,
    person,
    footer,
    detail,
    hubspotFormId,
    noMarginBottom = false,
    whiteBackground = false,
    imageVerticalOffset = true,
    index = 0,
  },
  ref
) {
  const { t } = useGlossary();

  return (
    <Container
      className={cc([
        style.container,
        footer && style.footer,
        noMarginBottom && style.noMarginBottom,
        'js-section-contact',
        whiteBackground && style.whiteBackground,
      ])}
      classNameInner={style.content}
      padding="large"
      innerAs={BasicRevealItem}
      ref={ref}
    >
      {person && (
        <div className={style.imageContainer}>
          <div className={cc([style.image, imageVerticalOffset && style.offset])}>
            {person.photo && (
              <Image
                {...person.photo}
                noWidth
                width={undefined}
                height={undefined}
                className={style.imageWrapper}
              />
            )}
            <div className={style.info}>
              {person && person.name && (
                <HTML color="white" as="p" className={style.contactName}>
                  {person.name}
                </HTML>
              )}
              {person.function && (
                <HTML as="p" className={style.contactFunction}>
                  {person.function}
                </HTML>
              )}
              {(person.email || person.telephone) && (
                <div className={style.contacts}>
                  {person.email && (
                    <Link
                      className={style.mail}
                      href={`mailto:${person.email}`}
                      onClick={() => {
                        sendGa('email-link-click', { 'gtm.elementTarget': person.email });
                      }}
                    >
                      {person.email}
                    </Link>
                  )}
                  {person.telephone && (
                    <Link
                      className={style.phone}
                      href={`tel:${person.telephone}`}
                      external
                      onClick={() => {
                        sendGa('tel-link-click', { 'gtm.elementTarget': person.telephone });
                      }}
                    >
                      {person.telephone}
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={style.contact}>
      <BasicRevealItem>
        {title && (
          <Heading className={style.heading} size="h3" level="h2">
            {detail
              ? caption || `Any questions about ${title}?`
              : caption || t('cta.contact.heading')}
          </Heading>
        )}
        {customTitle && !title && (
          <Heading className={style.heading} size="h3" level="h2">
            {customTitle}
          </Heading>
        )}
        <Heading className={style.subheading} size="h6" level="h3" weight="semibold" caps>
          {t('cta.contact.subheading', person && person.firstname)}
        </Heading>
          <Hubspot
            appendId={'contact-banner-' + index}
            className={style.form}
            portalId="6265534"
            formId={hubspotFormId || '40300260-576b-45d5-8cfd-d73cee2e0af6'}
          />
        </BasicRevealItem>
      </div>
    </Container>
  );
}

export default forwardRef(ContactBanner);
