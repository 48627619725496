import { useEffect, useRef } from 'react';

function useUpdateEffect(create, inputs) {
  const didUpdate = useRef(false);

  useEffect(() => {
    if (didUpdate.current === false) {
      didUpdate.current = true;
      return create(false);
    }
    return create(true);
  }, inputs);
}

export default useUpdateEffect;
