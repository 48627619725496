// import { loadableReady } from '@loadable/component';
import { addDeviceClasses } from 'dpdk-devicedetection';
import { GTMProvider } from 'dpdk-react-library';
import React from 'react';
import { hydrate } from 'react-dom';
import Root from './components/root';
import './scss/global.scss';
import * as sw from './serviceWorker';
import addFocusClasses from './utils/addFocusClasses';

addDeviceClasses(document.documentElement);
addFocusClasses(document.documentElement);

function init() {
  hydrate(
    <GTMProvider id={window.GTM}>
      <Root initialData={window.__INITIAL_DATA__} domainData={window.__DOMAIN_DATA__} />
    </GTMProvider>,
    document.getElementById('root')
  );
}

// Load all components needed before rendering
// loadableReady(init);
init();

sw.init();

if (module.hot) {
  module.hot.accept();
}
