// components
import Link from '../../link';
import Icon from '../../icon/icon';
// vendor
import React, { useState } from 'react';
import cc from 'classcat';
// style
import style from './storiesChips.scss';
import BasicRevealItem from '../../animation/basicRevealItem';

function StoriesChips({ categories, selected }) {
  const [state, setState] = useState(false);
  const categoriesMobile = state ? categories : categories.slice(0, 3);

  return (
    <div className={style.chipsSection}>
      <BasicRevealItem duration={1000} variation="left">
        <div className={style.chipsDesktop}>
          <div className={style.chipsContainer}>
            {categories.map(item => (
              <Link
                key={item.categorySlug}
                href={`/stories/category/${item.categorySlug}`}
                className={cc([style.link, item.categorySlug === selected && style.active])}
              >
                {item.title || 'All'}
              </Link>
            ))}
          </div>
        </div>

        <div className={style.chipsMobile}>
          <div className={style.chipsContainer}>
            {categoriesMobile.map(item => (
              <Link
                key={item.categorySlug}
                href={`/stories/category/${item.categorySlug}`}
                className={cc([style.link, item.categorySlug === selected && style.active])}
              >
                {item.title || 'All'}
              </Link>
            ))}
          </div>
          <button
            onClick={() => {
              setState(!state);
            }}
            className={style.showMoreButton}
          >
            {state ? (
              <Icon className={style.icon} type="iconMin" />
            ) : (
              <Icon className={style.icon} type="iconPlus" />
            )}
            {state ? 'Less Topics' : 'More Topics'}
          </button>
        </div>
      </BasicRevealItem>
    </div>
  );
}

export default StoriesChips;
