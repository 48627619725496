// vendor
import React from 'react';
import cc from 'classcat';

// style
import style from './inputText.scss';

function InputText({ name, type = 'text', placeholder, className, inputClassName, innerRef }) {
  return (
    <label className={cc([style.input, className])} aria-label={name}>
      <input
        className={cc([style.inputText, inputClassName])}
        type={type}
        name={name}
        placeholder={placeholder}
        ref={innerRef}
      />
    </label>
  );
}

export default InputText;
