import React from 'react';
import cc from 'classcat';
import RevealGroup from '../../animation/revealGroup';
import Heading from '../../heading/heading';
import { Image } from 'dpdk-react-library';
import Container from '../../container/container';

// components
import Carousel from '../../carousel/carousel';

// style
import style from './clientSlider.scss';

function clientSlider({ testimonials, title }) {
  const testimonialLength = testimonials.filter(i => i.name).length;
  return (
    <Container
      className={style.container}
      classNameInner={style.content}
      mw="large"
      padding="small"
    >
      <div className={style.intro}>
        {title && (
          <Heading level="h2" size="h2">
            <p className={style.heading}>{title}</p>
          </Heading>
        )}
        <Carousel
          resizeInputs={400}
          className={cc([
            style.carouselSlider,
            testimonialLength < 3 && style.carouselSliderWithoutFade,
          ])}
        >
          {({ getItemProps }) => {
            return testimonials
              .filter(i => i.name)
              .map((item, i) => renderCarouselItem(item, getItemProps, i));
          }}
        </Carousel>
      </div>
    </Container>
  );
}

function renderCarouselItem(item, getItemProps, i) {
  switch (item.type) {
    case 'testimonial':
      return (
        <div
          {...getItemProps({ className: style.item })}
          onDragStart={e => e.preventDefault()}
          key={i.toString()}
        >
          <div className={style.content}>
            <div className={style.header}>
              {item.image && (
                <Image
                  {...item.image}
                  width={undefined}
                  height={undefined}
                  className={style.image}
                />
              )}
              <div className={style.detail}>
                {item.name && <p className={style.name}>{item.name}</p>}
                {item.job_position && <span className={style.jobTitle}>{item.job_position}</span>}
              </div>
            </div>
            <Heading level="h3" size="h4">
              {item.title && <p className={style.title}>{item.title}</p>}
            </Heading>
            {item.description && <p className={style.body}>{item.description}</p>}
          </div>
        </div>
      );
    default:
      return null;
  }
}

export default clientSlider;
