import Approach from '../pages/approach';
import AuthorDetail from '../pages/authorDetail';
import careersVacancies from '../pages/careersVacancies';
import careersPerks from '../pages/careersPerks';
import Capabilities from '../pages/capabilities';
import CapabilityDetail from '../pages/capabilityDetail';
import Commitments from '../pages/commitments';
import Company from '../pages/company';
import CompanyCulture from '../pages/companyCulture';
import Contact from '../pages/contact';
import Disclaimer from '../pages/disclaimer';
import Home from '../pages/home';
import NotFound from '../pages/notFound';
import OfferingDetail from '../pages/offeringDetail';
import Office from '../pages/office';
import OldBrowser from '../pages/oldBrowser';
import Partners from '../pages/partners';
import Privacy from '../pages/privacy';
import ServiceDetail from '../pages/serviceDetail';
import Featured from '../pages/insights/featured';
import Stories from '../pages/insights/stories';
import WhitePapers from '../pages/insights/whitepapers';
import StoryCategories from '../pages/insights/storyCategories';
import StoryDetail from '../pages/insights/storyDetail';
import WhitepaperDetail from '../pages/insights/whitepaperDetail';
import Work from '../pages/work';
import WorkDetail from '../pages/workDetail';
import Branding from '../pages/branding';
import WebDesign from '../pages/webdesign';
import Marketing from '../pages/marketing';
import Creative from '../pages/creative';
import DigitalStrategy from '../pages/digitalStrategy';
import DigitalTransformation from '../pages/digitalTransformation';

const routes = [
  {
    path: '/',
    // Page filename
    chunk: 'home',
    Comp: Home,
  },
  {
    path: '/work',
    chunk: 'work',
    Comp: Work,
  },
  {
    path: '/work/:slug',
    chunk: 'workDetail',
    Comp: WorkDetail,
  },
  {
    path: '/approach',
    chunk: 'approach',
    Comp: Approach,
  },
  {
    path: '/capabilities',
    chunk: 'capabilities',
    Comp: Capabilities,
  },
  {
    path: '/capabilities/:slug',
    chunk: 'capabilityDetail',
    Comp: CapabilityDetail,
  },
  {
    path: '/capability/:slug',
    chunk: 'capabilityDetail',
    Comp: CapabilityDetail,
  },
  {
    path: '/services/:slug',
    chunk: 'serviceDetail',
    Comp: ServiceDetail,
  },
  {
    path: '/partners',
    chunk: 'partners',
    Comp: Partners,
  },
  {
    path: '/offerings/:slug',
    chunk: 'offeringDetail',
    Comp: OfferingDetail,
  },
  {
    path: '/company',
    chunk: 'company',
    Comp: Company,
  },
  {
    path: '/company/culture',
    chunk: 'companyCulture',
    Comp: CompanyCulture,
  },
  {
    path: '/careers',
    chunk: 'careersVacancies',
    Comp: careersVacancies,
  },
  {
    path: '/careers/perks-and-benefits',
    chunk: 'careersPerks',
    Comp: careersPerks,
  },
  {
    path: '/insights',
    chunk: 'insights/featured',
    Comp: Featured,
  },
  {
    path: '/stories',
    chunk: 'insights/stories',
    Comp: Stories,
  },
  {
    path: '/whitepapers',
    chunk: 'insights/whitepapers',
    Comp: WhitePapers,
  },
  {
    path: '/stories/:slug',
    chunk: 'storyDetail',
    Comp: StoryDetail,
    folder: 'insights',
  },
  {
    path: '/stories/category/:slug',
    chunk: 'insights/storyCategories',
    Comp: StoryCategories,
  },
  {
    path: '/whitepaper/:slug',
    chunk: 'whitepaperDetail',
    Comp: WhitepaperDetail,
    folder: 'insights',
  },
  {
    path: '/author/:slug',
    chunk: 'authorDetail',
    Comp: AuthorDetail,
  },
  {
    path: '/contact',
    chunk: 'contact',
    Comp: Contact,
  },
  {
    path: '/digital-agency/:city',
    chunk: 'office',
    Comp: Office,
  },
  {
    path: '/branding-agency/:city',
    chunk: 'branding',
    Comp: Branding,
  },
  {
    path: '/webdesign-agency/:city',
    chunk: 'webdesign',
    Comp: WebDesign,
  },
  {
    path: '/performance-marketing-agency/:city',
    chunk: 'marketing',
    Comp: Marketing,
  },
  {
    path: '/creative-agency/:city',
    chunk: 'creative',
    Comp: Creative,
  },
  {
    path: '/digital-strategy-agency/:city',
    chunk: 'digitalStrategy',
    Comp: DigitalStrategy,
  },
  {
    path: '/digital-transformation-agency/:city',
    chunk: 'digitalTransformation',
    Comp: DigitalTransformation,
  },
  {
    path: '/terms-and-conditions',
    chunk: 'disclaimer',
    Comp: Disclaimer,
  },
  {
    path: '/privacy-policy',
    chunk: 'privacy',
    Comp: Privacy,
  },
  {
    path: '/commitments',
    chunk: 'commitments',
    Comp: Commitments,
  },
  {
    path: '/oldbrowser',
    chunk: 'oldBrowser',
    Comp: OldBrowser,
  },
  {
    default: true,
    chunk: 'notFound',
    Comp: NotFound,
  },
];

routes.forEach(route => {
  // This works with loadable but doesn't ouput chunks with readable names
  // const fn = () => import(`../pages/${route.chunk}`)
  // route.Comp = loadable(() => import(`../pages/${route.chunk}`));
  // route.prefetchComponent = () => import(`../pages/${route.chunk}`);
});

export default routes;
