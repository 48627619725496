import React from "react";
import style from "./imageWithOverlay.scss";
import Heading from "../../heading/heading";
import { Image } from "dpdk-react-library";

const ImageWithOverlay = props => {
  return (
    props.imageProps && (
      <div className={style.outer__wrapper}>
        <Image {...props.imageProps} />
        {
          props.overlayProps && (
            <div className={style.overlay__container}>
              <Heading level="h5" size="h5" weight="semibold" color="green">{props.overlayProps.overlay_title}</Heading>
              <p>{props.overlayProps.overlay_label}</p>
              <Heading level="h5" size="h5" weight="semibold" color="green">{props.overlayProps.overlay_subtitle}</Heading>
            </div>
          )
        }
      </div>
    )
  );
};

export default ImageWithOverlay;
