// vendor
import React from 'react';

// components
import WorkDouble from './workDouble/workDouble';
import WorkHero from './workHero/workHero';
import WorkImage from './workImage/workImage';
import WorkImageText from './workImageText/workImageText';
import WorkQuote from './workQuote/workQuote';
import WorkTechnology from './workTechnology/workTechnology';

// style
import style from './workContent.scss';

function WorkContent({ data }) {
  return (
    data.sections &&
    data.sections.length > 0 &&
    data.sections.map((unit, i) => (
      <article className={style.unit} key={i.toString()}>
        {displaySection(unit)}
      </article>
    ))
  );

  function displaySection(unit) {
    switch (unit.sectionType) {
      case 'single_media':
        return <WorkImage data={unit} />;
      case 'double_media':
        return <WorkDouble data={unit} />;
      case 'hero':
        return <WorkHero data={unit} color={data.color} />;
      case 'text_media':
        return <WorkImageText data={unit} />;
      case 'quote':
        return <WorkQuote data={unit} />;
      case 'technology':
        return <WorkTechnology data={unit} />;
      default:
        return null;
    }
  }
}

export default WorkContent;
