// vendor
import React, { useContext } from 'react';

// static content
import domainContext from '../../../context/domainContext';
import { useGlossary } from '../../../context/glossaryContext';

// components
import Icon from '../../icon/icon';
import SectionHeading from '../../sectionHeading/sectionHeading';

// utils
import scroll from '../../../utils/scroll';

// style
import style from './careersIntro.scss';

function CareersIntro(pageData) {
  const { t } = useGlossary();
  const data = useContext(domainContext);

function totalVacancies(pageSubtitle){
    let res = pageSubtitle.replace('[VACANCIES]', data.vacancyCount)
    return res
  }
  
  return (
    <>
      <SectionHeading
        className={style.heading}
        heading={pageData.pageTitle}
        body={
          <button className={style.link} onClick={() => scroll('.js-section-careers')}>
            {pageData.pageSubtitle && totalVacancies(pageData.pageSubtitle)}
            <Icon className={style.icon} type="arrowBottom" />
          </button>
        }
        mw="medium"
        padding="small"
        noOpacity
      />
    </>
  );
}

export default CareersIntro;
