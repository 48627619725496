// vendor
import React from 'react';

// components
import Icon from '../icon/icon';
import Link from '../link';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './capabilitySmall.scss';

function CapabilitySmall({ item }) {
  return (
    <BasicRevealItem as="li" className={style.item}>
      <Link
        href={`/capabilities${item.slug ? item.slug.replace('/capability', '') : ''}`}
        className={style.link}
      >
        {item.title}
        <Icon className={style.icon} type="arrowRightSmall" />
      </Link>
    </BasicRevealItem>
  );
}

export default CapabilitySmall;
