import anime from 'animejs';

function toCapabilityDetail(ref, promise) {
  const tl = anime.timeline();
  const clone = ref.cloneNode(true);

  const headerSize = 52;
  const padding = 0.18 * window.innerWidth;
  const fromMaxContentWidth = 1400;
  const destMaxContentWidth = 1080;
  const fromContentWidth = Math.min(window.innerWidth - padding, fromMaxContentWidth);
  const destContentWidth = Math.min(window.innerWidth - padding, destMaxContentWidth);
  const destWidth = 0.6 * destContentWidth;
  const destHeight = destWidth / 1.4;
  const destOffset = (fromContentWidth - destContentWidth) / 2 + (destContentWidth - destWidth);

  clone.style.cssText = `
    opacity: 0;
  `;

  ref.parentNode.style.cssText = `
    position: relative;
  `;

  ref.classList.add('background');

  ref.style.cssText = `
    z-index: 11;
    width: ${ref.clientWidth}px;
    height: ${ref.clientHeight}px;
    top: ${ref.offsetTop - 60}px;
    left: 0;
    transform: translateX(${ref.offsetLeft}px);
    position: absolute;
  `;

  ref.parentNode.insertBefore(clone, ref);
  const plane = document.createElement('div');
  plane.classList.add('plane');

  plane.style.cssText = `
    width: 100%;
    height: ${document.body.scrollHeight}px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: white;
    z-index: 10;
  `;
  ref.classList.add('interacted');
  document.querySelector('.page').appendChild(plane);

  tl.add({
    targets: ref,
    translateY: `${(ref.getBoundingClientRect().top - headerSize) * -1}px`,
    height: `${destHeight}px`,
    easing: 'easeInOutQuad',
    duration: 400,
  });
  tl.add(
    {
      targets: '.plane',
      easing: 'easeInOutQuad',
      duration: 400,
      opacity: [0, 1],
    },
    '-=400'
  );
  tl.add(
    {
      targets: ref.querySelectorAll('*'),
      easing: 'easeInOutQuad',
      duration: 400,
      opacity: [1, 0],
    },
    '-=400'
  );
  tl.add({
    targets: ref,
    easing: 'easeInOutQuad',
    duration: 400,
    translateX: `${destOffset}px`,
    width: `${destWidth}px`,
    height: `${destHeight}px`,
    complete: () => {
      promise.resolve();
      setTimeout(() => {}, 2000);
    },
  });
}

export default toCapabilityDetail;
