// vendor
import React from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import Icon from '../../icon/icon';
import { Image } from 'dpdk-react-library';
import Link from '../../link';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './officeDifferentLocation.scss';

function OfficeDifferentLocation({ agencyPageProps }) {
  const { t } = useGlossary();

  const { agency_page_title, agency_page_links } = agencyPageProps;

  return (
    <RevealGroup>
      <Container className={style.container} classNameInner={cc([style.content])} padding="small">
        {agency_page_title && (
          <div className={style.services}>
            <BasicRevealItem
              variation="left"
              as={Heading}
              className={style.heading}
              level="h2"
              size="h2"
            >
              {agency_page_title}
            </BasicRevealItem>

            {Array.isArray(agency_page_links) ? (
              <div className={style.list}>
                {agency_page_links.map((item, i) => (
                  <BasicRevealItem
                    as={Link}
                    href={item.href}
                    className={style.item}
                    key={i.toString()}
                  >
                    {item.title && <>{item.title}</>}
                    <Icon className={style.icon} type="arrowRightSmall" />
                  </BasicRevealItem>
                ))}
              </div>
            ) : (
              typeof agency_page_links == 'object' && (
                <div className={style.list}>
                  <BasicRevealItem as={Link} href={agency_page_links.href} className={style.item}>
                    {agency_page_links.title && <>{agency_page_links.title}</>}
                    <Icon className={style.icon} type="arrowRightSmall" />
                  </BasicRevealItem>
                </div>
              )
            )}
          </div>
        )}
      </Container>
    </RevealGroup>
  );
}

export default OfficeDifferentLocation;
