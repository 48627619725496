// vendor
import React from 'react';

// static content
import img from '../../../../public/media/images/company-story-trust.jpg';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './companyTrust.scss';

function CompanyTrust({ associations, awards }) {

  return (
    <RevealGroup stagger={200}>
      <Container
        className={style.container}
        classNameInner={style.content}
        mw="large"
        padding="small"
      >
        <div className={style.media}>
          <BasicRevealItem className={style.wrapper}>
            <Image
              className={style.image}
              aspectRatio={img.width / img.height}
              placeholder={img.placeholder}
              srcSet={img.srcSet}
              src={img.src}
            />
          </BasicRevealItem>
        </div>
        {associations && renderContent(associations)}
      </Container>
    </RevealGroup>
  );
}

function renderContent({ heading, body, items }) {
  return (
    <div className={style.col}>
      {heading && (
        <BasicRevealItem as={Heading} className={style.heading} level="h2" size="h3">
          {heading}
        </BasicRevealItem>
      )}
      {body && (
        <BasicRevealItem as={HTML} className={style.body}>
          {body}
        </BasicRevealItem>
      )}
      {items && items.length > 0 && (
        <BasicRevealItem as="ul" className={style.listing}>
          {items.map((item, i) => (
            <li className={style.item} key={i.toString()}>
              <Image className={style.logo} srcSet={item.srcSet} src={item.src} contain />
            </li>
          ))}
        </BasicRevealItem>
      )}
    </div>
  );
}

export default CompanyTrust;
