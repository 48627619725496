// vendor
import React, { useContext, useState } from 'react';
import cc from 'classcat';
import { oneOf } from 'prop-types';

// static content
import { useGlossary } from '../../context/glossaryContext';
import domainContext from '../../context/domainContext';

// components
import Button from '../button/button';
import Heading from '../heading/heading';
import HTML from '../html/html';
import Link from '../link';

// animation
import BasicRevealItem from '../animation/basicRevealItem';
import RevealGroup from '../animation/revealGroup';

// style
import style from './ctaBlock.scss';

CtaBlock.propTypes = {
  type: oneOf(['project', 'careers', 'combined']),
};

const hrefs = {
  careers: '/careers',
  project: '/contact',
};

function CtaBlock({ type = 'project', className, title, paragraph, label }) {
  const { t } = useGlossary();
  const data = useContext(domainContext);
  const comb = ['careers', 'project'];
  const [hover, setHover] = useState(false);
  const [hoverCombined, setHoverCombined] = useState(false);

  return (
    <RevealGroup>
      <div className={cc([style.container, type === 'combined' && style.combined, className])}>
        {type !== 'combined' ? (
          <Link
            className={style.container}
            href={hrefs[type]}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
          >
            <BasicRevealItem variation="right" className={style.col}>
              <Heading className={style.heading} level="h2" size="h2" weight="light">
                {title ? title : t(`cta.${type}.heading`, data.vacancyCount)}
              </Heading>
            </BasicRevealItem>
            <BasicRevealItem variation="right" className={cc([style.col, style.lined])}>
              <HTML className={cc([style.body, style.faded])}>
                {paragraph ? paragraph : t(`cta.${type}.body`)}
              </HTML>
              <Button className={style.button} hovered={hover} boxed>
                {label ? label : t(`cta.${type}.button.label`)}
              </Button>
            </BasicRevealItem>
          </Link>
        ) : (
          comb.map((item, i) => (
            <BasicRevealItem
              key={i}
              variation="right"
              className={style.col}
              onMouseEnter={() => {
                setHoverCombined(item);
              }}
              onMouseLeave={() => {
                setHoverCombined(false);
              }}
            >
              <Link className={style.link} href={hrefs[item]}>
                <Heading className={style.heading} level="h3" size="h4">
                  {t(`cta.${item}.heading`, data.vacancyCount)}
                </Heading>
                <HTML className={style.body}>{t(`cta.${item}.body`)}</HTML>
                <Button className={style.button} boxed hovered={hoverCombined === item && true}>
                  {t(`cta.${item}.button.label`)}
                </Button>
              </Link>
            </BasicRevealItem>
          ))
        )}
      </div>
    </RevealGroup>
  );
}

export default CtaBlock;
