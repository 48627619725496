// vendor
import React from 'react';
import cc from 'classcat';
import { string, oneOf, bool } from 'prop-types';

// components
import Icon from '../icon/icon';
import Link from '../link';

// style
import style from './readMore.scss';

ReadMore.propTypes = {
  label: string,
  hovered: bool,
  href: string,
  className: string,
  color: oneOf(['green', 'white']),
  as: string,
  inverted: bool,
};

function ReadMore({ label, href, hovered = false, color = 'green', className, as, inverted }) {
  const Comp = as || (href ? Link : 'span');

  return (
    <Comp
      className={cc([
        style.container,
        style[color],
        hovered && style.hover,
        inverted && style.inverted,
        className,
      ])}
      href={href}
    >
      {label && (
        <span className={style.inner}>
          <div className={style.label}>{label}</div>
          <Icon className={style.icon} type="arrowRight" />
          
        </span>
      )}
    </Comp>
  );
}

export default ReadMore;
