// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import CapabilitySmall from '../../capabilitySmall/capabilitySmall';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import StoriesGrid from '../../storiesGrid/storiesGrid';

// style
import style from './serviceRelated.scss';

function ServiceRelated({ capabilities, stories }) {
  const { t } = useGlossary();
  return (
    <>
     {stories && stories.length > 0 && (
        <Container className={style.relatedStories} padding="small">
          <Heading level="h3" size="h2" weight="light">
            {t('service.next.stories')}
          </Heading>
          <StoriesGrid items={stories} wide />
        </Container>
      )}
      {capabilities && capabilities.length > 0 && (
        <Container
          className={style.relatedCapabilities}
          classNameInner={style.inner}
          padding="small"
        >
          <Heading className={style.heading} level="h3" size="h2" weight="light">
            {t('capability.next.heading')}
          </Heading>
          <ul className={style.list}>
            {capabilities.map((item, i) => (
              <CapabilitySmall item={item} key={i.toString()} />
            ))}
          </ul>
        </Container>
      )}
    </>
  );
}

export default ServiceRelated;
