// vendor
import React from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../context/glossaryContext';

// components
import Button from '../button/button';
import Heading from '../heading/heading';
import HTML from '../html/html';
import { Image } from 'dpdk-react-library';

// utils
import scroll from '../../utils/scroll';
import getFirstName from '../../utils/getFirstName';

// style
import style from './contactCard.scss';

function ContactCard({ className, contact, title, body }) {
  const { t } = useGlossary();
  const firstName = getFirstName(contact.name);

  return (
    <div className={cc([style.card, className])}>
      {contact.photo && <Image {...contact.photo} aspectRatio={0.85} />}
      <div className={style.content}>
        <Heading level="h5" size="h5" weight="semibold">
          {contact.name}
        </Heading>
        <Heading
          className={style.function}
          level="h5"
          size="h6"
          color="green"
          weight="semibold"
          caps
        >
          {contact.function}
        </Heading>
        <HTML className={style.body}>
          {body ? body : t('cta.contact.card.body', title.toLowerCase())}
        </HTML>
        <Button onClick={() => scroll('.js-section-contact')} icon="arrowBottom" boxed>
          {t('cta.contact.card.button', firstName)}
        </Button>
      </div>
    </div>
  );
}

export default ContactCard;
