// vendor
import React from 'react';
import cc from 'classcat';

// components
import Heading from '../heading/heading';
import HTML from '../html/html';
import { Image } from 'dpdk-react-library';
import Link from '../link';
import TestimonialImage from './testimonialImage/testimonialImage';

// animation
import BasicRevealItem from '../animation/basicRevealItem';
import RevealGroup from '../animation/revealGroup';

// style
import style from './testimonial.scss';

function Testimonial({ data }) {
  const { quotee, position, quote, summary, logo, image, link = {} } = data;

  return (
    <RevealGroup className={style.container}>
      <div className={cc([style.caption, style.col])}>
        <BasicRevealItem as={link ? Link : 'cite'} {...link} className={style.cite}>
          {logo && (
            <Image className={style.logo} {...logo} noWidth width={undefined} height={undefined} />
          )}
          <span>
            {quotee && (
              <Heading className={style.quotee} level="h2" size="h5" weight="semibold">
                {quotee}
              </Heading>
            )}
            {position && (
              <Heading
                className={style.position}
                level="h2"
                size="h6"
                weight="semibold"
                color="green"
                caps
              >
                {position}
              </Heading>
            )}
          </span>
        </BasicRevealItem>
        {summary && (
          <BasicRevealItem className={style.body}>
            <HTML as="blockquote">{summary}</HTML>
          </BasicRevealItem>
        )}
        {quote && (
          <BasicRevealItem className={style.quote}>
            <HTML as="blockquote">{quote}</HTML>
          </BasicRevealItem>
        )}
      </div>
      {image && (
        <div className={cc([style.media, style.col])}>
          <TestimonialImage
            item={{ image, slug: link?.href}}
            className={style.card}
          />
        </div>
      )}
    </RevealGroup>
  );
}

export default Testimonial;
