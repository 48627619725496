import React from 'react';
import style from './storiesHeaderItem.scss';
import BasicRevealItem from '../../../animation/basicRevealItem';

function StoriesHeader(props) {
  return (
    <BasicRevealItem>
      <p className={style.sc__header}> {props.title} </p>
    </BasicRevealItem>
  );
}

export default StoriesHeader;
