import React, { memo, useState, useEffect } from 'react';
import ContactBanner from '../components/contactBanner/contactBanner';
import CtaSection from '../components/ctaSection/ctaSection';
import Page from '../components/page';
import CapabilityContent from '../components/sections/capabilityContent/capabilityContent';
import CapabilityHero from '../components/sections/capabilityHero/capabilityHero';
import CapabilityNext from '../components/sections/capabilityNext/capabilityNext';
import CapabilityRelated from '../components/sections/capabilityRelated/capabilityRelated';
import CapabilityServices from '../components/sections/capabilityServices/capabilityServices';
import { get } from '../utils/request';

const CapabilityDetail = memo(({ data }) => {
  const {
    body,
    heroImage,
    images,
    partners,
    services,
    title,
    relatedContent,
    capabilities,
    contactPerson,
    contactCaption,
    hubspotFormId,
  } = data;
  return (
    <Page>
      <CapabilityHero heading={title} image={heroImage} />
      <CapabilityContent
        items={body}
        partners={partners}
        contact={contactPerson}
        title={title}
        contactCaption={data.contactCaption}
      />
      <CapabilityServices images={images} items={services} />
      {data.contactPerson && (
        <ContactBanner
          caption={contactCaption}
          title={title}
          person={contactPerson}
          hubspotFormId={hubspotFormId}
          detail
        />
      )}
      <CapabilityRelated title={title} relatedContent={relatedContent} />
      <CapabilityNext nextCapabilities={capabilities} actualCapability={title} />
      <CtaSection type="project" wide />
    </Page>
  );
});

CapabilityDetail.getPageData = (match, env, location) => {
  return get(`/api/page/capability/${match.params.slug}`);
};

export default CapabilityDetail;
