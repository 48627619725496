// vendor
import React from 'react';

function Chevron({ ...props }) {
  return (
      <svg viewBox="0 0 7.639 12.451" {...props}>
        <path
            id="Path_1214"
            data-name="Path 1214" d="M14534.529-8819.88l-5.518,5.519,5.518,5.519"
            transform="translate(-14527.598 8820.587)"
        />
      </svg>
  );
}

export default Chevron;
