// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import Testimonial from '../../testimonial/testimonial';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';

// style
import style from './capabilitiesTestimonials.scss';

function CapabilitiesTestimonials({ quotesTitle, items }) {
  const { t } = useGlossary();

  return (
    <>
      <Container
        className={style.container}
        classNameInner={style.content}
        padding="small"
        mw="medium"
      >
        <BasicRevealItem variation="left">
          <Heading className={style.heading} level="h2" size="h3">
            {quotesTitle ? quotesTitle : t('capabilities.testimonials.heading')}
          </Heading>
        </BasicRevealItem>
        {items && items.length > 0 && (
          <div className={style.testimonials}>
            {items.map((item, i) => (
              <Testimonial data={item} key={i.toString()} />
            ))}
          </div>
        )}
      </Container>
    </>
  );
}

export default CapabilitiesTestimonials;
