// vendor
import React from 'react';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './contactQuote.scss';

function ContactQuote({ data }) {
  const text = data && data.quote_text;
  const quotee = data && data.quote_quotee;
  const position = data && data.quote_position;

  return (
    <RevealGroup>
      <div className={style.container}>
        <BasicRevealItem duration={1000} variation="right">
          <div className={style.quoteBox}>
            {text && <blockquote>{text}</blockquote>}
            {quotee && <cite>{quotee}</cite>}
            {position && <span>{position}</span>}
          </div>
        </BasicRevealItem>
      </div>
    </RevealGroup>
  );
}

export default ContactQuote;
