import React, { memo } from 'react';
import ContactBanner from '../components/contactBanner/contactBanner';
import Page from '../components/page';
import HomeCtaSection from '../components/sections/homeCtaSection/homeCtaSection';
import PartnerHero from '../components/sections/partnerHero/partnerHero';
import PartnersContent from '../components/sections/partnersContent/partnersContent';
import { get } from '../utils/request';

const Partners = memo(({ data }) => {
  const heroTitle = data.headerTitle || null;

  return (
    <Page>
      <PartnerHero title={heroTitle} />
      <PartnersContent data={data} />
      <HomeCtaSection />
      {data.contactPerson && (
        <ContactBanner
          caption={data.contactCaption}
          title={data.title}
          person={data.contactPerson}
          hubspotFormId={data.hubspotFormId}
          detail
        />
      )}
    </Page>
  );
});

Partners.getPageData = (match, env, location) => {
  return get(`/api/page/partners`);
};

export default Partners;
