function t(translations, key, ...values) {
  const text = translations[key];

  if (!text) return '-- INVALID TRANSLATION ID --';

  const parts = text.split(/({\w+})/g);
  let allText = true;

  values.forEach((value, i) => {
    const j = parts.indexOf(`{${i}}`);
    if (j > -1) {
      parts[j] = value;
    }
    if (typeof value !== 'string' && typeof value !== 'number') {
      allText = false;
    }
  });

  if (allText) {
    return parts.join('');
  }

  return parts;
}

// function translate(language, ...args) {
//   const lang = 'en';
//   if (!translations[lang]) throw new Error('No translations found for language');
//   return t(translations[lang], ...args);
// }

export { t };
