// vendor
import React from 'react';

// static content
import { useGlossary } from '../../context/glossaryContext';

// components
import Container from '../container/container';
import FooterNavigation from './footerNavigation/footerNavigation';
import Link from '../link';
import Logo from '../logo/logo';
import Newsletter from './newsletter/newsletter';

// style
import style from './footer.scss';

function Footer({ newsletterHidden = false }) {
  const { t } = useGlossary();

  return (
    <footer className={style.footer}>
      <div className={style.inner}>
        {!newsletterHidden && <Newsletter className={style.newsletter} />}
        <Container mw="medium" padding="small" classNameInner={style.brand}>
          <hr className={style.separator} />
          <Link href="/" className={style.content}>
            <Logo className={style.logo} type="basic" />
            <p className={style.catchPhrase}>{t('footer.catchphrase')}</p>
          </Link>
          <hr className={style.separator} />
        </Container>
        <FooterNavigation />
      </div>
    </footer>
  );
}

export default Footer;
