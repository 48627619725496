// vendor
import React, { useState } from 'react';
import dlv from 'dlv';
import cc from 'classcat';

// components
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';
import BackButton from '../../backButton/backButton';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './storiesHero.scss';

function StoriesHero({ item }) {
  const title = dlv(item, 'title', '');
  const slug = dlv(item, 'slug', '');
  const types = dlv(item, 'types', '');
  const images = dlv(item, 'images', []);
  const [hover, setHover] = useState(false);

  return (
    <RevealGroup className={style.cover} stagger={150}>
      <section
        href={`/stories${slug ? slug.replace('/story', '') : ''}`}
        className={cc([style.container, hover && style.hover, style.isDetail])}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className={style.back_button__container}>
          <BackButton className={cc([style.overviewButton, style.topLabel])} href="/stories">
            All stories
          </BackButton>
        </div>

        <BasicRevealItem variation="fade" className={style.imageContainer}>
          <Image className={style.image} {...images.landscape} height="100%" width="100%" />
        </BasicRevealItem>
      </section>

      <div className={style.info}>
        {types && (
          <BasicRevealItem variation="left" as={HTML} className={style.summary} color="white">
            {types}
          </BasicRevealItem>
        )}
        {title && (
          <BasicRevealItem
            variation="left"
            as={Heading}
            className={style.title}
            level="h1"
            color="white"
          >
            {title}
          </BasicRevealItem>
        )}
      </div>
    </RevealGroup>
  );
}

export default StoriesHero;
