function handleIntersection(el, opts) {
  const options = {
    onEnter: () => {},
    onLeave: () => {},
    onEnterOnce: () => {},
    marginBottom: 0,
    marginTop: 0,
    ...opts,
  };
  const vars = {
    hasEntered: false,
    isVisible: false,
    wasVisible: false,
  };

  function handleCallbacks(isIntersecting) {
    if (isIntersecting) {
      if (!vars.hasEntered) {
        vars.hasEntered = true;
        options.onEnterOnce();
      }
      if (!vars.wasVisible) {
        options.onEnter();
        vars.isVisible = true;
      }
    } else {
      if (vars.wasVisible) {
        options.onLeave();
        vars.isVisible = false;
      }
    }
  }

  if (IOSupported) {
    const io = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (el === entry.target) {
            vars.wasVisible = vars.isVisible;
            handleCallbacks(entry.intersectionRatio >= (options.threshold || 0.01));
          }
        });
      },
      {
        rootMargin: `${options.marginBottom}px 0px ${options.marginTop}px 0px`,
        threshold: options.threshold || 0.01,
      }
    );
    // Add element to the observer
    io.observe(el);

    return function unsubscribe() {
      io.unobserve(el);
      io.disconnect();
    };
  }

  function handleScroll() {
    const bounds = el.getBoundingClientRect();
    const marginTop = options.marginTop;
    const marginBottom = options.marginTop;

    vars.wasVisible = vars.isVisible;
    const isActuallyVisible =
      bounds.top - marginTop <= window.innerHeight && bounds.top + marginBottom >= -bounds.height;
    handleCallbacks(isActuallyVisible);
  }

  document.addEventListener('scroll', handleScroll);
  handleScroll();

  return function unsubscribe() {
    document.removeEventListener('scroll', handleScroll);
  };
}

const IOSupported = typeof window !== 'undefined' && window.IntersectionObserver;

export { handleIntersection };
