// vendor
import React from 'react';
import cc from 'classcat';

// components
import Container from '../../../container/container';
import Heading from '../../../heading/heading';
import HTML from '../../../html/html';
import WorkMedia from '../workMedia/workMedia';

// animation
import BasicRevealItem from '../../../animation/basicRevealItem';

// style
import style from './workImageText.scss';

function WorkImageText({ data }) {
  return (
    <Container
      classNameInner={cc([style.container, style[data.align]])}
      padding="large"
      mw="medium"
    >
      <BasicRevealItem className={style.content}>
        <Heading level="h2" size="h2" className={style.heading}>
          {data.title}
        </Heading>
        <HTML className={style.text}>{data.body}</HTML>
      </BasicRevealItem>
      <WorkMedia className={style.media} data={data} />
    </Container>
  );
}

export default WorkImageText;
