import HTML from '../html/html';
// components
import Heading from '../heading/heading';
import { Image } from 'dpdk-react-library';
// vendor
import React from 'react';
import cc from 'classcat';
// style
import style from './basicImageCaption.scss';

function BasicImageCaption({ data }) {
  const { image, caption, heading, text, alignment, wideImage } = data;

  return (
    <>
      {heading && (
        <Heading className={style.heading} level="h3" size="h5" weight="semibold">
          {heading}
        </Heading>
      )}
      <div
        className={cc([
          style.wrapper,
          wideImage && style.wideImage,
          image && style[`count${image.length}`],
        ])}
      >
        {image && !Array.isArray(image) ? (
          <Image className={style.image} {...image} />
        ) : (
          image.map((item, i) => (
            <div className={style.item} key={i.toString()}>
              <Image className={style.image} {...item} />
            </div>
          ))
        )}
        {caption && (
          <HTML
            className={cc([style.caption, alignment && style[alignment]])}
            as="div"
            size="small"
          >
            {caption}
          </HTML>
        )}
      </div>
      {text && (
        <HTML className={style.text} as="div">
          {text}
        </HTML>
      )}
    </>
  );
}

export default BasicImageCaption;
