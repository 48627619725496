// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import CapabilitySmall from '../../capabilitySmall/capabilitySmall';
import Container from '../../container/container';
import Heading from '../../heading/heading';

// style
import style from './capabilityNext.scss';

function CapabilityNext({ nextCapabilities, actualCapability }) {
  const { t } = useGlossary();
  const capabilities = nextCapabilities;

  return (
    <>
      {nextCapabilities && nextCapabilities.length > 0 && (
        <Container
          className={style.container}
          classNameInner={style.content}
          padding="small"
          mw="medium"
        >
          <Heading className={style.heading} level="h3" size="h2" weight="light">
            {t('capability.next.heading')}
          </Heading>
          <ul className={style.list}>
            {capabilities.map(
              (item, i) =>
                item.title === actualCapability || (
                  <CapabilitySmall item={item} key={i.toString()} />
                )
            )}
          </ul>
        </Container>
      )}
    </>
  );
}

export default CapabilityNext;
