// vendor
import React from 'react';

// static content
import image from '../../../../public/media/images/challenges-intro.jpg';

// components
import Container from '../../container/container';
import { Image } from 'dpdk-react-library';
import SectionHeading from '../../sectionHeading/sectionHeading';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './partnerHero.scss';

function PartnerHero({ title }) {
  return (
    <RevealGroup stagger={150}>
      <Container
        className={style.container}
        classNameInner={style.content}
        mw="medium"
        padding="small"
      >
        <SectionHeading
          className={style.heading}
          preheading="Partners"
          heading={title ? title : "We partner with the best companies of the industry"}
        />
        {image && (
          <BasicRevealItem variation="fade" className={style.imageContainer}>
            <Image
              className={style.image}
              {...image}
              noWidth
              width={undefined}
              height={undefined}
            />
          </BasicRevealItem>
        )}
      </Container>
    </RevealGroup>
  );
}

export default PartnerHero;
