// vendor
import React from 'react';
import cc from 'classcat';

// static content
import staticImage from '../../../../public/media/images/home-capabilities.jpg';
import { useGlossary } from '../../../context/glossaryContext';

// components
import Capability from '../../capability/capability';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import { Image } from 'dpdk-react-library';
import SectionHeading from '../../sectionHeading/sectionHeading';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './homeCapabilities.scss';

function HomeCapabilities({ items, minimal = false, title, dynamicImage }) {
  const { t } = useGlossary();
  const image = dynamicImage ? dynamicImage : staticImage;

  return (
    <RevealGroup as="section">
      {!minimal && (
        <SectionHeading
          className={style.header}
          heading={title ? title : t('home.capabilities.heading')}
          padding="large"
          isH2
        />
      )}
      <Container
        as="div"
        className={cc([style.container, minimal && style.story])}
        classNameInner={style.content}
        padding="small"
      >
        <div className={cc([style.media, minimal && style.minimal])}>
          {minimal && (
            <Heading className={style.title} level="h2" size="h3">
              {t('company.story.capabilities.heading')}
            </Heading>
          )}
          <BasicRevealItem>
            <div className={style.inner}>
              <Image
                className={style.image}
                aspectRatio={image.width / image.height}
                placeholder={image.placeholder}
                srcSet={image.srcSet}
                src={image.src}
                sizes="(max-width: 600px) 100vw, 600px"
              />
            </div>
          </BasicRevealItem>
        </div>
        {Array.isArray(items) && (
          <div className={style.items}>
            {items.map(item => (
              <Capability
                href={item.slug ? `/capabilities${item.slug.replace('/capability', '')}` : '/'}
                heading={item.title}
                summary={item.summary}
                key={item.title}
              />
            ))}
          </div>
        )}
      </Container>
    </RevealGroup>
  );
}

export default HomeCapabilities;
