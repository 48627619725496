import React, { useState, useEffect } from 'react';
import { Image } from 'dpdk-react-library';
import cc from 'classcat';
import style from './officeHeader.scss';
import BackButton from '../../backButton/backButton';
import Heading from '../../heading/heading';
import Container from '../../container/container';
import StructuredData from '../../structuredData';
import { getLocalTimeWithTimezone } from '../../../utils/formatTime';

const OfficeHeader = props => {
  const [currentTime, setCurrentTime] = useState(
    getLocalTimeWithTimezone(new Date(), props.headerProps.timezoneOffset)
  );
  useEffect(() => {
    const updateTime = setInterval(
      () => setCurrentTime(getLocalTimeWithTimezone(new Date(), props.headerProps.timezoneOffset)),
      30000
    );
    return () => {
      clearInterval(updateTime);
    };
  }, []);

  return (
    <Container padding="small">
      <StructuredData />

      <BackButton href="/contact" className={style.back__button}>
        <span>To contact</span>
      </BackButton>
      <div className={style.banner__container}>
        <div className={cc([style.image__container, style.banner__image])}>
          {props.headerProps.header_image && (
            <Image width="100%" height="100%" {...props.headerProps.header_image} />
          )}
        </div>
        <div className={style.header__content}>
          <div className={style.contact__container}>
            <div
              className={cc([style.image__container, style.office__preview])}
              onClick={() => props.scrollToOfficeSlider()}
            >
              {props.headerProps.card_image && (
                <Image
                  className={style.office__image}
                  width="100%"
                  height="100%"
                  {...props.headerProps.card_image}
                />
              )}
            </div>
            <div className={style.office__contact}>
              <div>
                <small>Phone number</small>
                <a href={`tel:${props.headerProps.telephone}`} className={style.phoneNumber}>
                  {props.headerProps.telephone}
                </a>
                <small>Address</small>
                <Heading level="h5" size="h5" weight="light">
                  {props.headerProps.address.map((addressPiece, i) => (
                    <div key={i}>{addressPiece}</div>
                  ))}
                </Heading>
              </div>
            </div>
          </div>
          <div className={style.heading__container}>
            <Heading level="h1" size="h2" weight="light">
              {props.headerProps.title}
            </Heading>
            <div className={style.office__time}>
              <small>Local time</small>
              <small>{currentTime}</small>
            </div>
          </div>
        </div>
        <div className={style.office__intro}>
          <Heading level="h3" size="h2" weight="thin" className={style.intro__heading}>
            {props.headerProps.where_we_started_title}
          </Heading>
          <div dangerouslySetInnerHTML={{ __html: props.headerProps.where_we_started }} />
        </div>
      </div>
    </Container>
  );
};

export default OfficeHeader;
