import { pick } from '@reach/router/lib/utils';
import routes from '../config/routes';
import parseQueryString from './parseQueryString';
import preferDefault from './preferDefault';
// import { prefetch } from './prefetcher';

function fetchPageComponent(path) {
  const match = pick(routes, path);
  if (match) {
    if (match.route.prefetchComponent) {
      return match.route.prefetchComponent();
    }
    return Promise.resolve(match.route.Comp);
  }

  return Promise.reject();
}

// `r` is `req` on server, Reach Router `undefined` on client
function fetchPageData(path, r) {
  const match = pick(routes, path);
  if (!match) return Promise.resolve(null); // Or maybe Promise.reject .. not sure
  const env = {
    ssr: __SSR__,
  };

  if (__SSR__) {
    const folder = match.route.folder ? `${match.route.folder}/` : '';
    const comp = require(`../pages/${folder}${match.route.chunk || 'notFound'}`).default;
    const location = {
      pathname: r.path,
      query: r.query,
    };
    return getComponentPageData(comp, match, env, location);
  }

  let pathname, search;
  if (r) {
    ({ pathname, search } = r);
  } else {
    [pathname, search] = path.split('?');
    search = `?${search || ''}`;
  }

  const location = {
    pathname,
    query: parseQueryString(search),
  };

  return fetchPageComponent(path)
    .then(preferDefault)
    .then(comp => getComponentPageData(comp, match, env, location));
}

function getComponentPageData(comp, match, env, location) {
  if (!comp.getPageData) return Promise.resolve(null);
  return Promise.resolve(comp.getPageData(match, env, location));
}

export { fetchPageComponent, fetchPageData };
