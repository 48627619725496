// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';
import chromeImg from '../../../../public/media/images/browser/chrome.png';
import firefoxImg from '../../../../public/media/images/browser/firefox.png';
import edgeImg from '../../../../public/media/images/browser/edge.png';

// components
import Button from '../../button/button';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';

// style
import style from './oldBrowserContent.scss';

function OldBrowserContent() {
  const { t } = useGlossary();
  const items = [
    {
      label: 'Chrome',
      href: 'https://www.google.com/intl/en/chrome/',
      image: chromeImg,
    },
    {
      label: 'Firefox',
      href: 'https://www.mozilla.org/en-US/firefox/new/',
      image: firefoxImg,
    },
    {
      label: 'Edge',
      href: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
      image: edgeImg,
    },
  ];

  return (
    <Container mw="medium" padding="small" className={style.container} spaced>
      <Heading level="h2" size="h2" align="center">
        {t('oldbrowser.heading')}
      </Heading>
      <HTML className={style.text}>{t('oldbrowser.body')}</HTML>
      <div className={style.browsers}>
        {items.map((item, i) => (
          <div className={style.item} key={i.toString()}>
            <Image
              {...item.image}
              className={style.image}
              removePlaceholderOnLoad
              width="200px"
              height="200px"
            />
            <Button className={style.button} href={item.href} icon="none" boxed>
              {t('oldbrowser.button.label', item.label)}
            </Button>
          </div>
        ))}
      </div>
      <Heading level="h4" size="h4" align="center">
        {t('oldbrowser.post')}
      </Heading>
    </Container>
  );
}

export default OldBrowserContent;
