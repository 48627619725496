// vendor
import React from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../context/glossaryContext';

// components
import Disclaimer from '../disclaimer/disclaimer';
import Link from '../link';
import Socials from '../socials/socials';

// style
import style from './navigation.scss';

const pageData = {
  pages: ['work', 'approach', 'capabilities', 'company', 'insights', 'careers', 'contact'],
};

function Navigation({ data, open, className = '', setOpen }) {
  const { t } = useGlossary();
  return (
    <nav className={cc([style.container, open && style.open, className])} id="mainNavigation">
      <div className={style.pages}>
        {pageData.pages.map((anchor, i) => (
          <Link
            className={cc([style.anchor, style.animates])}
            activeClassName={style.active}
            href={`/${anchor}`}
            key={i.toString()}
            aria-label={anchor}
            onClick={() => setOpen(false)}
            style={{ transitionDelay: `${i * 80}ms` }}
          >
            {t(`header.nav.${anchor}`)}
          </Link>
        ))}
      </div>
      <Socials className={cc([style.socials, style.animates])} />
      <Disclaimer className={cc([style.disclaimer, style.animates])} />
    </nav>
  );
}

export default Navigation;