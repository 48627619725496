// vendor
import React from 'react';
import { number, object, oneOf, shape } from 'prop-types';

// animation
import RevealItem from './revealItem';

// hooks
import useSimpleRevealAnimation from '../../hooks/useSimpleRevealAnimation';

BasicRevealItem.propTypes = {
  variation: oneOf(['bottom', 'left', 'fade', 'right']),
  // either choose a variation or provider your own styles
  styles: shape({
    from: object.isRequired,
    to: object.isRequired,
  }),
  duration: number, // in ms
};

const variations = {
  fade: {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  bottom: {
    from: {
      opacity: 0,
      translateY: 200,
    },
    to: {
      opacity: 1,
      translateY: 0,
    },
  },
  left: {
    from: {
      opacity: 0,
      translateX: -200,
    },
    to: {
      opacity: 1,
      translateX: 0,
    },
  },
  right: {
    from: {
      opacity: 0,
      translateX: 200,
    },
    to: {
      opacity: 1,
      translateX: 0,
    },
  },
};

function BasicRevealItem({
  styles: overrideStyles,
  variation = 'bottom',
  style,
  duration,
  delay,
  ...rest
}) {
  const styles = overrideStyles || variations[variation];
  const { inlineStyle, animate, ref } = useSimpleRevealAnimation(styles, duration, delay);

  return (
    <RevealItem
      animate={animate}
      marginTop={variation === 'bottom' ? 200 : 0}
      {...rest}
      innerRef={ref}
      style={{ ...inlineStyle, ...style }}
    />
  );
}

export default BasicRevealItem;
