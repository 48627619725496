// vendor
import React from 'react';
import dlv from 'dlv';
// import Helmet from 'react-helmet';

// animation
import BasicRevealItem from '../../components/animation/basicRevealItem';

// components
import BackButton from '../../components/backButton/backButton';
import ContactBanner from '../../components/contactBanner/contactBanner';
import Page from '../../components/page';
import StoriesChips from '../../components/sections/storiesChips/storiesChips';
import StoriesFeatured from '../../components/sections/storiesFeatured/storiesFeatured';
import StoriesGrid from '../../components/storiesGrid/storiesGrid';
import StoriesHeaderItem from '../../components/sections/storiesHeader/storiesHeaderItem/storiesHeaderItem';
import StorieHeader from '../../components/sections/storiesHeader/storiesHeader';
import StoryIntro from '../../components/sections/storyIntro/storyIntro';
import Whitepaper from '../../components/sections/whitepaper/whitepaper';
import Hubspot from '../../components/sections/hubspot/Hubspot';

// style
import style from './whitepaperDetail.scss';

// utils
import { get } from '../../utils/request';

function WhitepaperDetail({ data }) {
  const items = dlv(data, 'items', {});
  const featuredStories = dlv(items, 'featuredStories', []);
  const authorData = dlv(items, 'authorData.0', []);
  const authorName = dlv(authorData, '0.name', '');
  const categories = dlv(data, 'categories', []);
  const hubspot = dlv(items, 'hubspot', []);
  const wpTitle = dlv(items, 'title', []);
  const wpSummary = dlv(items, 'summary', []);
  const whitepaper = { image: dlv(items, 'image', null) };
  const contactCaption = dlv(data, 'items.contactCaption', null);
  const categoriesTitle = dlv(data, 'items.categoriesTitle', null);

  return (
    <Page type="whitepaper" SEOProps={data.items.seo}>
      <BasicRevealItem>
        <BackButton className={style.overviewButton} href="/whitepapers">
          All whitepapers
        </BackButton>
      </BasicRevealItem>

      <StoriesFeatured addSpacing>
        <Whitepaper
          wpTitle={wpTitle}
          wpSummary={wpSummary}
          whitepaper={whitepaper}
          hideLabel={true}
        />
      </StoriesFeatured>

      {items.hubspot_to_top &&
        hubspot &&
        hubspot.map((item, key) => {
          return (
            <div style={{ background: '#F5F5F5' }} key={`${item.formId} ${key}`}>
              <BasicRevealItem>
                <div className={style.formHeaderContainer}>
                  <div className={style.formHeader}>{item.title || 'Download our report'}</div>
                  <div className={style.formText}>
                    {item.caption || 'Please, complete the form before to download our report'}
                  </div>
                </div>
                <Hubspot
                  appendId="whitepaper"
                  className={style.whitepaperForm}
                  portalId="6265534"
                  formId={item.formId || '715f9d3e-aa6c-4eb5-9bef-ada6dbe4b044'}
                />
              </BasicRevealItem>
            </div>
          );
        })}

      <div className={style.textSection}>
        <StoryIntro data={data.items} isWhitepaper />
      </div>

      {!items.hubspot_to_top &&
        hubspot &&
        hubspot.map((item, key) => {
          return (
            <div style={{ background: '#FFF' }} key={`${item.formId} ${key}`}>
              <BasicRevealItem>
                <div className={style.formHeaderContainer}>
                  <div className={style.formHeader}>{item.title || 'Download our report'}</div>
                  <div className={style.formText}>
                    {item.caption || 'Please, complete the form before to download our report'}
                  </div>
                </div>
                <Hubspot
                  appendId="whitepaper"
                  className={style.whitepaperForm}
                  portalId="6265534"
                  formId={item.formId || '715f9d3e-aa6c-4eb5-9bef-ada6dbe4b044'}
                />
              </BasicRevealItem>
            </div>
          );
        })}

      {featuredStories && (
        <StoriesFeatured>
          <StoriesHeaderItem title="RELATED STORIES" />
          <div style={{ marginBottom: '50px', background: '#FFF' }}>
            <StoriesGrid items={featuredStories.slice(0, 3)} variation="wide" grayLabel={true} />
          </div>
        </StoriesFeatured>
      )}

      <StorieHeader
        bg="white"
        subHeader=""
        mainHeader={
          categoriesTitle ? categoriesTitle : 'Sharing knowledge and insights from here to eternity'
        }
        addSpacing
      />

      <StoriesFeatured>
        <StoriesChips categories={categories} selected="branding" whiteBackground={true} />
      </StoriesFeatured>

      {authorData && (
        <ContactBanner
          name={authorName}
          caption={contactCaption}
          title={contactCaption}
          person={authorData}
          hubspotFormId={null}
          noMarginBottom
        />
      )}
    </Page>
  );
}

WhitepaperDetail.getPageData = (match, env, location) => {
  return get(`/api/page/whitepaper/${match.params.slug}`);
};

export default WhitepaperDetail;
