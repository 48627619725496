// vendor
import cc from 'classcat';
import React from 'react';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// components
import Container from '../container/container';
import Video from '../video/video';

// style
import style from './showReel.scss';

function ShowReel({ className = '', thumbnail, video }) {
  return (
    <BasicRevealItem>
      <Container
        className={cc([style.container, className])}
        classNameInner={style.content}
        padding="small"
      >
        <Video
          source={video ? video : '/media/videos/showreel.mp4'}
          placeholder={
            thumbnail ? thumbnail.originalWebp : '/media/images/videoPoster/nike-thumbnail.png'
          }
          expanding
        />
      </Container>
    </BasicRevealItem>
  );
}

export default ShowReel;
