import React, { memo, useState, useEffect } from 'react';
import ContactBanner from '../components/contactBanner/contactBanner';
import Page from '../components/page';
import CareersGrid from '../components/sections/careersGrid/careersGrid';
import CareersIntro from '../components/sections/careersIntro/careersIntro';
import Tabs from '../components/sections/Tabs/Tabs';
import { get } from '../utils/request';

const careersVacancies = memo(({ data }) => {

  const pages = [
    {
      href: '/careers',
      label: 'Vacancies',
      type: 'vacancies',
    },
    {
      href: '/careers/perks-and-benefits',
      label: 'Perks & Benefits',
      type: 'perks',
    },
  ];

  return (
    <Page newsletterHidden>
      <Tabs current="vacancies" pages={pages} />
      <CareersIntro pageTitle={data.pageTitle} pageSubtitle={data.pageSubtitle} />
      {data.vacancies && <CareersGrid items={data.vacancies} filters={data.filterOptions} />}
      {data.contactPerson && (
        <ContactBanner
          caption={data.contactFormTitle}
          title={data.title}
          person={data.contactPerson}
          hubspotFormId={data.hubspotFormId}
          footer
        />
      )}
    </Page>
  );
});

careersVacancies.getPageData = (match, env, location) => {
  return get(`/api/page/company/careers`);
};

export default careersVacancies;
