import BasicImageCaption from '../basicImageCaption/basicImageCaption';
import BasicQuote from '../basicQuote/basicQuote';
import BasicCTA from '../basicCTA/basicCTA';
// components
import BasicRevealItem from '../animation/basicRevealItem';
import BasicVideo from '../basicVideo/basicVideo';
import Container from '../container/container';
import HTML from '../html/html';
import Heading from '../heading/heading';
// vendor
import React from 'react';
import cc from 'classcat';
// style
import style from './basicContent.scss';

function BasicContent({ sections, padding = 'large', className = '', isWhitepaper = false }) {
  return (
    <BasicRevealItem>
      <Container
        className={cc([style.container, isWhitepaper && style.isWhitepaper])}
        classNameInner={cc([style.content, className])}
        padding={padding}
        mw="small"
      >
        {sections &&
          sections.length > 0 &&
          sections.map((section, i) => (
            <div className={style.unit} key={i.toString()}>
              {(section.type === 'image' || section.type === 'text_and_image') && (
                <BasicImageCaption data={section} />
              )}
              {section.type === 'video' && <BasicVideo {...section} />}
              {section.type === 'text' && (
                <>
                  {section.heading && (
                    <Heading className={style.heading} level="h3" size="h5">
                      {section.heading}
                    </Heading>
                  )}
                  {section.summary && (
                    <HTML className={style.summary} as="div">
                      {section.summary}
                    </HTML>
                  )}
                  {section.text && (
                    <HTML className={style.body} as="div">
                      {section.text}
                    </HTML>
                  )}
                </>
              )}
              {section.type === 'quote' && (
                <BasicQuote quote={section.text} author={section.author} />
              )}
              {section.type === 'CTA' && (
                <BasicCTA disclaimer={section.disclaimer} ctaLabel={section.link.title} link={section.link.href} />
              )}
            </div>
          ))}
      </Container>
    </BasicRevealItem>
  );
}

export default BasicContent;
