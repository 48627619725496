// vendor
import React from 'react';

// components
import { Image } from 'dpdk-react-library';
import Button from '../../button/button';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './contactWhitepaper.scss';

function ContactWhitepaper({ data }) {
  const title = data && data.whitepaper_title;
  const whitepaper = data && data.whitepaper;
  const wpTitle = whitepaper && whitepaper.title;
  const image = whitepaper && whitepaper.image;
  const slug = whitepaper && whitepaper.slug;
  const summary = whitepaper && whitepaper.summary;
  const ctaLabel = whitepaper && whitepaper.cta_label;

  return (
    <RevealGroup>
      <Container className={style.container} padding="small">
        {title && (
          <div className={style.desktopView}>
            <BasicRevealItem duration={1000} variation="left">
              <Heading level="h1" size="h2" weight="light">
                {title}
              </Heading>
            </BasicRevealItem>
          </div>
        )}

        <BasicRevealItem duration={1000} variation="left">
          <div className={style.whitepaper}>
            {title && (
              <div className={style.mobileView}>
                <BasicRevealItem duration={1000} variation="left">
                  <Heading level="h1" size="h2" weight="light">
                    {title}
                  </Heading>
                </BasicRevealItem>
              </div>
            )}

            <div className={style.imageWrapper}>
              {image && <Image {...image} width="100%" height="100%" />}
            </div>

            <div className={style.content}>
              <BasicRevealItem variation="right">
                <Heading level="h3" size="h6" weight="semibold" caps color="green">
                  white paper
                </Heading>
              </BasicRevealItem>

              {wpTitle && (
                <BasicRevealItem variation="right">
                  <Heading level="h2" size="h4" className={style.heading}>
                    {wpTitle}
                  </Heading>
                </BasicRevealItem>
              )}

              {summary && (
                <BasicRevealItem variation="right">
                  <HTML className={style.text}>{summary}</HTML>
                </BasicRevealItem>
              )}

              <BasicRevealItem variation="right">
                <Button href={slug} icon="arrowRight" boxed className={style.button}>
                  {ctaLabel !== null ? ctaLabel : 'Download the PDF'}
                </Button>
              </BasicRevealItem>
            </div>
          </div>
        </BasicRevealItem>
      </Container>
    </RevealGroup>
  );
}

export default ContactWhitepaper;
