// vendor
import React, { useRef } from 'react';
import cc from 'classcat';
import anime from 'animejs';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import BackButton from '../../backButton/backButton';
import Container from '../../container/container';
import { Image } from 'dpdk-react-library';
import SectionHeading from '../../sectionHeading/sectionHeading';
import StructuredData from '../../structuredData';

// animation
import BlockingRevealItem from '../../animation/blockingRevealItem';

// utils
import createDeferredPromise from '../../../utils/createDeferredPromise';

// style
import style from './capabilityHero.scss';

function CapabilityHero({ heading, image }) {
  const { t } = useGlossary();
  const cover = useRef();

  function animate() {
    const promise = createDeferredPromise();

    anime({
      targets: cover.current,
      scaleX: 0,
      duration: 700,
      easing: 'easeInOutCubic',
      complete() {
        promise.resolve();
      },
    });

    return promise;
  }

  return (
    <Container
      className={style.container}
      classNameInner={style.content}
      mw="medium"
      padding="small"
    >
      <StructuredData />

      <BackButton className={style.button} href="/capabilities">
        {t('label.toOverview')}
      </BackButton>
      {heading && (
        <SectionHeading
          className={style.heading}
          preheading={t('capability.preheading')}
          heading={heading}
          revealGroupStyle={{ flex: 1 }}
        />
      )}
      {image && (
        <BlockingRevealItem animate={animate}>
          <div className={style.media}>
            <div className={style.inner}>
              <span className={cc([style.cover, 'reveal-cover'])} ref={cover} />
              {typeof image !== 'undefined' && (
                <Image
                  className={style.image}
                  {...image}
                  aspectRatio={1.4}
                  width={undefined}
                  height={undefined}
                />
              )}
            </div>
          </div>
        </BlockingRevealItem>
      )}
    </Container>
  );
}

export default CapabilityHero;
