import { useContext } from 'react';
import dataContext from '../context/dataContext';
import { fetchPageData } from '../utils/routing';
// import routeContext from '../context/routeContext';

function useDataFetcher() {
  const dataState = useContext(dataContext);
  // const loc = useContext(routeContext);

  function fetchData(dataKey) {
    return dataState.load(dataKey, () => fetchPageData(dataKey)); // assuming dataKey is a href. TODO: support other kinds of data sources
  }

  return fetchData;
}

export default useDataFetcher;
