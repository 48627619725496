// vendor
import React, { useMemo } from 'react';
import { Location, Router } from '@reach/router';
import { isMobile } from 'dpdk-devicedetection';

// context
import domainContext from '../context/domainContext';
import { GlossaryProvider } from '../context/glossaryContext';
import locationContext from '../context/locationContext';
import pageContext from '../context/pageContext';

// components
import ConsentBar from './consentBar/consentBar';
import Header from './header/header';
import DataProvider from './dataProvider';
import PageRenderer from './pageRenderer';
import transitions from '../transitions';

// animation
import RevealPage from './animation/revealPage';

// utils
import createDeferredPromise from '../utils/createDeferredPromise';

// config
import routes from '../config/routes';

function Root({ initialData, domainData, location: serverLocation }) {
  const value = useMemo(
    () => ({
      animateThenNavigate(ref, animation = 'general') {
        // eslint-disable-next-line
        if (isMobile() || window.innerWidth < 1000) animation = 'general';
        const promise = createDeferredPromise();
        if (matchMedia('(prefers-reduced-motion: reduce)').matches) {
          promise.resolve();
        } else {
          transitions[animation](ref, promise);
        }

        return promise;
      },
    }),
    []
  );
  return (
    <DataProvider initialData={initialData}>
      <GlossaryProvider>
        <domainContext.Provider value={domainData}>
          <pageContext.Provider value={value}>
            <RevealPage>
              <Location>
                {({ location }) => (
                  <locationContext.Provider value={{ ...serverLocation, ...location }}>
                    <Header />
                    <ConsentBar />
                    <Router location={location}>
                      {routes.map(routeProps => (
                        <PageRenderer
                          key={`${routeProps.path}${location.key}` || '404'}
                          {...routeProps}
                        />
                      ))}
                    </Router>
                  </locationContext.Provider>
                )}
              </Location>
            </RevealPage>
          </pageContext.Provider>
        </domainContext.Provider>
      </GlossaryProvider>
    </DataProvider>
  );
}

export default Root;
