// vendor
import React from 'react';

function Instagram({ ...props }) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M68.75,0h-37.5C13.994,0,0,13.994,0,31.25v37.5C0,86.006,13.994,100,31.25,100h37.5C86.006,100,100,86.006,100,68.75v-37.5C100,13.994,86.006,0,68.75,0z M90.625,68.75c0,12.062-9.812,21.875-21.875,21.875h-37.5c-12.062,0-21.875-9.812-21.875-21.875v-37.5c0-12.062,9.812-21.875,21.875-21.875h37.5c12.062,0,21.875,9.813,21.875,21.875V68.75z" />
      <path d="M50,25c-13.806,0-25,11.194-25,25s11.194,25,25,25s25-11.194,25-25S63.806,25,50,25z M50,65.625c-8.613,0-15.625-7.012-15.625-15.625c0-8.619,7.012-15.625,15.625-15.625S65.625,41.381,65.625,50C65.625,58.613,58.613,65.625,50,65.625z" />
      <circle cx="76.875" cy="23.125" r="3.331" />
    </svg>
  );
}

export default Instagram;
