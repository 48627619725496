// vendor
import React, { useContext, useRef } from 'react';
import cc from 'classcat';
import { bool, func, node, object, string } from 'prop-types';
import { Link, navigate } from '@reach/router';

// context
import locationContext from '../context/locationContext';

import pageContext from '../context/pageContext';

// utils
import useDataFetcher from '../hooks/useDataFetcher';

InternalLink.propTypes = {
  activeClassName: string,
  activeStyle: object,
  href: string,
  children: node,
  // preloadOn: oneOf(['viewportEnter', 'hover', 'mount']), // just 'hover' for now
  dontPreload: bool,
  onClick: func,
  innerRef: object,
};

function InternalLink({
  href,
  innerRef: ref = useRef(),
  children,
  style,
  className,
  activeClassName,
  activeStyle,
  preloadOn,
  dontPreload,
  activeProps,
  onClick,
  animation,
  ...rest
}) {
  const context = useContext(pageContext);
  const location = useContext(locationContext);
  const fetchData = useDataFetcher();
  function prefetch() {
    if (!dontPreload) fetchData(href);
  }

  function handleClick(event) {
    if (onClick) onClick(event);
    if (shouldNavigate(event)) {
      // TODO: Move this logic somewhere else
      event.preventDefault();
      fetchData(href)
        // eslint-disable-next-line
        .then(() => {
          if (location.pathname.split('?')[0] !== href) {
            return context.animateThenNavigate(ref.current, animation);
          }
        })
        .then(() => navigate(href));
    }
  }

  return (
    <Link
      to={href}
      ref={ref}
      onMouseOver={prefetch}
      onClick={handleClick}
      getProps={({ isCurrent, isPartiallyCurrent }) =>
        isPartiallyCurrent
          ? {
              style: { ...style, ...activeStyle },
              className: cc([className, activeClassName]),
              ...activeProps,
            }
          : { style, className }
      }
      {...rest}
    >
      {children}
    </Link>
  );
}

function shouldNavigate(event) {
  return (
    !event.defaultPrevented &&
    event.button === 0 &&
    !(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
  );
}

export default InternalLink;
