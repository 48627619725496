import React, { memo } from 'react';
import ContactBanner from '../components/contactBanner/contactBanner';
import Page from '../components/page';
import OfferingContactBanner from '../components/sections/offeringContactBanner/offeringContactBanner';
import OfferingContent from '../components/sections/offeringContent/offeringContent';
import OfferingIntro from '../components/sections/offeringIntro/offeringIntro';
import OfferingReasons from '../components/sections/offeringReasons/offeringReasons';
import OfferingRelatedWork from '../components/sections/offeringRelatedWork/offeringRelatedWork';
import { get } from '../utils/request';

const OfferingDetail = memo(({ data }) => {
  return (
    <Page newsletterHidden>
      <OfferingIntro data={data} />
      <OfferingContent data={data} />
      <OfferingContactBanner contact={data.contactPerson} body={data.contactPersonCta2} />
      <OfferingReasons data={data} />
      {data.relatedWork && <OfferingRelatedWork data={data.relatedWork} />}
      <ContactBanner
        caption={data.contactFormTitle}
        subtitle={data.contactFormSubtitle}
        person={data.contactPerson}
        hubspotFormId={data.hubspotFormId}
        footer
      />
    </Page>
  );
});

OfferingDetail.getPageData = (match, env, location) => {
  return get(`/api/page/offerings/${match.params.slug}`);
};

export default OfferingDetail;
