// vendor
import React, { useState, useEffect } from 'react';
import cc from 'classcat';
import Icon from '../../icon/icon';

// animation
import RevealGroup from '../../animation/revealGroup';

// components
import CareerItem from '../../careerItem/careerItem';
import Container from '../../container/container';

// style
import style from './careersGrid.scss';

function CareersGrid({ items, filters }) {
  const [vacancies, setQuestions] = useState(items || []);
  const [activeFilter, setActiveFilter] = useState('All');
  const [showAllFilters, setShowAllFilters] = useState(false);

  const categoriesMobile = showAllFilters ? filters : filters?.slice(0, 3);

  function showSelectedVacancies(filter) {
    setActiveFilter(filter.title);
    let res = [];
    if (filter.title !== 'All') {
      for (let i = 0; i < items.length; i++) {
          for(let j = 0; j < filter.keywords.length; j++){
              if(items[i].title.includes(filter.keywords[j])){
                  res.push(items[i]);
                  break;
              }
          }
      }
    }

    setQuestions(filter.title !== 'All' ? res : items);
  }

  return (
    <RevealGroup stagger={150}>
      <Container
        className={cc([style.container, 'js-section-careers'])}
        classNameInner={style.content}
        mw="large"
        padding="small"
      >
        <div className={style.chipsContainer}>
          <div className={style.chipsDesktop}>
            {Array.isArray(filters) &&
              filters.map((filter, key) => (
                <li key={key} className={style.list}>
                  <p
                    onClick={() => showSelectedVacancies(filter)}
                    className={cc([style.link, filter.title === activeFilter && style.active])}
                  >
                    {filter.title}
                  </p>
                </li>
              ))}
          </div>
          <div className={style.chipsMobile}>
            {Array.isArray(categoriesMobile) &&
              categoriesMobile.map((filter, key) => (
                <li key={key} className={style.list}>
                  <p
                    onClick={() => showSelectedVacancies(filter)}
                    className={cc([style.link, filter.title === activeFilter && style.active])}
                  >
                    {filter.title}
                  </p>
                </li>
              ))}
            <button
              onClick={() => {
                setShowAllFilters(!showAllFilters);
              }}
              className={style.showMoreButton}
            >
              {showAllFilters ? (
                <Icon className={style.icon} type="iconMin" />
              ) : (
                <Icon className={style.icon} type="iconPlus" />
              )}
              {showAllFilters ? 'Less Topics' : 'More Topics'}
            </button>
          </div>
        </div>
        {vacancies && vacancies.length > 0 ? (
          <div className={style.grid}>
            {vacancies.map((item, i) => (
              <CareerItem className={style.item} item={item} key={i.toString()} />
            ))}
          </div>
        ) : (
          <p className={style.nonAvailable}>Sorry, there are no open positions available at this time.</p>
        )}
      </Container>
    </RevealGroup>
  );
}

export default CareersGrid;
