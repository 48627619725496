// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Container from '../../container/container';
import SectionHeading from '../../sectionHeading/sectionHeading';
import WorkGrid from '../../workGrid/workGrid';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './workHighlight.scss';

function WorkHighlight({ data }) {
  const { t } = useGlossary();
  return (
    <RevealGroup>
      <BasicRevealItem>
        <SectionHeading
          className={style.header}
          preheading={t('work.preheading')}
          heading={t('work.heading')}
          padding="large"
        />
      </BasicRevealItem>
      <Container className={style.container} classNameInner={style.content} padding="small" spaced>
        <WorkGrid items={data.items} bigShadow hasHigherLevelHeadings />
      </Container>
    </RevealGroup>
  );
}

export default WorkHighlight;
