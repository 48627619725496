// vendor
import React from 'react';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import PartnerGrid from '../../partnerGrid/partnerGrid';

// style
import style from './partnersContent.scss';

function PartnersContent({ data }) {
  const { partners, content } = data;

  return (
    <>
      <Container className={style.intro} mw="medium" padding="small">
        <Heading className={style.heading} level="h4" size="h3">
          {content[0].heading}
        </Heading>
        <HTML className={style.text}>{content[0].text}</HTML>
      </Container>
      {partners && (
        <Container className={style.partners} mw="medium" padding="small">
          <PartnerGrid items={partners} hasNoRedirectToOverview />
        </Container>
      )}
    </>
  );
}

export default PartnersContent;
