function getAgencyNewYorkStructureData() {
  let question1 = "What is a digital agency?";
  let question2 = "What can our New York digital agency do for you?";
  let question3 = "Why choose DPDK as a digital agency?";
  let answer1 =
    "At DPDK, we help our clients build and accelerate their digital presence. We are a digital agency that grows businesses by combining technology, design, and creativity. We transform brands and shape industries by delivering exceptional digitalism.";
  let answer2 =
    "Our digital agency in New York grows businesses by combining technology, design, and creativity. We build and accelerate your digital presence through tailored strategies, a unique brand identity, innovative products, and content that converts. We deliver digital awesomeness to the most innovative brands in Manhattan and beyond. ";
  let answer3 =
    "We’ve been in business for more than 20 years, and know how to create exceptional digital work. Our no-nonsense attitude and fierce drive to deliver top-notch digital experiences make DPDK an experienced partner for bold brands that are ready to push their marketing ambitions to a new level. ";
  let address = JSON.stringify({
    '@type': 'PostalAddress',
    addressLocality: 'New York City',
    addressRegion: 'New York',
    postalCode: '11249',
    streetAddress: '185 Whyte Ave',
  });

  return {
    type: 'application/ld+json',
    innerHTML: `
      {
         "@context":"https://schema.org",
         "@type":"FAQPage",
        "name": "DPDK",
        "description": "Digital Marketing Agency",
        "image": "https://dpdk.com/assets/domain/share_image/dpdk-facebook_0.jpg",
        "priceRange": "$2000 to $10000",
        "address": ${address},
        "telephone": "(718) 782-4050",
         "mainEntity":[
            {
               "@type":"Question",
               "name": "${question1}",
               "acceptedAnswer":{
                  "@type":"Answer",
                  "text": "${answer1}"
               }
            },
            {
               "@type":"Question",
               "name": "${question2}",
               "acceptedAnswer":{
                  "@type":"Answer",
                  "text": "${answer2}"
               }
            },
            {
               "@type":"Question",
               "name": "${question3}",
               "acceptedAnswer":{
                  "@type":"Answer",
                  "text": "${answer3}"
               }
            }
         ]
      }
    `,
  };
}

export default getAgencyNewYorkStructureData;
