// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import BlockQuote from '../../blockQuote/blockQuote';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './offeringReasons.scss';

function OfferingReasons({ data }) {
  const { t } = useGlossary();
  const { reasonsToPartnerWithUs, quotes } = data;

  return (
    <>
      <Container className={style.reasons} mw="medium" padding="small">
        <BasicRevealItem as={Heading} size="h2" level="h2">
          {t('offering.title.partnering')}
        </BasicRevealItem>
        <RevealGroup className={style.items}>
          {reasonsToPartnerWithUs &&
            reasonsToPartnerWithUs.length > 0 &&
            reasonsToPartnerWithUs.map((item, i) => (
              <BasicRevealItem className={style.item} key={i.toString()}>
                <Image {...item.icon} className={style.icon} width={28} height={28} />
                <HTML className={style.title}>{item.title}</HTML>
                <HTML>{item.body}</HTML>
              </BasicRevealItem>
            ))}
        </RevealGroup>
      </Container>

      <Container className={style.quote} mw="medium" padding="small">
        <BasicRevealItem as={Heading} className={style.heading} size="h2" level="h2">
          {t('offering.title.quote')}
        </BasicRevealItem>
      </Container>

      {typeof quotes !== 'undefined' &&
        quotes !== null &&
        Array.isArray(quotes) &&
        quotes.length > 0 && (
          <BlockQuote
            className={style.blockquote}
            mw="medium"
            body={quotes[0].quote}
            name={quotes[0].quotee}
            position={quotes[0].position}
            image={quotes[0].image}
            small
          />
        )}
    </>
  );
}

export default OfferingReasons;
