import React, { memo } from 'react';
import BlockQuote from '../components/blockQuote/blockQuote';
import ContactBanner from '../components/contactBanner/contactBanner';
import OfferingBlock from '../components/offeringBlock/offeringBlock';
import Page from '../components/page';
import CompanyAwards from '../components/sections/companyAwards/companyAwards';
import CompanyIntro from '../components/sections/companyIntro/companyIntro';
import CompanyPartners from '../components/sections/companyPartners/companyPartners';
import Tabs from '../components/sections/Tabs/Tabs';
import CompanyTeam from '../components/sections/companyTeam/companyTeam';
import CompanyTrust from '../components/sections/companyTrust/companyTrust';
import HomeCapabilities from '../components/sections/homeCapabilities/homeCapabilities';
import { get } from '../utils/request';

const Company = memo(({ data }) => {
  const quote = {
    body:
      'Peugeot is on the lookout for more and more digital communication opportunities in order to reach clients in an original way. Together with DPDK we are working on strengthening our innvative image.',
    author: {
      name: 'Bart van Slobbe',
      function: 'Chief Digital Officer Peugeot',
    },
  };

  const pages = [
    {
      href: '/company',
      label: 'Story',
      type: 'story',
    },
    {
      href: '/company/culture',
      label: 'Culture',
      type: 'culture',
    },
  ];

  return (
    <Page newsletterHidden>
      <Tabs current="story" pages={pages} />
      <CompanyIntro />
      <CompanyTeam carousel={data.carousel} />
      {data.offerings && <OfferingBlock offerings={data.offerings} />}
      <CompanyTrust associations={data.associations} awards={data.awards} />
      {data?.awards?.list && data?.awards?.list?.length > 0 && (
        <CompanyAwards items={data.awards.list} />
      )}
      {data?.partners && (
        <CompanyPartners
          items={data.partners.slice(0, 10)}
          title={data.partnersTitle || null}
          desc={data.partnersDesc || null}
        />
      )}
      <BlockQuote body={quote.body} name={quote.author.name} position={quote.author.function} />
      {data?.capabilities?.items && (
      <HomeCapabilities minimal items={data.capabilities.items} />)}
      {data?.contactPerson && (
        <ContactBanner
          caption={data.contactCaption}
          title={data.contactPerson}
          person={data.contactPerson}
          hubspotFormId={data.hubspotFormId}
          footer
        />
      )}
    </Page>
  );
});

Company.getPageData = (match, env, location) => {
  return get(`/api/page/company/story`);
};

export default Company;
