// vendor
import React from 'react';

function Vimeo({ ...props }) {
  return (
    <svg viewBox="0 0 24 20" {...props}>
      <path d="M22.87,8.51c-2.44,5.22-8.33,12.32-12.06,12.32S6.61,13,4.6,7.79c-1-2.56-1.62-2-3.47-.68L0,5.65C2.7,3.28,5.4.53,7.06.37c1.86-.17,3,1.1,3.44,3.84.57,3.59,1.37,9.17,2.75,9.17,1.08,0,3.74-4.43,3.88-6,.24-2.32-1.7-2.39-3.39-1.66C16.41-3,27.53-1.43,22.87,8.51Z" />
    </svg>
  );
}

export default Vimeo;
