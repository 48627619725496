// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Button from '../../button/button';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import WorkGrid from '../../workGrid/workGrid';

// animation
import RevealGroup from '../../animation/revealGroup';

// style
import style from './homeWorkGrid.scss';

function HomeWorkGrid({ data, title, cta }) {
  
  const { t } = useGlossary();
  return (
    <RevealGroup>
      <Container className={style.container} classNameInner={style.content} padding="small" spaced>
      {title &&<Heading level="h2" size="h2">
              <p className={style.heading}>{title}</p>
      </Heading>}
        <WorkGrid items={data} shadow className={style.grid} />
        <Button href="/work" display="end">
          {cta ? cta : t('home.work.button.label')}
        </Button>
      </Container>
    </RevealGroup>
  );
}

export default HomeWorkGrid;
