// vendor
import React, { useContext } from 'react';

// static content
import domainContext from '../../context/domainContext';

function Form({ children, className, onSubmit, ...props }) {
  const data = useContext(domainContext);

  function onBeforeSubmit(e) {
    e.preventDefault();
    const data = {};
    const c = e.currentTarget.querySelectorAll('input, textarea, select');
    for (let i = 0; i < c.length; i += 1) {
      const { type, name, value } = c[i];
      if (type === 'submit') continue;
      if (type === 'checkbox') {
        data[name] = c[i].checked;
      } else {
        data[name] = value;
      }
    }
    data._csrf = __C;
    onSubmit(data);
  }
  return (
    <form className={className} onSubmit={onBeforeSubmit} {...props}>
      <input type="hidden" name="_csrf" value={data._csrfToken} />
      <input
        type="hidden"
        name="url"
        value={typeof window !== 'undefined' ? window.location.pathname : data.pathname}
      />
      {children}
    </form>
  );
}

export default Form;
