// vendor
import React from 'react';
import cc from 'classcat';
import { string, bool } from 'prop-types';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Heading from '../../heading/heading';
import ReadMore from '../../readMore/readMore';

// style
import style from './workInfo.scss';

WorkInfo.propTypes = {
  category: string,
  caption: string,
  landscape: bool,
  hovered: bool,
};

function WorkInfo({
  category,
  client,
  caption,
  landscape,
  hovered,
  hasHigherLevelHeadings = false,
}) {
  const { t } = useGlossary();

  return (
    <div className={cc([style.container, landscape && style.landscape, hovered && style.hover])}>
      <span className={style.client}>{client}</span>
      <Heading
        className={style.caption}
        level={`${hasHigherLevelHeadings ? 'h2' : 'h3'}`}
        size="h4"
        color="white"
      >
        {caption}
      </Heading>
      <hr className={style.separator} />
      <Heading
        className={style.category}
        level={`${hasHigherLevelHeadings ? 'h3' : 'h4'}`}
        size="h6"
        weight="semibold"
        color="green"
        caps
      >
        {category}
      </Heading>
      <ReadMore className={style.button} label={t('work.button.label')} hovered={hovered} />
    </div>
  );
}

export default WorkInfo;
