import React, { useContext } from 'react';

import ContactBanner from '../../components/contactBanner/contactBanner';
import Helmet from 'react-helmet';
import Page from '../../components/page';
import RevealGroup from '../../components/animation/revealGroup';
import StoriesEarlier from '../../components/sections/storiesEarlier/storiesEarlier';
import StoriesFeatured from '../../components/sections/storiesFeatured/storiesFeatured';
import StoryIntro from '../../components/sections/storyIntro/storyIntro';
import { get } from '../../utils/request';
import locationContext from '../../context/locationContext';
import style from './storyDetail.scss';

function StoryDetail({ data }) {
  const {
    authors,
    categories,
    featuredIn,
    images,
    intro,
    publishedDate,
    relatedStories,
    readTime,
    sections,
    summary,
    title,
    types,
    seo,
  } = data;

  const location = useContext(locationContext);

  const introData = {
    authors,
    featuredIn,
    publishedDate,
    readTime,
    sections,
    title,
    slug: undefined,
    types,
    images,
    summary,
    intro,
    categories,
  };

  if (authors[0] !== null) {
    var authorsName = authors[0].name;
    var photo = authors[0].photo.original;
  }

  return (
    <Page type="article" newsletterHidden>
      <RevealGroup>
        {introData && <StoryIntro data={introData} />}

        <div className={style.featured__container}>
          {relatedStories && (
            <StoriesFeatured>
              <StoriesEarlier items={relatedStories} title="Related stories" />
            </StoriesFeatured>
          )}
        </div>

        {data.contactPerson && (
          <div className={style.contactBanner}>
            <ContactBanner
              caption={data.contactCaption}
              title={data.title}
              person={data.contactPerson}
              hubspotFormId={data.hubspotFormId}
              whiteBackground
              detail
            />
          </div>
        )}
      </RevealGroup>

      <Helmet
        script={[
          {
            type: 'application/ld+json',
            innerHTML: `
            {
              "author": {
                  "name": "${authorsName}",
                  "image": {
                      "url": "${`https://${location.host}${photo}`}",
                      "@context": "http://schema.org",
                      "@type": "URL"
                  },
                  "@context": "http://schema.org",
                  "@type": "Person"
              },
              ${seo.decription ? `"description": "${seo.description}",` : ''}
              "datePublished": {
                  "datePublished": "${new Date(parseInt(publishedDate) * 1000).toISOString()}",
                  "@context": "http://schema.org",
                  "@type": "Date"
              },
              "headline": "${title}",
              "@context": "http://schema.org",
              "@type": "Article"
            }
          `,
          },
        ]}
      />
    </Page>
  );
}

StoryDetail.getPageData = (match, env, location) => {
  return get(`/api/page/stories/${match.params.slug}`);
};

export default StoryDetail;
