import React, { useRef, useEffect, useState } from 'react';
import Page from '../components/page';
import OfficeHeader from '../components/sections/officeHeader/officeHeader';
import ContactBanner from '../components/contactBanner/contactBanner';
import OfficeLocation from '../components/sections/officeLocation/officeLocation';
import OfficeStories from '../components/sections/officeStories/officeStories';
import OfficeCareers from '../components/sections/officeCareers/officeCareers';
import ContentSlider from '../components/sections/contentSlider/contentSlider';
import BlockQuote from '../components/blockQuote/blockQuote';
import { get } from '../utils/request';
import { useGlossary } from '../context/glossaryContext';
import OfficeDifferentLocation from '../components/sections/officeDIfferentLocations/officeDifferentLocation';

const Office = ({ data }) => {
  const officeSliderRef = useRef();

  const { t } = useGlossary();

  return (
    <Page>
      <OfficeHeader
        scrollToOfficeSlider={() =>
          officeSliderRef.current && officeSliderRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        headerProps={{
          title: t('office.heading.prefix') + data.title,
          titleClean: data.title,
          address: data.address,
          email: data.email,
          telephone: data.telephone,
          contactCTALabel: data.contactButtonLabel,
          timezoneOffset: data.timezoneOffset,
          header_image: data.header_image,
          card_image: data.card_image,
          where_we_started_title: data.where_we_started_title,
          where_we_started: data.where_we_started,
        }}
      />
      {data.agencyPageLinks && (
        <OfficeDifferentLocation
          agencyPageProps={{
            agency_page_links: data.agencyPageLinks,
            agency_page_title: data.agencyLinkTitle || 'Offices',
          }}
        />
      )}

      <ContactBanner
        title={data.contact_title}
        caption={data.contact_title && data.contact_title[0]}
        imageVerticalOffset={false}
        whiteBackground
        person={data.author}
      />
      <OfficeLocation
        locationProps={{
          directions_title: data.directions_title,
          directions_desc: data.directions_desc,
          directions_image: data.directions_image,
          directions_link: data.directions_link,
          directions_cta_link: data.directionsCtaLink,
        }}
      />
      <ContentSlider
        slidesPerViewBreakpoints={{
          0: {
            slidesPerView: 1,
          },
          700: {
            slidesPerView: 1.25,
          },
          1000: {
            slidesPerView: 1.75,
          },
          1500: {
            slidesPerView: 2.25,
          },
        }}
        sliderContentArray={data.cases}
        sliderTitle={data.cases_title}
      />
      <OfficeStories storiesContentArray={data.stories} title={data.stories_title} />
      <OfficeCareers
        careerProps={{
          city: data.title,
          vacancies_title: data.vacancies_title,
          vacancies_results: data.vacancies_results,
          vacancies_desc: data.vacancies_desc,
          vacancies_btn_text: data.vacancies_btn_text,
        }}
      />
      <ContentSlider
        ref={officeSliderRef}
        slidesPerViewBreakpoints={{
          0: {
            slidesPerView: 1.25,
          },
          1000: {
            slidesPerView: 2.25,
          },
          1500: {
            slidesPerView: 3.25,
          },
        }}
        sliderContentArray={data.sneak_peek_slides}
        sliderTitle={data.sneak_peek_title}
        sliderSubtitle={data.sneak_peek_description}
      />
      <BlockQuote
        className="quote--grey"
        body={data.quote_text}
        name={data.quote_quotee}
        position={data.quote_position}
      />
      <ContentSlider
        slidesPerViewBreakpoints={{
          0: {
            slidesPerView: 1.25,
          },
          1000: {
            slidesPerView: 2.25,
          },
          1500: {
            slidesPerView: 3.25,
          },
        }}
        sliderContentArray={data.hotspot_slides}
        sliderTitle={data.hotspot_title}
        sliderSubtitle={data.hotspot_description}
        whiteBackground={true}
      />
      <ContactBanner
        caption={data.contact_title && data.contact_title.length === 2 ? data.contact_title[1] : ''}
        index={1}
        title={data.title}
        person={data.author}
        noMarginBottom={true}
      />
    </Page>
  );
};

Office.getPageData = (match, env, location) => {
  return get(`/api/page/office/${match.params.city}`);
};

export default Office;
