import React from 'react';
import Link from '../../link';
import style from './whiteaperCard.scss';
import Container from '../../../components/container/container';
import { Image } from 'dpdk-react-library';
import BasicRevealItem from '../../animation/basicRevealItem';

function WhitepaperCard({
  title = '',
  summary = '',
  slug = '',
  hideLabel = false,
  cta_label = '',
  image,
}) {
  return (
    <BasicRevealItem>
      <Container className={style.wp_container} padding="small" spaced>
        <BasicRevealItem className={style.wp_card}>
          <BasicRevealItem className={style.wp_artwork}>
            {image && (
              <Image
                className={style.image}
                {...image}
                width={400}
                height={undefined}
                removePlaceholderOnLoad
              />
            )}
          </BasicRevealItem>
          <BasicRevealItem className={style.wp_content}>
            <h1 className={style.wp_header}>{title}</h1>
            <p className={style.wp_summary}>{summary}</p>
            {!hideLabel && (
              <Link className={style.anchor} href={`${slug ? slug.replace('/story', '') : ''}`}>
                <b className={style.wp_link}>{cta_label || 'Download the whitepaper'}</b>
              </Link>
            )}
          </BasicRevealItem>
        </BasicRevealItem>
      </Container>
    </BasicRevealItem>
  );
}

export default WhitepaperCard;
