import React from 'react';
import RevealGroup from '../../animation/revealGroup';
import ShortStorie from './shortStorie/shortStorie';
import StoriesHeaderItem from '../storiesHeader/storiesHeaderItem/storiesHeaderItem';
import cc from 'classcat';
import style from './storiesEarlier.scss';

function StoriesEarlier({ items, title = 'Earlier stories' }) {
  return (
    items &&
    items.length > 0 && (
      <RevealGroup className={cc([style.stories])}>
        <div className={style.es_container}>
          <div className={style.title}>
            <StoriesHeaderItem title={title} />
          </div>

          <div className={style.es_card}>
            <div className={style.es_storiesSection}>
              {items.map(item => (
                <ShortStorie item={item} key={item.title} defaultStyle={true} hasMinHeight />
              ))}
            </div>
          </div>
        </div>
      </RevealGroup>
    )
  );
}

export default StoriesEarlier;
