// vendor
import React from 'react';
import { oneOf } from 'prop-types';

// components
import Basic from './basic';
import Boxed from './boxed';

const types = {
  basic: Basic,
  boxed: Boxed,
};

Logo.propTypes = {
  type: oneOf(Object.keys(types)).isRequired,
};

function Logo({ type, ...rest }) {
  const LogoComp = types[type];
  return <LogoComp {...rest} />;
}

export default Logo;
