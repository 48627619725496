import React from 'react';
import cc from 'classcat';
import BasicRevealItem from '../../animation/basicRevealItem';
import HTML from '../../html/html';
import BasicQuote from '../../basicQuote/basicQuote';
import Button from '../../button/button';
import Heading from '../../heading/heading';
import SocialLink from '../../socialLink/socialLink';
import scroll from '../../../utils/scroll';
import { Image } from 'dpdk-react-library';
import style from './storiesAuthor.scss';

function StoriesAuthor({
  bio,
  photo,
  fullName,
  firstName,
  authorFunction,
  quote,
  socialMedia = {},
}) {
  const btnLabel = `Contact ${firstName}`;
  const twitterUrl =
    Object.keys(socialMedia).length !== 0 && socialMedia.twitter && socialMedia.twitter.href;
  const facebookUrl =
    Object.keys(socialMedia).length !== 0 && socialMedia.facebook && socialMedia.facebook.href;
  const linkedinUrl =
    Object.keys(socialMedia).length !== 0 && socialMedia.linkedin && socialMedia.linkedin.href;

  const items = [
    {
      label: 'Twitter',
      href: twitterUrl || '',
      icon: 'twitter',
    },
    {
      label: 'Facebook',
      href: facebookUrl || '',
      icon: 'facebook',
    },
    {
      label: 'Linkedin',
      href: linkedinUrl || '',
      icon: 'linkedin',
    },
  ];

  return (
    <BasicRevealItem>
      <div className={style.container}>
        <div className={style.imageContainer}>
          <Image
            {...photo}
            noWidth
            width={undefined}
            height={undefined}
            className={style.imageWrapper}
          />

          <div className={style.button}>
            <Button onClick={() => scroll('.js-section-contact')} icon="arrowBottom" boxed>
              {btnLabel}
            </Button>
          </div>
        </div>

        <div className={style.info}>
          <div>
            <Heading className={style.heading} level="h2">
              {fullName}
            </Heading>

            <HTML className={style.function}>{authorFunction}</HTML>

            <div className={cc([style.socials, style.mobileView])}>
              {items.map(item => (
                <SocialLink item={item} key={item.label} />
              ))}
            </div>

            {quote && (
              <div className={cc([style.quote, style.mobileView])}>
                <BasicQuote quote={quote} author={fullName} authorFunction={authorFunction} />
              </div>
            )}

            <HTML size="large" className={style.biography}>
              {bio}
            </HTML>

            {quote && (
              <div className={cc([style.quote, style.desktopView])}>
                <BasicQuote quote={quote} author={fullName} authorFunction={authorFunction} />
              </div>
            )}
          </div>

          <div className={cc([style.socials, style.desktopView])}>
            {items.map(item => (
              <SocialLink item={item} key={item.label} />
            ))}
          </div>
        </div>
      </div>
    </BasicRevealItem>
  );
}

export default StoriesAuthor;
