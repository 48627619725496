// vendor
import React, { memo, useLayoutEffect, useRef } from 'react';

// animations
import BasicRevealItem from '../components/animation/basicRevealItem';

// components
import BackButton from '../components/backButton/backButton';
import Page from '../components/page';
import WorkContent from '../components/sections/workContent/workContent';
import WorkFooter from '../components/sections/workFooter/workFooter';
import WorkHero from '../components/sections/workHero/workHero';

// static content
import { useGlossary } from '../context/glossaryContext';

// hooks
import useUpdateEffect from '../hooks/useUpdateEffect';

// utils
import { get } from './../utils/request';

// style
import style from './workDetail.scss';

function Template({ data }) {
  const { t } = useGlossary();

  return (
    <Page title={data.title}>
      <BasicRevealItem variation="fade" delay={1000}>
        <BackButton href="/work" className={style.backButton}>
          {t('work.backWork')}
        </BackButton>
      </BasicRevealItem>
      <WorkHero data={data} />
      <WorkContent data={data.template} />
      <WorkFooter data={data} />
    </Page>
  );
}

const WorkDetail = memo(({ data }) => {
  if (typeof data.template !== 'undefined' && data.template !== null) {
    return <Template data={data} />;
  }
  const { t } = useGlossary();
  const video = useRef(null);

  useUpdateEffect(() => {
    const iframe = document.querySelector('iframe');
    iframe.addEventListener('load', () => {
      iframe.style.height = `${iframe.contentWindow.document.body.offsetHeight}px`;
      requestAnimationFrame(() => {
        iframe.style.height = `${iframe.contentWindow.document.body.offsetHeight}px`;
      });
    });
  });
  if (typeof window !== 'undefined') {
    useLayoutEffect(() => {
      if (video.current === null || typeof window.currentVideo === 'undefined') return;
      video.current.appendChild(window.currentVideo);
    });
  }

  return (
    <Page title={data.title}>
      <BasicRevealItem variation="fade" delay={1000}>
        <BackButton href="/work" className={style.backButton}>
          {t('work.backWork')}
        </BackButton>
      </BasicRevealItem>
      {typeof data.video !== 'undefined' && (
        <>
          {typeof window !== 'undefined' && typeof window.currentVideo !== 'undefined' ? (
            <div
              style={{
                opacity: '1',
                position: 'absolute',
                height: '700px',
                width: '100%',
                overflow: 'hidden',
              }}
              ref={video}
            />
          ) : (
            typeof data.video !== 'undefined' &&
            data.video.src.length > 0 && (
              <div
                style={{
                  opacity: '1',
                  position: 'absolute',
                  height: '700px',
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <video
                  className={style.video}
                  src={`/video/work/${data.video.src[0].value}.mp4`}
                  autoPlay
                  loop
                  playsInline
                  muted
                  style={{ transform: 'translateX(-50%) translateY(-50%) scale(1.3)' }}
                />
              </div>
            )
          )}
        </>
      )}

      <BasicRevealItem variation="fade" delay={1000}>
        {typeof data.video !== 'undefined' && data.video.src.length > 0 ? (
          <iframe
            title={data.title}
            src={`/workStatic/${data.video.src[0].value}.html`}
            style={{ width: '100%', height: '2000px', overflow: 'hidden', position: 'relative' }}
          />
        ) : (
          <iframe
            title={data.title}
            src={`/workStatic/${data.url}.html`}
            style={{ width: '100%', height: '2000px', overflow: 'hidden', position: 'relative' }}
          />
        )}
      </BasicRevealItem>
    </Page>
  );
});

WorkDetail.getPageData = (match, env) => {
  return get(`/api/page/work/${match.params.slug}`);
};

export default WorkDetail;
