// vendor
import { useContext, useRef } from 'react';
import { func } from 'prop-types';

// animation
import revealContext from '../../context/revealContext';

BlockingRevealItem.propTypes = {
  animate: func.isRequired, // Should return a Promise
};

function BlockingRevealItem({ children, animate }) {
  const context = useContext(revealContext);
  const hasRegistered = useRef(false);
  if (!hasRegistered.current) {
    context.addBlockingReveal(animate);
    hasRegistered.current = true;
  }
  return children;
}

export default BlockingRevealItem;
