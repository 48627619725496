// vendor
import React from 'react';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './offeringContent.scss';

function OfferingContent({ data }) {
  const { benefitsTitle, benefitsItems, howWeDoIt } = data;

  return (
    <>
      <Container className={style.benefits} mw="medium" padding="small">
        {benefitsTitle && (
          <BasicRevealItem as={Heading} size="h2" level="h2">
            {benefitsTitle}
          </BasicRevealItem>
        )}
        <RevealGroup className={style.items}>
          {benefitsItems &&
            benefitsItems.length > 0 &&
            benefitsItems.map((item, i) => (
              <BasicRevealItem className={style.item} key={i.toString()}>
                <HTML className={style.title}>{item.body}</HTML>
                <HTML>{item.title}</HTML>
              </BasicRevealItem>
            ))}
        </RevealGroup>
      </Container>

      {howWeDoIt && (
        <Container
          className={style.content}
          classNameInner={style.inner}
          mw="large"
          padding="small"
        >
          <div className={style.media}>
            {howWeDoIt.title && (
              <BasicRevealItem as={Heading} className={style.heading} size="h2" level="h2">
                {howWeDoIt.title}
              </BasicRevealItem>
            )}
            {howWeDoIt.image && (
              <BasicRevealItem>
                <Image {...howWeDoIt.image} className={style.image} />
              </BasicRevealItem>
            )}
          </div>
          {howWeDoIt.body && (
            <BasicRevealItem className={style.text}>
              <HTML className={style.body}>{howWeDoIt.body}</HTML>
            </BasicRevealItem>
          )}
        </Container>
      )}
    </>
  );
}

export default OfferingContent;
