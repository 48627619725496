// vendor
import React from 'react';

function Play({ ...props }) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <polygon points="40,66.6 40,33.6 67,50" />
      <circle cx="50" cy="50" r="45.8" />
    </svg>
  );
}

export default Play;
