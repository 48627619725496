function getAgencyRotterdamStructureData() {
  let question1 = "Where to find us in Rotterdam?";
  let question2 = "What can our Rotterdam digital agency do for you?";
  let question3 = "Why choose DPDK?";
  let answer1 =
    "Our Rotterdam office is located in one of the most beautiful areas of Rotterdam, near the iconic Erasmus bridge, and just a few steps away from the waterfront. The DPDK team here creates exceptional digital work for the most innovative brands across the globe. ";
  let answer2 =
    "Our Rotterdam digital agency grows businesses by combining technology, design, and creativity. We build and accelerate your digital presence through tailored strategies, a unique brand identity, innovative products, and content that converts. We deliver digital awesomeness to the most innovative brands in the Netherlands and beyond.";
  let answer3 =
    "We’ve been in business for more than 20 years, and know how to create exceptional digital work. Our no-nonsense attitude and fierce drive to deliver top-notch digital experiences make DPDK an experienced partner for bold brands that are ready to push their marketing ambitions to a new level.";
  let address = JSON.stringify({
    '@type': 'PostalAddress',
    addressLocality: 'Rotterdam',
    addressRegion: 'South Holland',
    postalCode: '3016 CA',
    streetAddress: 'Calandstraat 5a',
  });

  return {
    type: 'application/ld+json',
    innerHTML: `
        {
         "@context":"https://schema.org",
         "@type":"FAQPage",
        "name": "DPDK",
        "description": "Digital Marketing Agency",
        "image": "https://dpdk.com/assets/domain/share_image/dpdk-facebook_0.jpg",
        "priceRange": "$2000 to $10000",
        "address": ${address},
        "telephone": "(718) 782-4050",
         "mainEntity":[
            {
               "@type":"Question",
               "name": "${question1}",
               "acceptedAnswer":{
                  "@type":"Answer",
                  "text": "${answer1}"
               }
            },
            {
               "@type":"Question",
               "name": "${question2}",
               "acceptedAnswer":{
                  "@type":"Answer",
                  "text": "${answer2}"
               }
            },
            {
               "@type":"Question",
               "name": "${question3}",
               "acceptedAnswer":{
                  "@type":"Answer",
                  "text": "${answer3}"
               }
            }
         ]
      }
    `,
  };
}

export default getAgencyRotterdamStructureData;
