// vendor
import React from 'react';

function Boxed({ ...props }) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M55.604,49.81h-1.423v-1.408c0-0.47-0.403-0.854-0.903-0.854c-0.499,0-0.904,0.384-0.904,0.854v1.408H50.95c-0.474,0-0.862,0.4-0.862,0.894c0,0.496,0.387,0.895,0.862,0.895h1.424v1.408c0,0.47,0.406,0.854,0.904,0.854s0.903-0.384,0.903-0.854v-1.408h1.423c0.478,0,0.865-0.399,0.864-0.895C56.469,50.21,56.082,49.81,55.604,49.81z" />
      <path d="M42.282,45.294c-3.013,0.053-5.414,2.51-5.36,5.49c0.052,2.98,2.537,5.357,5.55,5.305c3.011-0.05,5.41-2.511,5.36-5.489C47.776,47.618,45.292,45.241,42.282,45.294z" />
      <path d="M25.45,45.294c-3.012,0.053-5.411,2.51-5.357,5.49c0.052,2.98,2.535,5.357,5.549,5.305c3.01-0.05,5.409-2.511,5.358-5.489C30.947,47.618,28.465,45.241,25.45,45.294z" />
      <path d="M64.08,45.294c-3.013,0.052-5.412,2.51-5.359,5.49c0.053,2.98,2.534,5.357,5.549,5.305c3.013-0.05,5.413-2.511,5.36-5.489C69.58,47.618,67.089,45.241,64.08,45.294z" />
      <path d="M0,0v100h100V0H0z M80.705,60.311l-5.922-5.86c-1.537,4.236-5.594,7.298-10.412,7.381c-4.937,0.085-9.188-2.988-10.785-7.334c-0.096,0.034-0.198,0.053-0.306,0.053c-0.111,0-0.216-0.021-0.315-0.056c-1.545,4.213-5.592,7.255-10.391,7.337c-1.083,0.02-2.132-0.115-3.125-0.379v7.995c0,3.83-5.807,3.83-5.807,0V58.44c-2.008,2.047-4.793,3.34-7.899,3.392c-6.219,0.108-11.347-4.794-11.455-10.949c-0.11-6.15,4.843-11.225,11.062-11.334c1.003-0.019,1.979,0.093,2.909,0.322v-9.321c0-3.829,5.813-3.829,5.813,0v12.613c2.011-2.173,4.89-3.558,8.107-3.614c4.95-0.088,9.205,2.996,10.794,7.36c0.095-0.034,0.198-0.055,0.306-0.055c0.106,0,0.208,0.021,0.301,0.054c1.538-4.223,5.591-7.274,10.399-7.36c2.05-0.034,3.987,0.473,5.656,1.394V30.551c0-3.829,5.808-3.829,5.808,0v15.971l5.262-5.206c2.741-2.709,6.842,1.356,4.109,4.061l-5.491,5.438l5.492,5.434C87.546,58.955,83.446,63.023,80.705,60.311z" />
    </svg>
  );
}

export default Boxed;
