import cc from 'classcat';
import { func } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Scroller } from 'scroller';
import style from './carousel.scss';

Carousel.propTypes = {
  children: func,
};

function Carousel({ children, resizeInputs, className }) {
  const [holding, setHolding] = useState(false);
  const [x, setX] = useState(0);
  const [height, setHeight] = useState(0);
  const scroller = useRef();
  const wrapper = useRef();
  const inner = useRef();

  if (!scroller.current) {
    scroller.current = new Scroller(x => setX(x), {
      scrollingY: false,
    });
    scroller.current.setPosition(0, 0);
  }

  function handleResize() {
    const wrapperRect = wrapper.current.getBoundingClientRect();
    const innerRect = inner.current.getBoundingClientRect();
    scroller.current.setDimensions(
      wrapperRect.width,
      wrapperRect.height,
      innerRect.width,
      innerRect.height
    );
    setHeight(innerRect.height);
  }

  useEffect(handleResize, resizeInputs);

  useEffect(() => {
    function handleTouchStart(e) {
      setHolding(true);
      scroller.current.doTouchStart(e.touches, e.timeStamp);
    }

    function handleTouchMove(e) {
      scroller.current.doTouchMove(e.touches, e.timeStamp, 1);
    }

    function handleMouseDown(e) {
      setHolding(true);
      scroller.current.doTouchStart([e], e.timeStamp);
    }

    function handleMouseMove(e) {
      scroller.current.doTouchMove([e], e.timeStamp, 1);
    }

    function handleEnd(e) {
      setHolding(false);
      scroller.current.doTouchEnd(e.timeStamp);
    }

    window.addEventListener('resize', handleResize);
    wrapper.current.addEventListener('touchstart', handleTouchStart, { passive: true });
    wrapper.current.addEventListener('touchmove', handleTouchMove, { passive: true });
    window.addEventListener('touchend', handleEnd);
    wrapper.current.addEventListener('mousedown', handleMouseDown);
    wrapper.current.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleEnd);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      wrapper.current.removeEventListener('touchstart', handleTouchStart);
      wrapper.current.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleEnd);
      wrapper.current.removeEventListener('mousedown', handleMouseDown);
      wrapper.current.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleEnd);
    };
  }, []);

  function getItemProps(props) {
    return props;
  }

  return (
    <div
      ref={wrapper}
      className={cc([style.wrapper, holding && style.holding, className])}
      style={{ height: `${height}px` }}
    >
      <div ref={inner} className={style.inner} style={{ transform: `translateX(${-1 * x}px)` }}>
        {children({ getItemProps })}
      </div>
    </div>
  );
}

export default Carousel;
