// vendor
import React from 'react';
import cc from 'classcat';

// components
import WorkGridItem from './workGridItem/workGridItem';

// style
import style from './workGrid.scss';

function WorkGrid({
  items,
  shadow,
  bigShadow,
  className,
  removeHighlightedItems = false,
  hasHigherLevelHeadings = false,
}) {
  return (
      <div
        className={cc([
          style.work,
          (shadow || bigShadow) && style.shadow,
          bigShadow && style.extended,
          className,
        ])}
      >
        {items &&
        items.length > 0 && items.map(
          (item, i) =>
            item !== null && (
              <WorkGridItem
                removeHighlightedItems={removeHighlightedItems}
                item={item}
                key={i.toString()}
                hasHigherLevelHeadings={hasHigherLevelHeadings}
              />
            )
        )}
      </div>
  );
}

export default WorkGrid;
