// vendor
import React from 'react';
import cc from 'classcat';
import { bool, func, oneOf, oneOfType, string } from 'prop-types';

// style
import style from './html.scss';

HTML.propTypes = {
  children: string.isRequired,
  as: oneOfType([string, func]),
  size: oneOf(['small', 'regular', 'large']),
  color: oneOf(['black', 'white', 'green']),
  italic: bool,
  className: string,
};

function HTML({
  children,
  as: Comp = 'div',
  size = 'regular',
  color = 'black',
  italic = false,
  className = '',
  innerRef,
  ...rest
}) {
  return (
    <Comp
      className={cc([style.html, style[size], style[color], italic && style[italic], className])}
      dangerouslySetInnerHTML={{ __html: children }}
      ref={innerRef}
      {...rest}
    />
  );
}

export default React.forwardRef((props, ref) => <HTML {...props} innerRef={ref} />);
