// vendor
import React, { useState } from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Button from '../../button/button';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import Icon from '../../icon/icon';
import ImageSwitcher from '../../imageSwitcher/imageSwitcher';
import OfferingBlock from '../../offeringBlock/offeringBlock';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './approachPresentation.scss';

function ApproachPresentation({ items, offerings }) {
  const { t } = useGlossary();
  const [active, setActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  function handlePrev() {
    setActiveIndex(prev => (prev + items.length - 1) % items.length);
  }

  function handleNext() {
    setActiveIndex(prev => (prev + 1) % items.length);
  }

  function closeAndReset() {
    setActiveIndex(0);
    setActive(false);
  }

  return (
    <RevealGroup>
      <OfferingBlock className={style.offerings} offerings={offerings} />

      <div className={cc([style.container, active && style.active])}>
        <div className={style.background} />
        <Container className={style.contentWrapper} classNameInner={style.content} padding="small">
          <ImageSwitcher
            {...items[activeIndex].image}
            width={undefined}
            height="100%"
            className={style.image}
          />
          <div className={style.gridArea}>
            <div className={style.intro}>
              <BasicRevealItem>
                <Heading size="h2">{t('approach.presentation.heading')}</Heading>
              </BasicRevealItem>
              <BasicRevealItem>
                <HTML className={style.body}>{t('approach.presentation.body')}</HTML>
              </BasicRevealItem>
              <BasicRevealItem>
                <Button
                  className={style.button}
                  boxed
                  onClick={() => setActive(true)}
                  tabIndex={active ? -1 : 0}
                  icon="arrowRight"
                >
                  {t('approach.presentation.button.label')}
                </Button>
              </BasicRevealItem>
            </div>
            <span className={style.counter}>
              {activeIndex + 1}/{items.length}
            </span>
            {items.map((item, i) => {
              const visible = active && activeIndex === i;
              return (
                <div className={cc([style.slide, visible && style.active])} key={i.toString()}>
                  <Heading size="h2">{item.heading}</Heading>
                  <HTML className={style.body}>{item.body}</HTML>
                  <div className={cc([style.slideControls])}>
                    <button
                      className={style.prev}
                      onClick={handlePrev}
                      aria-label="previous"
                      tabIndex={visible ? 0 : -1}
                    >
                      <Icon type="arrowRight" />
                    </button>
                    <button
                      className={style.next}
                      onClick={handleNext}
                      aria-label="next"
                      tabIndex={visible ? 0 : -1}
                    >
                      <Icon type="arrowRight" />
                    </button>
                    <button
                      className={style.close}
                      onClick={() => closeAndReset()}
                      tabIndex={visible ? 0 : -1}
                    >
                      Close Slideshow
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </div>
    </RevealGroup>
  );
}

export default ApproachPresentation;
