// vendor
import React, { useContext, useMemo, useRef, useState } from 'react';
import { number } from 'prop-types';

// animation
import revealContext from '../../context/revealContext';

// components
import IntersectionChecker from '../intersectionChecker';

RevealGroup.propTypes = {
  stagger: number, // milliseconds of stagger between reveal items
};

function RevealGroup({ as: Comp = 'div', children, stagger = 300, ...rest }) {
  const [hasAnimatedState, setHasAnimated] = useState(false);
  const context = useContext(revealContext);
  const hasAnimated = context.isInGroup ? context.hasAnimated : hasAnimatedState;
  const items = useRef([]);

  function animate() {
    context.waitForBlocking().then(() => {
      items.current.forEach((fn, i) => {
        setTimeout(fn, i * stagger); // lel, maybe refactor someday, éh?
      });
    });
    setTimeout(() => setHasAnimated(true), items.current.length * stagger);
  }

  const value = useMemo(
    () => ({
      ...context,
      isInGroup: true,
      hasAnimated,
      addReveal(fn) {
        if (context.isInGroup) {
          context.addReveal(fn);
        } else {
          items.current.push(fn);
        }
      },
    }),
    [hasAnimated]
  );

  const el = <revealContext.Provider value={value}>{children}</revealContext.Provider>;

  if (!context.isInGroup) {
    return (
      <IntersectionChecker onEnterOnce={animate} {...rest} as={Comp}>
        {el}
      </IntersectionChecker>
    );
  }

  return <Comp {...rest}>{el}</Comp>;
}

export default RevealGroup;
