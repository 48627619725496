// vendor
import React from 'react';

// components
import Container from '../../../container/container';
import AutoPlayingVideo from '../../../autoPlayingVideo/AutoPlayingVideo';
import HTML from '../../../html/html';
import { Image } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../../../animation/basicRevealItem';

// style
import style from './workDouble.scss';

function WorkDouble({ data }) {
  return (
    <Container className={style.container} classNameInner={style.inner} padding="small">
      {data.media.map((unit, i) => (
        <BasicRevealItem className={style.media} key={i.toString()}>
          {unit.type === 'image' ? (
            <Image {...unit.image} />
          ) : (
            <AutoPlayingVideo
              muted
              source={
                unit.upload
                  ? unit.upload.replace('/var/source/dpdk_website_2019/files', '/assets')
                  : unit.url
              }
            />
          )}
          {unit.caption && <HTML className={style.caption}>{unit.caption}</HTML>}
        </BasicRevealItem>
      ))}
    </Container>
  );
}

export default WorkDouble;
