import React, { useEffect, useState } from 'react';
import dlv from 'dlv';

function Hubspot({ appendId, portalId, formId, ...props }) {
  const id = `hubspot-form-dpdk-${appendId}`;
  const target = `#${id}`;

  const [loaded, setLoaded] = useState(typeof hbspt !== 'undefined');

  useEffect(() => {
    if (loaded) {
      if (typeof window === 'undefined') return null;
      const container = document.querySelector(target);
      if (container !== null && container.innerHTML === '' && typeof hbspt !== 'undefined') {
        hbspt.forms.create({ portalId, formId, target });
      }
    } else {
      if (typeof document !== 'undefined' && 'IntersectionObserver' in window) {
        let form = document.querySelector(`#${id}`);
        let observer = new IntersectionObserver(entries => {
          if (dlv(entries, '0.isIntersecting', false)) {
            loadScript();
            observer.unobserve(form);
          }
        });
        if (form) observer.observe(form);
      } else {
        loadScript();
      }
    }
  });

  function loadScript() {
    let script = document.createElement(`script`);
    script.defer = true;
    script.onload = () => {
      setLoaded(true);
    };
    script.src = `//js.hsforms.net/forms/v2.js`;
    document.head.appendChild(script);
  }

  function onClick(e) {
    if (
      e.target.name === 'i_agree_with_the_dpdk_privacy_policy' ||
      e.target.name === 'i_d_like_to_receive_updates_from_dpdk'
    ) {
      e.target.parentElement.classList.toggle('checkbox-checked');
    }
  }

  return <div onClick={onClick} id={id} {...props} />;
}

export default Hubspot;
