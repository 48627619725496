// vendor
import cc from 'classcat';
import React from 'react';


// animation
import BasicRevealItem from '../../animation/basicRevealItem';

// components
import { Image } from 'dpdk-react-library';
import Link from '../../link';

// style
import style from './testimonialImage.scss';

function TestimonialImage({
  item,
  className = '',
}) {
  const { image, slug } = item;

  return (
    <BasicRevealItem className={cc([style.quote, className])}>
      { slug ? ( 
        <Link
          href={slug}
        >
          {image && (
            <Image
              {...image}
              className={cc([style.quoteImage, slug])}
              width={undefined}
              height={undefined}
            />
          )}
        </Link>
        ) : (
          <>
            {image && (
              <Image
                {...image}
                className={cc([style.quoteImage, slug])}
                width={undefined}
                height={undefined}
              />
            )}
          </>
        )
      }
    </BasicRevealItem>
  );
}

export default TestimonialImage;
