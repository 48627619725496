// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';
import image from '../../../../public/media/images/company-story-intro.jpg';

// components
import Button from '../../button/button';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';

// utils
import scroll from '../../../utils/scroll';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './companyIntro.scss';

function CompanyIntro() {
  const { t } = useGlossary();

  return (
    <RevealGroup>
      <Container
        className={style.container}
        classNameInner={style.content}
        mw="large"
        padding="small"
      >
        <div className={style.cols}>
          <BasicRevealItem className={style.media}>
            {image && (
              <Image
                className={style.image}
                aspectRatio={image.width / image.height}
                placeholder={image.placeholder}
                srcSet={image.srcSet}
                src={image.src}
                contain
              />
            )}
          </BasicRevealItem>
          <BasicRevealItem className={style.text}>
            <Heading size="h1" level="h1" className={style.heading}>
              {t('company.story.heading')}
            </Heading>
            <Heading size="h4" level="h4" className={style.subheading}>
              {t('company.story.subheading')}
            </Heading>
            <HTML className={style.body}>{t('company.story.body')}</HTML>
            <Button
              className={style.button}
              onClick={() => scroll('.js-section-contact')}
              icon="arrowBottom"
              boxed
            >
              {t('company.story.button.label')}
            </Button>
          </BasicRevealItem>
        </div>
      </Container>
    </RevealGroup>
  );
}

export default CompanyIntro;
