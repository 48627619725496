// vendor
import React from 'react';

// components
import InternalLink from './internalLink';

function Link({
  href = '',
  external,
  innerRef,
  children,
  style,
  className,
  activeClassName,
  activeStyle,
  activeProps,
  ...rest
}) {
  if (external || href.match(/^(https?:)?\/\//) || href.match(/^mailto:/)) {
    const target = href.match(/^mailto:/) ? {} : { target: '_blank' };
    return (
      <a
        href={href}
        rel="noopener noreferrer"
        ref={innerRef}
        {...target}
        className={className}
        style={style}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <InternalLink
      href={href}
      innerRef={innerRef}
      className={className}
      style={style}
      activeClassName={activeClassName}
      activeStyle={activeStyle}
      activeProps={activeProps}
      {...rest}
    >
      {children}
    </InternalLink>
  );
}

export default React.forwardRef((props, ref) => <Link {...props} innerRef={ref || undefined} />);
