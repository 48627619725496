function addFocusClasses(el) {
  function removeClasses() {
    el.classList.remove('keyboard-focus', 'mouse-focus', 'touch-focus');
  }
  function keyboardFocus() {
    removeClasses();
    el.classList.add('keyboard-focus');
  }

  function mouseFocus() {
    removeClasses();
    el.classList.add('mouse-focus');
  }

  function touchFocus() {
    removeClasses();
    el.classList.add('touch-focus');
  }

  document.addEventListener('keyup', keyboardFocus);
  document.addEventListener('mousedown', mouseFocus);
  document.addEventListener('touchstart', touchFocus);
}

export default addFocusClasses;
