// vendor
import React, { memo } from 'react';
import Page from '../components/page';
// components
import BasicPageContent from '../components/sections/basicPageContent/basicPageContent';
// utils
import { get } from '../utils/request';

const Disclaimer = memo(({ data }) => {
  return (
    <Page title={data.title}>
      <BasicPageContent data={data} />
    </Page>
  );
});

Disclaimer.getPageData = (match, env, location) => {
  return get(`/api/page/terms-conditions`);
};

export default Disclaimer;
