// vendor
import React from 'react';
import cc from 'classcat';

// components
import Icon from '../icon/icon';
import Link from '../link';

// style
import style from './socials.scss';

function Socials({ className }) {
  const items = [
    {
      label: 'Instagram',
      href: 'https://www.instagram.com/dpdk_digital/',
      icon: 'instagram',
    },
    {
      label: 'Twitter',
      href: 'https://twitter.com/dpdk',
      icon: 'twitter',
    },
    {
      label: 'Facebook',
      href: 'https://www.facebook.com/dpdknl',
      icon: 'facebook',
    },
    {
      label: 'Linkedin',
      href: 'https://linkedin.com/company/dpdk',
      icon: 'linkedin',
    },
    {
      label: 'Vimeo',
      href: 'https://vimeo.com/dpdk',
      icon: 'vimeo',
    },
  ];

  return (
    <div className={cc([style.container, className])}>
      {items.map((item, i) => (
        <Link className={style.anchor} href={item.href} external key={i.toString()}>
          <span className={style.label}>{item.label}</span>
          <Icon className={style.icon} type={item.icon} />
        </Link>
      ))}
    </div>
  );
}

export default Socials;
