import { useContext } from 'react';
import routeContext from '../context/routeContext';
import useData from './useData';

function usePageData() {
  const { pathname, search } = useContext(routeContext).location;
  const uri = `${pathname}${search}`;
  return useData(uri);
}

export default usePageData;
