// vendor
import React from 'react';

// components
import { Image } from 'dpdk-react-library';
import Button from '../button/button';
import Link from '../link';
import Heading from '../heading/heading';

// style
import style from './officeCard.scss';

function Office({ title, address = [], telephone, image, slug }) {
  const fullAddress = address.join(', ');

  return (
    <Link href={slug}>
      <div className={style.card}>
        {image && (
          <div className={style.imageWrapper}>
            <Image {...image} width="100%" height="100%" />
          </div>
        )}

        <div className={style.content}>
          {title && (
            <Heading level="h2" size="h4" className={style.title} weight="light" color="green">
              {title}
            </Heading>
          )}

          {telephone && (
            <div className={style.telephone}>
              <span>Phone number</span>
              <p>{telephone}</p>
            </div>
          )}

          {fullAddress && (
            <div className={style.address}>
              <span>Address</span>
              <p>{fullAddress}</p>
            </div>
          )}

          <div className={style.button}>
            <Button icon="arrowRight" boxed>
              Get in touch
            </Button>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Office;
