// components
import Container from '../../container/container';
// vendor
import React from 'react';
import cc from 'classcat';
// style
import style from './storiesFeatured.scss';

function StoriesFeatured({ children, addSpacing = false, noFlex = false, storiesHomePage = true }) {
  return (
    <Container
      className={cc([
        style.container, addSpacing && style.addSpacing,
        !noFlex && style.content,
        noFlex && style.noFlexContent,
        !storiesHomePage && style.storiesCategoriesPage,
      ])}
      padding="small"
    >
      {children}
    </Container>
  );
}

export default StoriesFeatured;
