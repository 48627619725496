// vendor
import React from 'react';

// components
import Container from '../../../container/container';
import WorkMedia from '../workMedia/workMedia';

// style
import style from './workImage.scss';

function WorkImage({ data }) {
  return (
    <Container className={style.container} padding="small" mw={data.showWide ? 'large' : 'medium'}>
      <WorkMedia data={data} />
    </Container>
  );
}

export default WorkImage;
