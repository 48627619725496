// vendor
import React, { memo, useContext, useRef, useState } from 'react';
import cc from 'classcat';
import dlv from 'dlv';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import config from '../../../config';

// static content
import domainContext from '../../../context/domainContext';

// components
import Container from '../../container/container';
import { Image } from 'dpdk-react-library';
import NewsletterForm from '../newsletterForm/newsletterForm';

// style
import style from './newsletter.scss';

function Newsletter({ className }) {
  const data = useContext(domainContext);


  const [hover, setHover] = useState(false);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config.PUBLIC_RECAPTCHA_SITE_KEY}
      language="en"
      useRecaptchaNet={true}
      scriptProps={{
        async: true, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // can be "head" or "body",
      }}
    >
      <Container
        className={cc([style.container, hover && style.hover, className])}
        mw="medium"
        padding="small"
      >
        {/* <Link
          className={style.preview}
          href={`https://newsletter.dpdk.com/${date.getFullYear()}/${padZero(
            date.getMonth() + 1,
            2
          )}`}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }} */}
        {/* > */}
        <div className={style.preview}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}>
          <div className={style.imageContainer}>
            <Image
              className={style.image}
              srcSet={dlv(data, 'signUp.image.srcSet')}
              srcSetWebp={dlv(data, 'signUp.image.srcSetWebp')}
              alt={dlv(data, 'signUp.image.alt')}
              height="auto"
            />
          </div>
          <div className={style.info}>
            <span className={style.month}>{dlv(data, 'signUp.subtitle')}</span>
            <p className={style.title}>{dlv(data, 'signUp.title')}</p>
          </div>
          </div>
        {/* </Link> */}
        <NewsletterForm />
      </Container>
    </GoogleReCaptchaProvider>
  );
}

export default memo(Newsletter);
