import { useLoadingState } from 'dpdk-react-library';
import { useRef, useState } from 'react';
import createFetcher from '../utils/createFetcher';
import useUpdateEffect from './useUpdateEffect';

function useFetcher({ initialItems, url, skip = 0, count, limit = 1, params } = {}, inputs = []) {
  const [items, setItems] = useState(initialItems ? [...initialItems] : []);
  const [hasMore, setHasMore] = useState(skip < count);
  const { state, dispatchLoad, dispatchError, dispatchSuccess } = useLoadingState();
  const fetcher = useRef();
  const promise = useRef();

  if (!fetcher.current) {
    fetcher.current = createFetcher({ url, skip, limit, params });
  }

  useUpdateEffect(didUpdate => {
    if (!didUpdate) return;
    fetcher.current = createFetcher({ url, skip: 0, limit, params });
    setItems([]);
    setHasMore(true);
    fetchNext();
  }, inputs);

  function fetchNext(event) {
    if (event && event.preventDefault) event.preventDefault();
    dispatchLoad();
    const currentPromise = fetcher.current
      .next()
      .then(next => {
        // Switched fetcher, ignore result and return
        if (currentPromise !== promise.current) return;

        setItems(prev => [...prev, ...next.value]);
        if (next.done) {
          setHasMore(false);
        }
        dispatchSuccess();
      })
      .catch(e => {
        dispatchError(e.message);
      });

    promise.current = currentPromise;
  }

  return { items, hasMore, fetchNext, loading: state.loading, error: state.error };
}

export default useFetcher;
