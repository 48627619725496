// vendor
import React, { memo } from 'react';

// components
import Tabs from '../components/sections/Tabs/Tabs';
import CtaSection from '../components/ctaSection/ctaSection';
import CultureGrid from '../components/sections/cultureGrid/cultureGrid';
import CultureIntro from '../components/sections/cultureIntro/cultureIntro';
import Page from '../components/page';

// utils
import { get } from '../utils/request';

const CompanyCulture = memo(({ data }) => {

  const pages = [
    {
      href: '/company',
      label: 'Story',
      type: 'story',
    },
    {
      href: '/company/culture',
      label: 'Culture',
      type: 'culture',
    },
  ];

  return (
    <Page>
      <Tabs current="culture" pages={pages} />
      <CultureIntro data={data} />
      <CultureGrid data={data} />
      <CtaSection type="careers" grey wide alt />
    </Page>
  );
});

CompanyCulture.getPageData = (match, env, location) => {
  return get(`/api/page/company/culture`);
};

export default CompanyCulture;
