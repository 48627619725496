// vendor
import React, { useContext, useEffect, useRef } from 'react';
import cc from 'classcat';
import { bool, func, object, oneOfType, string } from 'prop-types';

// animation
import revealContext from '../../context/revealContext';

// utils
import { handleIntersection } from '../../utils/dom';

RevealItem.propTypes = {
  animate: func.isRequired,
  innerRef: object,
  animateOnMount: bool,
  as: oneOfType([object, string]), // No function components directly, only ones wrapped in `React.forwardRef`
};

function RevealItem({
  as: Comp = 'div',
  children,
  animate,
  innerRef: ref = useRef(),
  className,
  animateOnMount,
  ...rest
}) {
  const context = useContext(revealContext);
  const hasRegistered = useRef(false);
  if (!hasRegistered.current && context.isInGroup) {
    context.addReveal(animate);
    hasRegistered.current = true;
  }

  function handleAnimate() {
    if (context.waitForBlocking) {
      context.waitForBlocking().then(animate);
    } else {
      animate();
    }
  }

  useEffect(() => {
    if (!context.isInGroup || context.hasAnimated) {
      if (animateOnMount || context.hasAnimated) {
        handleAnimate();
      } else {
        handleIntersection(ref.current, { onEnterOnce: handleAnimate, ...rest });
      }
    }
  }, []);

  return (
    <Comp
      className={cc([className, 'reveal-item'])}
      ref={ref}
      {...omit(rest, ['onEnter', 'onLeave', 'onEnterOnce', 'marginBottom', 'marginTop'])}
    >
      {children}
    </Comp>
  );
}

function omit(obj, keys) {
  const newObj = { ...obj };
  keys.forEach(key => delete newObj[key]);
  return newObj;
}

export default RevealItem;
