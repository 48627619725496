// vendor
import React from 'react';

// components
import ContactCard from '../../contactCard/contactCard';
import Container from '../../container/container';
import BasicVideo from '../../basicVideo/basicVideo';
import BasicImageCaption from '../../basicImageCaption/basicImageCaption';
import Heading from '../../heading/heading';
import HTML from '../../html/html';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// style
import style from './serviceContent.scss';

function ServiceContent({ sections, contactPerson, title, contactCaption }) {
  const { t } = useGlossary();

  return (
    <Container
      className={style.container}
      classNameInner={style.content}
      padding="small"
      mw="medium"
    >
      <BasicRevealItem as="header" className={style.header}>
        <Heading className={style.preHeading} as="p" size="h6" color="green" weight="semibold" caps>
          {t('service.detail.title')}
        </Heading>
        {title && (
          <Heading className={style.heading} level="h1" size="h2">
            {title}
          </Heading>
        )}
      </BasicRevealItem>
      <div className={style.inner}>
        {sections.map((section, i) => (
          <BasicRevealItem className={style.unit} as="div" key={i.toString()}>
            {section.type === 'text' && (
              <>
                {section.heading && (
                  <Heading className={style.heading} level="h2" size="h3">
                    {section.heading}
                  </Heading>
                )}
                {section.text && <HTML className={style.text}>{section.text}</HTML>}
              </>
            )}
            {(section.type === 'text_and_image' || section.type === 'image') && (
              <BasicImageCaption data={{ ...section }} />
            )}
            {section.type === 'video' && <BasicVideo {...section} />}
          </BasicRevealItem>
        ))}
      </div>
      {contactPerson && (
        <BasicRevealItem as="aside" className={style.aside}>
          <ContactCard contact={contactPerson} title={title} body={contactCaption} />
        </BasicRevealItem>
      )}
    </Container>
  );
}

export default ServiceContent;
