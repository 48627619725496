// vendor
import React, { memo } from 'react';
import Page from '../components/page';
// components
import BasicPageContent from '../components/sections/basicPageContent/basicPageContent';
// utils
import { get } from '../utils/request';

const Commitments = memo(({ data }) => {
  return (
    <Page title={data.title}>
      <BasicPageContent data={data} />
    </Page>
  );
});

Commitments.getPageData = (match, env, location) => {
  return get(`/api/page/commitments`);
};


export default Commitments;
