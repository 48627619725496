
import React, { memo } from 'react';
import RevealGroup from '../components/animation/revealGroup';
import ContactBanner from '../components/contactBanner/contactBanner';
import Page from '../components/page';
import ApproachList from '../components/sections/approachList/approachList';
import ApproachPresentation from '../components/sections/approachPresentation/approachPresentation';
import ApproachWorkflow from '../components/sections/approachWorkflow/approachWorkflow';
import { get } from '../utils/request';

const Approach = memo(({ data }) => {
  return (
    <Page newsletterHidden>
      <RevealGroup>
        <ApproachWorkflow />
        <ApproachList items={data.approaches} />
      </RevealGroup>
      <ApproachPresentation items={data.slideshow} offerings={data.offerings} />
      {data.contactPerson && (
        <ContactBanner
          caption={data.contactCaption}
          title={data.title}
          person={data.contactPerson}
          hubspotFormId={data.hubspotFormId}
          footer
        />
      )}
    </Page>
  );
});

Approach.getPageData = (match, env, location) => {
  return get(`/api/page/approach`);
};

export default Approach;
