// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import ApproachListItem from './approachListItem/approachListItem';
import Container from '../../container/container';
import Heading from '../../heading/heading';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './approachList.scss';

function ApproachList({ items }) {
  const { t } = useGlossary();

  return (
    <RevealGroup>
      <Container className={style.container} classNameInner={style.inner} padding="large">
        <div className={style.content}>
          <BasicRevealItem className={style.intro}>
            <Heading
              className={style.preheading}
              level="h2"
              size="h6"
              weight="semibold"
              color="green"
              caps
            >
              {t('approach.preheading.2')}
            </Heading>
            <Heading className={style.heading} level="h2" size="h2">
              {t('approach.heading.2')}
            </Heading>
          </BasicRevealItem>
          {items.map((item, i) => (
            <ApproachListItem item={item} key={i.toString()} />
          ))}
        </div>
      </Container>
    </RevealGroup>
  );
}

export default ApproachList;
