import React from 'react';
import { Image } from 'dpdk-react-library';
import style from './brandingLocation.scss';
import Heading from '../../heading/heading';
import Button from '../../button/button';
import Icon from '../../icon/icon';
import Link from '../../link';
import Container from '../../container/container';
import cc from 'classcat';

const BrandingLocation = props => {
  return (
    <Container padding="small" className={cc(style.outer__wrapper)}>
      <div className={style.location__container}>
        {props.locationProps.directions_link && (
          <Link external href={props.locationProps.directions_link}>
            {props.locationProps.directions_image && (
              <div className={style.location__map}>
                <Image
                  width="100%"
                  height="100%"
                  className={style.map__image}
                  {...props.locationProps.directions_image}
                />
              </div>
            )}
          </Link>
        )}
        <div className={style.location__directions}>
          {props.locationProps.directions_title && (
            <Heading level="h3" size="h3" weight="thin" className={cc(style.directions__heading)}>
              {props.locationProps.directions_title}
            </Heading>
          )}
          {props.locationProps.directions_desc && (
            <div dangerouslySetInnerHTML={{ __html: props.locationProps.directions_desc }} />
          )}
          {props.locationProps.directions_link && (
            <a href={props.locationProps.directions_link} target="_blank" rel="noopener noreferrer">
              <Button className={style.directions__button}>
                {props.locationProps.directions_cta_link
                  ? props.locationProps.directions_cta_link
                  : 'Get directions'}
              </Button>
            </a>
          )}
        </div>
      </div>
    </Container>
  );
};

export default BrandingLocation;
