// vendor
import React from 'react';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import OfficeCard from '../../officeCard/officeCard';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './contactOffice.scss';

function ContactOffice({ data }) {
  const officesTitle = data && data.offices_title;
  let officeData =
    data && data?.offices &&
    data.offices.map(office => ({
      address: office?.address,
      telephone: office?.telephone,
      title: office?.title,
      image: office?.card_image,
      slug: office?.slug,
    }));

  // TODO: This is a temporary hack - a weight factor should be added in the CMS in order to sort accordingly in FE
  const offices = officeData && [officeData[0], officeData[2], officeData[1]];

  return (
    <RevealGroup>
      <Container className={style.container} padding="small">
        <BasicRevealItem duration={1000} variation="left">
          <Heading level="h1" size="h2" weight="light">
            {officesTitle !== null ? officesTitle : 'Our offices'}
          </Heading>
        </BasicRevealItem>

        <div className={style.officeGroup}>
          {offices &&
            offices.map(office => {
              if (!office) return;
              return (
                <BasicRevealItem duration={1000} key={office.title}>
                  <OfficeCard {...office} />
                </BasicRevealItem>
              );
            })}
        </div>
      </Container>
    </RevealGroup>
  );
}

export default ContactOffice;
