// vendor
import { bool, number, oneOf, oneOfType, string } from 'prop-types';

Time.propTypes = {
  timestamp: oneOfType([string, number]).isRequired,
  weekday: oneOfType([bool, oneOf(['short', 'long'])]),
  year: oneOfType([bool, oneOf(['2-digit', 'numeric'])]),
  month: oneOfType([bool, oneOf(['2-digit', 'short', 'long'])]),
  day: bool,
  hour: oneOfType([bool, oneOf(['2-digit', 'numeric'])]),
  minute: bool,
  second: bool,
  hour12: bool,
};

function Time({ timestamp, weekday, year, month, day, hour, minute, second, hour12 }) {
  const date = new Date(parseInt(timestamp) * 1000);
  const options = {
    weekday: weekday === true ? 'long' : weekday,
    year: year === true ? '2-digit' : 'numeric',
    month: month === true ? '2-digit' : month,
    day: day === true ? (month === 'long' ? 'numeric' : '2-digit') : day,
    hour: hour ? '2-digit' : undefined,
    minute: minute ? '2-digit' : undefined,
    second: second ? '2-digit' : undefined,
    hour12,
  };

  // TODO: Get locale from data
  let formattedDate = date.toLocaleDateString('en-IN', options).replace(/\//g, '.');

  const dateArray = formattedDate.split(' ');
  const finalDay = dateArray[0];
  const finalMonth = dateArray[1] !== undefined ? dateArray[1] : false;
  const finalYear = dateArray[2];

  const dayArray = finalDay.split('');
  if (typeof dayArray[1] === 'undefined') dayArray.unshift('0');

  const firstDigit = dayArray[0];
  const lastDigit = dayArray[1] !== undefined ? dayArray[1] : dayArray[0];
  let formattedDay;

  if (firstDigit !== '1') {
    switch (lastDigit) {
      case '1':
        formattedDay = `${firstDigit}${lastDigit}st`;
        break;
      case '2':
        formattedDay = `${firstDigit}${lastDigit}nd`;
        break;
      case '3':
        formattedDay = `${firstDigit}${lastDigit}rd`;
        break;
      default:
        formattedDay = `${firstDigit}${lastDigit}th`;
        break;
    }
  } else {
    formattedDay = `${firstDigit}${lastDigit}th`;
  }

  if (finalMonth) formattedDate = finalMonth.concat(` ${formattedDay}`, ` ${finalYear}`);

  return formattedDate;
}

export default Time;
