// vendor
import React, { useState } from 'react';
import cc from 'classcat';

// components
import { Image } from 'dpdk-react-library';
import ContactBusiness from './contactBusiness/contactBusiness';
import ContactMedia from './contactMedia/contactMedia';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './contactForms.scss';

function ContactForms({ data }) {
  const title = data && data.title;
  const introTitle = data && data.intro_title;
  const introDesc = data && data.intro_description;
  const introImage = data && data.intro_image;
  const formData = data && data.forms;
  const newBusinessForm = formData && formData.length > 0 && formData[0];
  const mediaInquiryForm = formData && formData.length > 0 && formData[1];

  const [active, setActive] = useState(false);
  //TODO: make the formData load dynamically.
  return (
    <RevealGroup>
      <Container
        className={style.container}
        classNameInner={style.inner}
        padding="small"
        spaced
        relative
      >
        <div className={style.content}>
          {title && (
            <BasicRevealItem variation="left">
              <Heading
                className={style.preheading}
                level="h2"
                size="h6"
                weight="semibold"
                caps
                color="green"
              >
                {title}
              </Heading>
            </BasicRevealItem>
          )}

          {introTitle && (
            <BasicRevealItem
              variation="left"
              as={Heading}
              className={style.header}
              level="h1"
              size="h1"
            >
              {introTitle}
            </BasicRevealItem>
          )}

          {introDesc && (
            <BasicRevealItem variation="left" as={HTML} className={style.body}>
              {introDesc}
            </BasicRevealItem>
          )}

          {introImage && (
            <BasicRevealItem variation="left">
              <Image {...introImage} className={style.image} />
            </BasicRevealItem>
          )}
        </div>

        <div className={style.contact}>
          <BasicRevealItem variation="fade" as="nav" className={style.nav}>

            {newBusinessForm && newBusinessForm.tab_label && <HTML
              className={cc([style.anchor, !active && style.active])}
              onClick={() => setActive(false)}
            >
              {newBusinessForm.tab_label}
            </HTML>}

            {mediaInquiryForm && mediaInquiryForm.tab_label && <HTML
              className={cc([style.anchor, active && style.active])}
              onClick={() => setActive(true)}
            >
              {mediaInquiryForm.tab_label}
            </HTML>}

            <span className={style.underline} />
          </BasicRevealItem>
          {active ? (
            <>{mediaInquiryForm && <ContactMedia data={mediaInquiryForm} />}</>
          ) : (
            <>{newBusinessForm && <ContactBusiness data={newBusinessForm} />}</>
          )}
        </div>
      </Container>
    </RevealGroup>
  );
}

export default ContactForms;
