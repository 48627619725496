// vendor
import React from 'react';

// animation
import BasicRevealItem from '../animation/basicRevealItem';
import RevealGroup from '../animation/revealGroup';

// components
import Heading from '../heading/heading';
import Link from '../link';

// style
import style from './basicCTA.scss';

function BasicCTA({ disclaimer, ctaLabel, link }) {

  return (
    <RevealGroup>
      <div className={style.container}>
        <BasicRevealItem>
          <div className={style.inner}>
            {disclaimer && (
              <Heading className={style.heading} level="h4" size="h5">
                {disclaimer}
              </Heading>
            )}
            {ctaLabel && link && (
              <Link
                href={link}
                className={style.ctaButton}
                rel="noopener noreferrer"
                target="_blank"
              >
                {ctaLabel}
              </Link>
            )}
          </div>
        </BasicRevealItem>
      </div>
    </RevealGroup>
  );
}

export default BasicCTA;
