// vendor
import React, { useContext } from 'react';
import dlv from 'dlv';
import Helmet from 'react-helmet';

// components
import BlockUpdates from '../components/blockUpdates';
import HomeChallengesSelection from '../components/sections/homeChallengesSelection/homeChallengesSelection';
import HomeChallengesReel from '../components/sections/homeChallengesReel/homeChallengesReel';
import HomeCtaSection from '../components/sections/homeCtaSection/homeCtaSection';
import HomeHero from '../components/sections/homeHero/homeHero';
import HomeLinkBar from '../components/sections/homeLinkBar/homeLinkBar';
import HomeWorkGrid from '../components/sections/homeWorkGrid/homeWorkGrid';
import OfferingBlock from '../components/offeringBlock/offeringBlock';
import Page from '../components/page';

// utils
import config from '../config';

// static content
import domainContext from '../context/domainContext';
import locationContext from '../context/locationContext';
import { get } from '../utils/request';

function Home({ data }) {
  const location = useContext(locationContext);
  const { domain, offices } = useContext(domainContext);

  const headerVideo = dlv(data, 'headerVideo', '/media/videos/homepagevideo.mp4');
  const headerTitle = dlv(data, 'headerTitle', null);
  const headerCta = dlv(data, 'headerCTA', null);
  const offeringsTitle = dlv(data, 'offeringsTitle', null);
  const offeringsParagraph = dlv(data, 'offeringsParagraph', null);
  const offeringsImage = dlv(data, 'offeringsImage', null);
  const videoThumbnail = dlv(data, 'videoThumbnail', null);
  const videoPath = dlv(data, 'videoFile', null);
  const ctaTitle = dlv(data, 'ctaTitle', null);
  const ctaParagraph = dlv(data, 'ctaParagraph', null);
  const ctaLabel = dlv(data, 'ctaLabel', null);
  const selectedStories = dlv(data, 'work', false);

  if (selectedStories && selectedStories.length > 3) {
    selectedStories.splice(3);
  }
  if (selectedStories && selectedStories[0] && selectedStories[0].highlighted !== true) {
    selectedStories[0].highlighted = true;
  }

  if (
    (selectedStories && selectedStories[1] && selectedStories[1].highlighted) ||
    (selectedStories[2] && selectedStories[2].highlighted) === true
  ) {
    selectedStories[1].highlighted = false;
    selectedStories[2].highlighted = false;
  }

  return (
    <Page>
      <BlockUpdates>
        <HomeHero video={headerVideo} />
        {headerTitle && <HomeLinkBar title={data.headerTitle} cta={headerCta} />}
        {
          <HomeChallengesReel
            thumbnail={videoThumbnail}
            video={videoPath}
            heroTitle={data.workTitle}
          />
        }
        {selectedStories && <HomeWorkGrid data={selectedStories} cta={data.workCTA} />}
        {data.offerings && (
          <OfferingBlock
            offerings={data.offerings}
            title={offeringsTitle}
            paragraph={offeringsParagraph}
            dynamicImage={offeringsImage}
          />
        )}
        {data.challenges && data.challenges.items && (
          <HomeChallengesSelection items={data.challenges.items} />
        )}
        <HomeCtaSection title={ctaTitle} paragraph={ctaParagraph} label={ctaLabel} />
      </BlockUpdates>
      <Helmet
        script={[
          {
            type: 'application/ld+json',
            innerHTML: `
              {
                  "@context": "http://schema.org",
                  "@type": "WebSite",
                  "name": "${config.defaultPageTitle} - ${config.pageTitleSuffix}",
                  "url": "https://${location.host}"
              }
          `,
          },
          {
            type: 'application/ld+json',
            innerHTML: `
              {
                "@context": "http://schema.org/",
                "@type": "Organization",
                "name": "DPDK",
                "url": "https://${location.host}",
                "logo": "http://${location.host}${dlv(domain, 'seo.shareImage.original', '')}",
                "contactPoint": [
                  ${offices
                    .map(
                      office => `
                        {
                          "streetAddress": "${office.address[0]}",
                          "postalCode": "${office.address[1]}",
                          "addressCountry": "${office.address[2]}",
                          "email": "${office.email}",
                          "telephone": "${office.telephone}",
                          "@context": "http://schema.org",
                          "@type": "PostalAddress"
                        }
                      `
                    )
                    .join(',')}
                ]
              }
            `,
          },
        ]}
      />
    </Page>
  );
}

Home.getPageData = (match, env, location) => {
  return get(`/api/page/home`);
};

export default Home;
