import anime from 'animejs';

function general(ref, promise) {
  const tl = anime.timeline();
  const plane = document.createElement('div');
  plane.classList.add('plane');
  plane.style.cssText = `
    width: 100%;
    height: ${window.innerHeight - document.querySelector('#mainNavigation').clientHeight}px;
    position: fixed;
    top: ${document.querySelector('#mainNavigation').clientHeight}px;
    left: 0;
    opacity: 0;
    background: white;
    z-index: 10;
    will-change: opacity;
  `;
  document.querySelector('.page').appendChild(plane);
  tl.add({
    targets: '.plane',
    easing: 'linear',
    duration: 200,
    opacity: [0, 1],
    complete: () => {
      promise.resolve();
    },
  });

  // If #rotterdam is in url do tl.add({ new animation scroll down })
}

export default general;
