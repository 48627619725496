// vendor
import React from 'react';
import cc from 'classcat';

// components
import Container from '../container/container';
import CtaBlock from '../ctaBlock/ctaBlock';

// style
import style from './ctaSection.scss';

function CtaSection({ type = 'project', grey, wide, className }) {
  return (
    <Container
      className={cc([style.container, grey && style.grey, wide && style.wide, className])}
      classNameInner={style.content}
      padding="small"
      mw="small"
    >
      <CtaBlock className={style.cta} type={type} />
    </Container>
  );
}

export default CtaSection;
