import React, { memo } from 'react';

import CapabilitiesOverview from '../components/sections/capabilitiesOverview/capabilitiesOverview';
import CapabilitiesSelection from '../components/sections/capabilitiesSelection/capabilitiesSelection';
import CapabilitiesTestimonials from '../components/sections/capabilitiesTestimonials/capabilitiesTestimonials';
import ContactBanner from '../components/contactBanner/contactBanner';
import Page from '../components/page';
import { get } from '../utils/request';

const Capabilities = memo(({ data }) => {
  const headerTitle = data.headerTitle || null;
  const quotesTitle = data.quotesTitle || null;

  return (
    <Page newsletterHidden>
      <CapabilitiesOverview headerTitle={headerTitle} items={data.capabilities.items} offerings={data.offerings} />
      <CapabilitiesTestimonials quotesTitle={quotesTitle} items={data.quotes} />
      <CapabilitiesSelection items={data.stories} />
      {data.contactPerson && (
        <ContactBanner
          caption={data.contactCaption}
          title={data.title}
          person={data.contactPerson}
          hubspotFormId={data.hubspotFormId}
          footer
        />
      )}
    </Page>
  );
});

Capabilities.getPageData = (match, env, location) => {
  return get(`/api/page/capabilities`);
};

export default Capabilities;
