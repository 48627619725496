// vendor
import cc from 'classcat';
import { oneOf, string, oneOfType, node } from 'prop-types';
import React from 'react';
// animation
import BasicRevealItem from '../animation/basicRevealItem';
import RevealGroup from '../animation/revealGroup';
// components
import Container from '../container/container';
import Heading from '../heading/heading';
import HTML from '../html/html';
// style
import style from './sectionHeading.scss';

SectionHeading.propTypes = {
  preheading: string,
  heading: string,
  subheading: string,
  body: oneOfType([string, node]),
  mw: oneOf(['small', 'medium', 'large']),
  padding: oneOf(['default', 'small', 'large']),
  className: string,
};

function SectionHeading({
  preheading,
  heading,
  subheading,
  body,
  mw = 'large',
  padding = 'default',
  className,
  classNameInner,
  revealGroupStyle,
  noOpacity,
  isH2 = false,
}) {
  return (
    <RevealGroup style={revealGroupStyle}>
      <Container
        as="header"
        stagger={150}
        className={cc([style.container, noOpacity && style.noOpacity, className])}
        classNameInner={cc([style.content, classNameInner])}
        mw={mw}
        padding={padding}
        spaced
      >
        {preheading && (
          <BasicRevealItem variation="left">
            <span className={style.preheading}>{preheading}</span>
          </BasicRevealItem>
        )}
        {heading && (
          <BasicRevealItem variation="left">
            <Heading
              className={style.heading}
              level={`${isH2 ? 'h2' : 'h1'}`}
              size="h1"
              dangerousHTML
            >
              {heading}
            </Heading>
          </BasicRevealItem>
        )}
        {subheading && (
          <BasicRevealItem variation="left">
            <Heading className={style.subheading} level="h3" size="h3">
              {subheading}
            </Heading>
          </BasicRevealItem>
        )}
        {body && (
          <BasicRevealItem variation="left">
            {typeof body === 'string' ? (
              <HTML className={style.body}>{body}</HTML>
            ) : (
              <div className={style.body}>{body}</div>
            )}
          </BasicRevealItem>
        )}
      </Container>
    </RevealGroup>
  );
}

export default SectionHeading;
