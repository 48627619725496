import React from 'react';

function IconPlus({ ...props }) {
  return (
      <svg viewBox="0 0 12 2" {...props }>
        <line
          id="Line_116"
          data-name="Line 116"
          x1="12"
          transform="translate(0 1)"
          fill="none"
          stroke="#2fd0b7"
          stroke-width="2"
        />
      </svg>

  );
}

export default IconPlus;
