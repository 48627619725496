// vendor
import React from 'react';

function Linkedin({ ...props }) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M0,100h22.362V31.25H0V100z M83.275,32.056c-0.238-0.075-0.463-0.156-0.713-0.225c-0.3-0.069-0.6-0.125-0.906-0.175c-1.188-0.237-2.487-0.406-4.013-0.406c-13.037,0-21.306,9.481-24.031,13.144V31.25H31.25V100h22.362V62.5c0,0,16.9-23.537,24.031-6.25c0,15.431,0,43.75,0,43.75H100V53.606C100,43.219,92.881,34.562,83.275,32.056z M10.938,21.875c6.041,0,10.938-4.897,10.938-10.938S16.978,0,10.938,0S0,4.897,0,10.938S4.897,21.875,10.938,21.875z" />
    </svg>
  );
}

export default Linkedin;
