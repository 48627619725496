// vendor
import React from 'react';

// components
import Container from '../../container/container';
import CtaBlock from '../../ctaBlock/ctaBlock';

// style
import style from './homeCtaSection.scss';

function HomeCtaSection({title, paragraph, label}) {
  return (
    <Container className={style.container} classNameInner={style.content} padding="small">
      <CtaBlock className={style.cta} type="project" title={title} paragraph={paragraph} label={label} />
    </Container>
  );
}

export default HomeCtaSection;
