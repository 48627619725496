import { useState } from 'react';

function useControlProp(externalValue, setExternalValue, initialValue) {
  const [isControlled] = useState(externalValue !== undefined);
  const [internalValue, setInternalValue] = useState(initialValue);

  const value = isControlled ? externalValue : internalValue;
  const setValue = isControlled ? setExternalValue : setInternalValue;

  return [value, setValue];
}

export default useControlProp;
