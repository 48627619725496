// vendor
import React from 'react';
import cc from 'classcat';

// components
import Icon from '../icon/icon';
import Link from '../link';

// style
import style from './backButton.scss';

function BackButton({ children, href, onClick = () => {}, className }) {
  return (
    <Link className={cc([style.container, className])} href={href} onClick={onClick}>
      <Icon className={style.icon} type="arrowRightSmall" />
      <span className={style.label}>{children}</span>
    </Link>
  );
}

export default BackButton;
