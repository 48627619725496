// vendor
import React from 'react';

// components
import BlockQuote from '../../../blockQuote/blockQuote';

function WorkQuote({ data }) {
  return (
    <BlockQuote
      mw="medium"
      position={data.position}
      body={data.quote}
      name={data.quotee}
      image={data.image}
    />
  );
}

export default WorkQuote;
