// vendor
import React from 'react';

// components
import OldBrowserContent from '../components/sections/oldBrowserContent/oldBrowserContent';
import Page from '../components/page';

function OldBrowser() {
  return (
    <Page newsletterHidden>
      <OldBrowserContent />
    </Page>
  );
}

export default OldBrowser;
