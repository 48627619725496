// vendor
import React from 'react';

function Facebook({ ...props }) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M36.753,19.367c0,2.52,0,13.768,0,13.768H26.666V49.97h10.087V100h20.721V49.972h13.905c0,0,1.302-8.073,1.933-16.899c-1.81,0-15.76,0-15.76,0s0-9.794,0-11.511c0-1.72,2.259-4.035,4.492-4.035c2.229,0,6.933,0,11.289,0c0-2.292,0-10.212,0-17.527c-5.816,0-12.433,0-15.35,0C36.241-0.001,36.753,16.851,36.753,19.367z" />
    </svg>
  );
}

export default Facebook;
