function formatTime(
  language,
  timestamp,
  { weekday, year, month, day, hour, minute, second, hour12 } = {}
) {
  const date = new Date(0);
  date.setUTCSeconds(timestamp);
  const options = {
    weekday: weekday === true ? 'long' : weekday,
    year: year ? 'numeric' : undefined,
    month: month === true ? '2-digit' : month,
    day: day === true ? (month === 'long' ? 'numeric' : '2-digit') : day,
    hour: hour ? '2-digit' : undefined,
    minute: minute ? '2-digit' : undefined,
    second: second ? '2-digit' : undefined,
    hour12,
  };

  return date.toLocaleString(language, options);
}

function getLocalTimeWithTimezone(currentDateNow, timezoneOffset) {
  const addDST = () => {
    if ((currentMonth > 2 && currentMonth < 9) || (currentMonth === 9 && currentDay < 27)) {
      return 1;
    } else {
      return 0;
    }
  }
  const UTCHours = currentDateNow.getUTCHours();
  const currentDay = currentDateNow.getUTCDay();
  const currentMonth = currentDateNow.getUTCMonth();
  const currentHours = ("0" + (UTCHours + Number(timezoneOffset) + addDST())).slice(-2);
  const currentMinutes = ("0" + currentDateNow.getMinutes()).slice(-2);

  return `${currentHours}:${currentMinutes}`;
}

export {formatTime, getLocalTimeWithTimezone};
