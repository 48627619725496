// vendor
import React from 'react';

// components
import AutoPlayingVideo from '../../../autoPlayingVideo/AutoPlayingVideo';
import HTML from '../../../html/html';
import { Image } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../../../animation/basicRevealItem';

// style
import style from './workMedia.scss';

// Lazy Loading disabled :(

function WorkMedia({ data, className }) {
  return (
    <BasicRevealItem className={className}>
      {data.type === 'image' ? (
        <Image {...{...data.image, removePlaceholderOnLoad: true}} />
      ) : (
        <AutoPlayingVideo
          muted
          source={
            data.video.upload
              ? data.video.upload.replace('/var/source/dpdk_website_2019/files', '/assets')
              : data.video.url
          }
        />
      )}
      {data.caption && <HTML className={style.caption}>{data.caption}</HTML>}
    </BasicRevealItem>
  );
}

export default WorkMedia;
