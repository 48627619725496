import React, { memo, useContext } from 'react';
import Helmet from 'react-helmet';
import locationContext from '../context/locationContext';
import getBrandingStructureData from '../utils/getStructuredData/getBrandingStructureData';
import getAgencyNewYorkStructureData from '../utils/getStructuredData/getAgencyNewYorkStructureData';
import getAgencySanFranStructureData from '../utils/getStructuredData/getAgencySanFranStructureData';
import getAgencyRotterdamStructureData from '../utils/getStructuredData/getAgencyRotterdamStructureData';

function StructuredData() {
  const locationObj = useContext(locationContext);
  const relativePath = locationObj.pathname;
  let structuredData = {};

  if (relativePath === '/capabilities/branding') structuredData = getBrandingStructureData();
  if (relativePath === '/digital-agency/new-york') structuredData = getAgencyNewYorkStructureData();
  if (relativePath === '/digital-agency/san-francisco')
    structuredData = getAgencySanFranStructureData();
  if (relativePath === '/digital-agency/rotterdam')
    structuredData = getAgencyRotterdamStructureData();

  return <Helmet script={[structuredData]} />;
}

export default memo(StructuredData);
