// vendor
import React from 'react';
import cc from 'classcat';

// components
import Container from '../container/container';
import HTML from '../html/html';
import { Image } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './blockQuote.scss';

function BlockQuote({ className, mw = 'large', small, body, name, position, image }) {
  return (
    <Container
      className={cc([style.container, className])}
      classNameInner={style.content}
      padding="small"
      mw={mw}
    >
      <BasicRevealItem className={style.quote} as="blockquote">
        {body && <p className={cc([style.text, small && style.small])}>{`‘${body}’`}</p>}
        {name && position && (
          <footer className={style.footer}>
            {image && <Image className={style.image} {...image} noWidth />}
            <cite className={style.cite}>
              {name && (
                <HTML className={cc([style.name])} size="large" component="p">
                  {name}
                </HTML>
              )}
              {position && (
                <HTML className={style.function} component="p">
                  {position}
                </HTML>
              )}
            </cite>
          </footer>
        )}
      </BasicRevealItem>
    </Container>
  );
}

export default BlockQuote;
