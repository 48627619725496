import BackButton from '../../backButton/backButton';
import BasicRevealItem from '../../animation/basicRevealItem';
import React from 'react';
import cc from 'classcat';
import style from './storiesHeader.scss';

function StoriesHeader(props) {
  return (
    <BasicRevealItem
      variation="left"
      className={cc([style.meta, style.block, props.addSpacing && style.addSpacing])}
    >
      <section className={style.outer__header} style={{ backgroundColor: props.bg }}>
        <div className={style.container__header}>
          {props.backBtn && (
            <BasicRevealItem className={style.container__button}>
              <BackButton className={style.overviewButton} href="/stories">
                All stories
              </BackButton>
            </BasicRevealItem>
          )}
          {props.subHeader && <p className={style.subHeader__header}>{props.subHeader}</p>}
          {props.mainHeader && <h1 className={style.mainHeader__header}>{props.mainHeader}</h1>}
        </div>
      </section>
    </BasicRevealItem>
  );
}

export default StoriesHeader;
