import React, { memo } from 'react';
import ContactBanner from '../components/contactBanner/contactBanner';
import Page from '../components/page';
import CareersBenefits from '../components/sections/careersBenefits/careersBenefits';
import Tabs from '../components/sections/Tabs/Tabs';
import { get } from '../utils/request';

const CareersPerks = memo(({data}) => {

  const pages = [
    {
      href: '/careers',
      label: 'Vacancies',
      type: 'vacancies',
    },
    {
      href: '/careers/perks-and-benefits',
      label: 'Perks & Benefits',
      type: 'perks',
    },
  ];

  return (
    <Page newsletterHidden>
      <Tabs current="perks" pages={pages} />
      {data.content && <CareersBenefits data={data} noPaddingBottom={true} />}
      {data.contactPerson && (
        <ContactBanner
          caption={data.contactFormTitle}
          title={data.title}
          person={data.contactPerson}
          hubspotFormId={data.hubspotFormId}
          footer
        />
      )}
    </Page>
  );
});

CareersPerks.getPageData = (match, env, location) => {
  return get(`/api/page/benefits`);
};

export default CareersPerks;
