// vendor
import React from 'react';

// components
import NotFoundIntro from '../components/sections/notFoundIntro/notFoundIntro';
import Page from '../components/page';

function NotFound() {
  return (
    <Page>
      <NotFoundIntro />
    </Page>
  );
}

export default NotFound;
