import anime from 'animejs';

function scrollTo(el) {
  if (!el) return;
  const bounds = el.getBoundingClientRect();

  const tween = {
    y: window.pageYOffset,
  };

  anime({
    targets: tween,
    y: tween.y + bounds.top,
    duration: 1000,
    easing: 'easeInOutCubic',
    update: () => {
      window.scrollTo(0, tween.y);
    },
  });
}

function scroll(selector) {
  if (!selector) return;
  const node = document.querySelector(selector);
  scrollTo(node);
}

export default scroll;
