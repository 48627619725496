import React, { useContext } from 'react';

// components
import HTML from '../html/html';
import Icon from '../icon/icon';
import Link from '../link';
// vendor
import cc from 'classcat';
import locationContext from '../../context/locationContext';
// style
import style from './shareTo.scss';

function ShareTo({ title = '' }) {
  const location = useContext(locationContext);
  const url = `https://${location.host}${location.pathname}`;

  const items = [
    {
      label: 'Twitter',
      href: `https://twitter.com/intent/tweet?text=${title}&url=${url}/&via=dpdk`,
      icon: 'twitter',
    },
    {
      label: 'Facebook',
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      icon: 'facebook',
    },
    {
      label: 'Linkedin',
      href: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      icon: 'linkedin',
    },
  ];

  return (
    <div className={style.socialsSticky}>
      <div className={cc([style.socials])}>
        {items.map((item, i) => (
          <Link className={style.anchor} href={item.href} external key={i.toString()}>
            <span className={style.name}>{item.label}</span>
            <Icon className={style.icon} type={item.icon} />
          </Link>
        ))}
        {/* <hr className={style.separator} />
        <HTML className={style.label}>{t('story.label.share')}</HTML> */}
      </div>
    </div>
  );
}

export default ShareTo;
