// vendor
import React, { useState } from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../context/glossaryContext';

// components
import Heading from '../heading/heading';
import HTML from '../html/html';
import Link from '../link';
import ReadMore from '../readMore/readMore';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './capabilitiesGridItem.scss';

function CapabilitiesGridItem({ title, summary, linkText, slug, className = '' }) {
  const { t } = useGlossary();
  const [hover, setHover] = useState(false);

  return (
    <BasicRevealItem
      as={Link}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={cc([style.item, className])}
      href={slug ? `/capabilities${slug.replace('/capability', '')}` : '/'}
      animation="toCapabilityDetail"
    >
      {title && (
        <Heading className={style.heading} level="h3" size="h3" color={hover ? 'white' : 'black'}>
          {title}
        </Heading>
      )}
      {summary && (
        <HTML className={style.summary} color={hover ? 'white' : 'black'}>
          {summary}
        </HTML>
      )}
      <ReadMore
        className={style.button}
        label={linkText ? linkText : t('label.readMore')}
        color={hover ? 'white' : 'green'}
        hovered={hover}
      />
    </BasicRevealItem>
  );
}

export default CapabilitiesGridItem;
