// vendor
import React from 'react';

// components
import Container from '../../container/container';
import { Image } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './serviceHero.scss';

function ServiceHero({ image }) {
  return (
    <RevealGroup stagger={150}>
      <Container className={style.container} mw="medium">
        {image && (
          <BasicRevealItem variation="fade" className={style.imageContainer}>
            <Image
              className={style.image}
              {...image}
              noWidth
              width={undefined}
              height={undefined}
            />
          </BasicRevealItem>
        )}
      </Container>
    </RevealGroup>
  );
}

export default ServiceHero;
