// vendor
import React, { useEffect, useState } from 'react';
import { isMobile } from 'dpdk-devicedetection';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';

// style
import style from './approachWorkflow.scss';

function ApproachWorkflow() {
  const [mobile, setMobile] = useState(true);
  const { t } = useGlossary();

  useEffect(() => {
    setMobile(isMobile());
  }, []);

  return (
    <RevealGroup stagger={150}>
      <Container
        className={style.infographicContainer}
        classNameInner={style.infographic}
        padding="small"
        spaced
      >
        <BasicRevealItem className={style.infographicBackground} />
        <Container
          as="header"
          stagger={150}
          className={style.container}
          classNameInner={style.inner}
          mw="medium"
          padding="small"
          spaced
        >
          <BasicRevealItem variation="left">
            <Heading className={style.preheading} level="h2" size="h6" weight="semibold" caps>
              {t('approach.preheading')}
            </Heading>
          </BasicRevealItem>
          <BasicRevealItem variation="left">
            <Heading level="h1" size="h1" color="white" dangerousHTML>
              {t('approach.heading')}
            </Heading>
          </BasicRevealItem>
        </Container>
        <BasicRevealItem duration={1000} variation="fade" className={style.center}>
          {mobile ? (
            <img
              className={style.gif}
              src="/media/images/approach-workflow-mobile.png"
              alt={t('work.preheading')}
            />
          ) : (
            <img
              className={style.gif}
              src="/media/images/approach-workflow.gif"
              alt={t('work.preheading')}
            />
          )}
        </BasicRevealItem>
      </Container>
    </RevealGroup>
  );
}

export default ApproachWorkflow;
