import React, { memo } from 'react';
import Button from '../components/button/button';
import ContactBanner from '../components/contactBanner/contactBanner';
import Container from '../components/container/container';
import IntersectionChecker from '../components/intersectionChecker';
import Page from '../components/page';
import WorkHighlight from '../components/sections/workHighlight/workHighlight';
import ShowReel from '../components/showReel/showReel';
import WorkGrid from '../components/workGrid/workGrid';
import { useGlossary } from '../context/glossaryContext';
import useFetcher from '../hooks/useFetcher';
import { get } from '../utils/request';

const LIMIT = 7;

const Work = memo(({ data, data: { workGrid } }) => {
  const { t } = useGlossary();
  const { items, fetchNext, loading, error, hasMore } = useFetcher({
    url: '/api/work',
    initialItems: workGrid.items,
    skip: workGrid.skip + workGrid.limit,
    count: workGrid.count,
    limit: LIMIT,
  });

  // Groups with 2, 3, or 4 items
  const groups = [];
  // If a group already contains 3 items and the 4th one is highlighted,
  // Add it to a queue for use in the next group
  const highlightedQueue = [];
  // space in each group, gets reset, when a new group is added
  let spaceLeft = 4;
  let currentGroup = [];

  function addItem(item, spaceNeeded) {
    currentGroup.push(item);
    spaceLeft -= spaceNeeded;

    if (spaceLeft <= 0) {
      groups.push(currentGroup);
      spaceLeft = 4;
      currentGroup = [];
    }
  }

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const spaceNeeded = item.highlighted === true ? 2 : 1;
    if (spaceNeeded > spaceLeft) {
      highlightedQueue.push(item);
      continue;
    }

    addItem(item, spaceNeeded);

    if (highlightedQueue.length && spaceLeft > 1) {
      addItem(highlightedQueue.shift(), 2);
    }
  }

  if (currentGroup.length) {
    groups.push(currentGroup);
  }

  return (
    <Page newsletterHidden>
      {workGrid.skip === 0 && (
        <>
          <WorkHighlight data={data.highlightedWork} />
          <Container spaced>
            {data?.videoFile && (
              <ShowReel thumbnail={data?.videoThumbnail} video={data.videoFile} />
            )}
          </Container>
        </>
      )}

      <Container padding="small" spaced>
        {groups.map((group, i) => (
          <WorkGrid
            items={group}
            key={i.toString()}
            bigShadow={i === 0 ? true : false}
            hasHigherLevelHeadings
          />
        ))}
        {!loading && !error && hasMore && (
          <IntersectionChecker onEnterOnce={fetchNext} marginTop={500}>
            <Button boxed href={`/work?skip=${workGrid.skip + workGrid.limit}`} onClick={fetchNext}>
              {t('label.loadMore')}
            </Button>
          </IntersectionChecker>
        )}
      </Container>
      {data.contactPerson && (
        <ContactBanner
          caption={data.contactCaption}
          title={data.title}
          person={data.contactPerson}
          footer
        />
      )}
    </Page>
  );
});

Work.getPageData = (match, env, location) => {
  return get(`/api/page/work?skip=${location.query.skip || 0}&limit=${LIMIT}`);
};

export default Work;
