// vendor
import React, { useState } from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../context/glossaryContext';

// components
import Button from '../button/button';
import Heading from '../heading/heading';
import Link from '../link';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './careerItem.scss';

function CareersItem({ item, className }) {
  const [hover, setHover] = useState(false);
  const { t } = useGlossary();

  return (
    <BasicRevealItem
      as={Link}
      className={cc([style.container, className])}
      href={item.url}
      external={true}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={style.content}>
        <p className={style.meta}>
          {item.experience && <span className={style.role}>{item.experience}</span>}
          {item.location && item.location.country && (
            <span className={style.location}>{item.location.country}</span>
          )}
        </p>
        {item.title && (
          <Heading className={style.heading} level="h2" size="h3" color="white">
            {item.title}
          </Heading>
        )}
      </div>
      <Button
        className={style.button}
        as="span"
        color={hover === true ? 'white' : 'green'}
        hovered={hover}
        hoverHighlight
      >
        {t('company.careers.button.label')}
      </Button>
    </BasicRevealItem>
  );
}

export default CareersItem;
