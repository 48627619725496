import BasicRevealItem from '../../animation/basicRevealItem';
import React from 'react';
import { Image } from 'dpdk-react-library';
import ShortStorie from '../storiesEarlier/shortStorie/shortStorie';
import StoriesHeaderItem from '../../sections/storiesHeader/storiesHeaderItem/storiesHeaderItem';
import Link from '../../link';
import cc from 'classcat';
import Icon from '../../icon/icon';
import style from './storiesMost.scss';

function StoriesMost({ items, storiesHomePage, image, title }) {
  const styleCardCategories = storiesHomePage ? '' : style.es_card_categoriesLanding;
  const styleStoriesSectionCategories = storiesHomePage
    ? ''
    : style.es_storiesSection_categoriesLanding;
  const styleStoriesCategories = storiesHomePage ? '' : style.stories_categoriesLanding;

  return (
    items &&
    items.length > 0 && (
      <BasicRevealItem className={cc([style.stories, styleStoriesCategories])}>
        <div className={style.headerContainer}>
          <StoriesHeaderItem title={title} />
          {storiesHomePage && (
            <BasicRevealItem duration={1000} variation="left">
              <Link className={cc([style.readmoreLink, style.readMoreLinkDesktop])} href="/stories">
                View all stories
                <Icon className={style.icon} type="arrowRight" />
              </Link>
            </BasicRevealItem>
          )}
        </div>
        <div className={style.es_container}>
          <div className={cc([style.es_card_storiesHome, styleCardCategories])}>
            {storiesHomePage && (
              <BasicRevealItem className={style.es_imgSection}>
                {image && <Image {...image} alt="Most Read Stories" className={style.es_img} />}
              </BasicRevealItem>
            )}

            <BasicRevealItem
              className={cc([style.es_storiesSection, styleStoriesSectionCategories])}
            >
              {items.map(item => (
                <ShortStorie item={item} key={item.title} defaultStyle={storiesHomePage} />
              ))}
            </BasicRevealItem>
          </div>
        </div>
        {storiesHomePage && (
          <Link className={cc([style.readmoreLink, style.readMoreLinkMobile])} href="/stories">
            View all stories
            <Icon className={style.icon} type="arrowRight" />
          </Link>
        )}
      </BasicRevealItem>
    )
  );
}

export default StoriesMost;
