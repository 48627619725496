// vendor
import React from 'react';
import Container from '../../container/container';
import Heading from '../../heading/heading';

// components
import ShowReel from '../../showReel/showReel';

// style
import style from './homeChallengesReel.scss';

function HomeChallengesReel({thumbnail, video, heroTitle, heroSub }) {
  return (
    <section className={style.container}>
      <Container padding={"small"}>
       <Heading level="h2" size="h1" weight="thin">
              {heroTitle}
            </Heading>
            <p className={style.sub}>{heroSub && heroSub}</p>
       </Container>
      <ShowReel className={style.reel} thumbnail={thumbnail} video={video} />
    </section>
  );
}

export default HomeChallengesReel;

