// vendor
import React, { useContext, useEffect, useMemo, useRef } from 'react';

// animation
import revealContext from '../../context/revealContext';

// utils
import createDeferredPromise from '../../utils/createDeferredPromise';

// So ...
// The revealContext.Provider is render multiple times
// Once in <Root /> and once in <Page /> (and optionally again when you render a <Group />)
// When navigating and on first load, the one in <Page /> will call the blocking animations
// The one in <Root /> will await this for the first load, so we can have blocking animations on first load
// and <RevealItems /> not in a <Page /> That will wait for those (like <Header />)
function RevealPage({ children }) {
  const context = useContext(revealContext);
  const blockers = useRef([]);
  const blockersPromise = useRef(createDeferredPromise());

  useEffect(() => {
    Promise.all(blockers.current.map(fn => fn())).then(() => {
      if (context.waitForBlocking) {
        blockersPromise.current.resolve();
        if (!context.waitForBlocking().isResolved) {
          context.waitForBlocking().resolve();
        }
      }
    });
  }, []);

  const value = useMemo(
    () => ({
      addBlockingReveal(fn) {
        blockers.current.push(fn);
      },
      waitForBlocking() {
        return blockersPromise.current;
      },
    }),
    []
  );

  return <revealContext.Provider value={value}>{children}</revealContext.Provider>;
}

export default RevealPage;
