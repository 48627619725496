// vendor
import React, { useEffect, useRef, useState } from 'react';
import cc from 'classcat';
import { func, number } from 'prop-types';

// style
import style from './progressButtons.scss';

ProgressButtons.propTypes = {
  count: number.isRequired,
  onChange: func,
};

function ProgressButtons({ count, onChange = () => {}, light, className }) {
  const [activeIndex, setIndex] = useState(0);
  const intervalId = useRef();

  function setNext() {
    let next = setIndex(prev => {
      next = (prev + 1) % count;
      return next;
    });
  }

  function handleClick(i) {
    if (i === activeIndex) {
      return;
    }
    setIndex(i);
  }

  useEffect(() => {
    intervalId.current = setTimeout(setNext, 4000);
    onChange(activeIndex);
    return () => {
      clearTimeout(intervalId.current);
    };
  }, [activeIndex]);

  return (
    count > 1 && (
      <div className={cc([style.buttons, light && style.light, className])}>
        {Array.from(new Array(count)).map((_, i) => (
          <button
            className={cc([style.button, i === activeIndex && style.active])}
            onClick={() => handleClick(i)}
            key={i.toString()}
          >
            {i + 1}
          </button>
        ))}
      </div>
    )
  );
}

export default ProgressButtons;
