// vendor
import React from 'react';
import { oneOf } from 'prop-types';

// components
import ArrowBottom from './arrowBottom';
import ArrowRight from './arrowRight';
import ArrowRightSmall from './arrowRightSmall';
import Award from './award';
import Facebook from './facebook';
import Instagram from './instagram';
import Linkedin from './linkedin';
import MapPointer from './mapPointer';
import Play from './play';
import Twitter from './twitter';
import Vimeo from './vimeo';
import IconPlus from './iconPlus';
import Chevron from './chevron';
import IconMin from './iconMin';

const icons = {
  arrowBottom: ArrowBottom,
  arrowRight: ArrowRight,
  arrowRightSmall: ArrowRightSmall,
  award: Award,
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  mapPointer: MapPointer,
  play: Play,
  twitter: Twitter,
  vimeo: Vimeo,
  iconPlus: IconPlus,
  chevRon: Chevron,
  iconMin: IconMin,
  document: 'div',
};

Icon.propTypes = {
  type: oneOf(Object.keys(icons)).isRequired,
};

function Icon({ type, awards, hovered, ...rest }) {
  const IconComp = icons[type];
  return <IconComp hovered={hovered} awards={awards} {...rest} />;
}

export default Icon;
