import BasicRevealItem from '../../animation/basicRevealItem';
import React from 'react';
import StoriesHeaderItem from '../../sections/storiesHeader/storiesHeaderItem/storiesHeaderItem';
import StoryExpert from '../../storyExpert/storyExpert';
import style from './storiesExpert.scss';

function StoriesExpert({ items }) {
  return (
    <BasicRevealItem className={style.container}>
      <StoriesHeaderItem title="READ FROM OUR EXPERTS" />
      {items.map(item => (
        <StoryExpert item={item} key={item.title} />
      ))}
    </BasicRevealItem>
  );
}

export default StoriesExpert;
