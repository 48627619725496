// vendor
import React from 'react';

// components
import Container from '../../../container/container';
import { Image } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../../../animation/basicRevealItem';

// style
import style from './workHero.scss';

function WorkHero({ data, color }) {
  return (
    <Container className={style.container} mw="medium" padding="large">
      <BasicRevealItem>
        <Image className={style.image} {...data.image} removePlaceholderOnLoad />
        {data.background ? (
          <Image {...data.background} className={style.imageBackground} />
        ) : (
          <div className={style.background} style={{ background: color }} />
        )}
      </BasicRevealItem>
    </Container>
  );
}

export default WorkHero;
