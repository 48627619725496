// vendor
import cc from 'classcat';
import { Image } from 'dpdk-react-library';
import React from 'react';
// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';
import BasicContent from '../../basicContent/basicContent';
// components
import ScrollToTopButton from '../../scrollToTopButton/scrollToTopButton';
import Container from '../../container/container';
import HTML from '../../html/html';
import Link from '../../link';
import ShareTo from '../../shareTo/shareTo';
import Time from '../../time';
import StoriesHero from '../storiesHero/storiesHero';
// style
import style from './storyIntro.scss';
import InternalLink from '../../internalLink';

function StoryIntro({ data, isWhitepaper = false }) {
  const {
    authors,
    intro,
    publishedDate,
    sections,
    title,
    types,
    images,
    summary,
    categories,
  } = data;

  const heroData = {
    title,
    slug: undefined,
    types,
    images,
    summary,
    intro,
    categories,
  };

  return (
    <RevealGroup style={{ backgroundColor: isWhitepaper ? '#FFF' : '#F5F5F5' }}>
      <Container
        className={cc([style.container, isWhitepaper && style.isWhitepaper])}
        classNameInner={style.content}
        padding="large"
        mw="large"
      >
        {!isWhitepaper ? <StoriesHero item={heroData} /> : null}

        {!isWhitepaper && (
          <div className={style.storyMeta}>
            <BasicRevealItem className={cc([style.meta, style.block])}>
              {authors &&
                authors.length > 0 &&
                authors.map(author => (
                  <div className={style.author} key={author.name}>
                    <span className={style.inner}>
                      <span>Written by </span>
                      {author && author.name && author.slug && (
                        <InternalLink className={style.authorName} href={author.slug}>
                          {author.name}
                        </InternalLink>
                      )}
                      <span> on </span>
                      {publishedDate && (
                        <Time timestamp={publishedDate} day month={'long'} year={'numeric'} />
                      )}
                    </span>
                  </div>
                ))}
            </BasicRevealItem>
          </div>
        )}

        <ShareTo title={heroData.title} />

        {intro && (
          <div className={style.introContainer}>
            <BasicRevealItem as="p" className={cc([style.intro, style.block])}>
              {intro.split(/\[([^)]+)\)/gm).map(s => {
                if (s.includes('](')) {
                  let parts = s.split('](');
                  return (
                    <a href={parts[1]} target="_blank" key={s}>
                      {parts[0]}
                    </a>
                  );
                } else {
                  return s;
                }
              })}
            </BasicRevealItem>
          </div>
        )}

        {sections && (
          <BasicContent
            sections={sections}
            padding={isWhitepaper ? 'small' : 'default'}
            isWhitepaper={isWhitepaper}
            className={cc([style.maxContentWidth, style.basicContent])}
          />
        )}

        <BasicRevealItem className={cc([style.intro, style.authorFooterContainer])}>
          <Container padding="default" mw="small" classNameInner={style.authorFooterMaxWidth}>
            {authors &&
              authors.length > 0 &&
              authors.map(author => (
                <div className={style.authorFooter} key={author.name}>
                  {author.photo && (
                    <Link href={author.slug} className={style.imageWrapper}>
                      <Image className={style.image} {...author.photo} width="100%" height="100%" />
                    </Link>
                  )}
                  <span className={style.inner}>
                    <span className={style.subHeadAuthor}>STORY WRITTEN BY </span>
                    {author.name && (
                      <HTML className={style.name} size="small">
                        {author.name}
                      </HTML>
                    )}
                    {author.function && (
                      <HTML className={style.function} size="small">
                        {author.function}
                      </HTML>
                    )}
                  </span>
                </div>
              ))}
          </Container>
        </BasicRevealItem>
      </Container>

      {!isWhitepaper && (
        <BasicRevealItem className={style.toTopBtn} variation="left">
          <ScrollToTopButton />
        </BasicRevealItem>
      )}
    </RevealGroup>
  );
}

export default StoryIntro;
