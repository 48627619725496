// vendor
import React from 'react';
import cc from 'classcat';

// components
import Container from '../../container/container';
import Link from '../../link';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';

// style
import style from './Tabs.scss';

function Tabs({ current = pages[0].type, pages, has1400MaxWidth = false }) {
  return (
    <Container
      className={style.container}
      classNameInner={[style.content, has1400MaxWidth && style.hasLargerMaxWidth]}
      mw="medium"
      padding="small"
    >
      <BasicRevealItem variation="fade" as="nav" className={style.nav}>
        {pages.map((page, i) => (
          <Link
            className={cc([style.anchor, current === page.type && style.active])}
            href={page.href}
            key={i.toString()}
          >
            {page.label}
          </Link>
        ))}
        <span className={style.underline} />
      </BasicRevealItem>
    </Container>
  );
}

export default Tabs;
