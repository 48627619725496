// vendor
import { Image } from 'dpdk-react-library';
import React, { useEffect, useState } from 'react';
// static content
import { useGlossary } from '../../../context/glossaryContext';
// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';
import AutoPlayingVideo from '../../autoPlayingVideo/AutoPlayingVideo';
// components
import Carousel from '../../carousel/carousel';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
// style
import style from './companyTeam.scss';

function CompanyTeam({ carousel }) {
  const { t } = useGlossary();
  const [width, setWidth] = useState(400);

  useEffect(() => {
    window.innerWidth < 800 && setWidth(250);
  }, []);

  return (
    <RevealGroup>
      <Container
        className={style.container}
        classNameInner={style.content}
        mw="large"
        padding="small"
      >
        <div className={style.intro}>
          <BasicRevealItem
            as={Heading}
            variation="left"
            className={style.heading}
            level="h2"
            size="h3"
          >
            {t('company.story.team.heading')}
          </BasicRevealItem>
          <BasicRevealItem as={HTML} variation="left" className={style.body}>
            {t('company.story.team.body')}
          </BasicRevealItem>
        </div>
        {carousel && carousel.length > 0 && (
          <BasicRevealItem>
            <Carousel resizeInputs={[width]}>
              {({ getItemProps }) =>
                carousel.map((item, i) => renderCarouselItem(item, width, getItemProps, i))
              }
            </Carousel>
          </BasicRevealItem>
        )}
      </Container>
    </RevealGroup>
  );
}

function renderCarouselItem(item, width, getItemProps, i) {
  switch (item.type) {
    case 'image':
      return (
        <Image
          {...getItemProps({ className: style.image })}
          onDragStart={e => e.preventDefault()}
          aspectRatio={item.image.dimensions ? item.image.dimensions.width / item.image.dimensions.height : null}
          width={`${width}px`}
          key={i.toString()}
          placeholder={item.image.placeholder}
          srcSet={item.image.srcSet}
          src={item.image.src}
        />
      );
    case 'video':
      return (
        <AutoPlayingVideo
          className={style.video}
          muted
          source={item.upload ? item.upload : item.url}
          style={{ width: `${width}px` }}
          key={i.toString()}
          autoPlay={false}
          disableReveal
        />
      );
    default:
      return null;
  }
}

export default CompanyTeam;
