// vendor
import React, { useState } from 'react';
import cc from 'classcat';
import dlv from 'dlv';

// static content
import { useGlossary } from '../../../../context/glossaryContext';

// components
import Heading from '../../../heading/heading';
import HTML from '../../../html/html';
import { Image } from 'dpdk-react-library';
import Link from '../../../link';
import ReadMore from '../../../readMore/readMore';

// animation
import BasicRevealItem from '../../../animation/basicRevealItem';

// style
import style from './offeringWorkItem.scss';

function OfferingWorkItem({ item }) {
  const { t } = useGlossary();
  const { title, offeringPageTitle, slug, logo, images, mostImportantAward } = item;
  const [hover, setHover] = useState(false);

  return (
    <BasicRevealItem
      as={Link}
      className={cc([style.item, hover && style.hover])}
      href={slug}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <div className={style.preview}>
        {dlv(images, 'square', false) && (
          <Image
            {...images.square}
            className={style.image}
            noWidth
            width={undefined}
            height={undefined}
          />
        )}
      </div>
      <div className={style.info}>
        <div className={style.content}>
          <Image {...logo} className={style.logo} width={40} height={40} />
          <Heading className={style.title} level="h3" size="h4" weight="light" color="black">
            {offeringPageTitle || title}
          </Heading>
          {mostImportantAward && <HTML>{mostImportantAward}</HTML>}
        </div>
        <ReadMore label={t('work.button.label')} color="green" hovered={hover} />
      </div>
    </BasicRevealItem>
  );
}

export default OfferingWorkItem;
