// vendor
import React, { useState, useEffect } from 'react';
import cc from 'classcat';

// components
import Link from '../link';
import Logo from '../logo/logo';
import Navigation from '../navigation/navigation';
import PageProgressBar from './pageProgressBar/pageProgressBar';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './header.scss';

function Header() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';
  });

  return (
    // Won't wait for any blocking reveal animations because it's not inside <Page />
    // The <RevealPage /> in <Page /> could be moved higher upwards in the tree, but then
    // Blocking reveal animations will have no effect when navigating to other pages
    <BasicRevealItem
      as="header"
      className={style.container}
      styles={{ from: { translateY: '-100%' }, to: { translateY: 0 } }}
      animateOnMount
    >
      <div className={style.content}>
        <Link href="/" className={style.anchor} aria-label="DPDK" onClick={() => setOpen(false)}>
          <Logo className={style.logo} type="basic" />
        </Link>
        <button
          className={cc([style.toggle, open && style.close])}
          type="button"
          aria-label="Mobile menu toggle"
          name="Mobile menu toggle"
          onClick={() => setOpen(!open)}
        >
          <span className={style.bar} />
        </button>
        <Navigation className={style.navigation} open={open} setOpen={setOpen} />
      </div>
      <PageProgressBar />
    </BasicRevealItem>
  );
}

export default Header;
