// vendor
import React from 'react';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import CareersBulletPoints from '../careersBulletPoints/careersBulletPoints';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './careersBenefits.scss';

function CareersBenefits({ data, noPaddingBottom = false }) {
  const { pageTitle, image, secondImage, thirdImage, content } = data;

  return (
    <RevealGroup>
      <Container
        className={[style.container, noPaddingBottom && style.noPaddingBottom]}
        classNameInner={style.inner}
        mw="large"
        padding="small"
      >
        {pageTitle && (
          <Heading level="h1" size="h2" weight="light" align="left">
            {pageTitle}
          </Heading>
        )}
      </Container>

      <Container
        className={[style.container, noPaddingBottom && style.noPaddingBottom]}
        classNameInner={style.inner}
        mw="large"
        padding="small"
      >
        <div className={style.media}>
          {image && (
            <BasicRevealItem>
              <Image {...image} className={style.image} />
            </BasicRevealItem>
          )}
          {secondImage && (
            <BasicRevealItem>
              <Image {...secondImage} className={style.image} />
            </BasicRevealItem>
          )}
          {thirdImage && (
            <BasicRevealItem>
              <Image {...thirdImage} className={style.image} />
            </BasicRevealItem>
          )}
        </div>
        <div className={style.content}>
          {content &&
            content.length > 0 &&
            content.map((item, i) => (
              <BasicRevealItem className={style.section} key={i.toString()}>
                {item.title && (
                  <Heading level="h3" size="h5" weight="semibold">
                    {item.title}
                  </Heading>
                )}
                <HTML className={style.body}>{item.body}</HTML>
                { item.bullets.filter((bullet) => !!bullet.trim()).length > 0 && <CareersBulletPoints bullets={item.bullets} />}
                
              </BasicRevealItem>
            ))}
        </div>
      </Container>
    </RevealGroup>
  );
}

export default CareersBenefits;
