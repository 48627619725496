import React from 'react';
import Icon from '../icon/icon';
import style from './socialLink.scss';

function SocialLink({ item }) {
  return (
    <a
      className={style.anchor}
      href={item.href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`Navigate to author's ${item.label} page`}
    >
      <Icon className={style.icon} type={item.icon} />
    </a>
  );
}

export default SocialLink;
