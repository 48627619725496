let fetch = global.fetch;
if (__SSR__) {
  fetch = require('node-fetch').default || require('node-fetch');
}

function request(url, params, meth = 'get') {
  if (__SSR__ && !url.match(/^(https?:)?\/\//)) {
    // eslint-disable-next-line
    url = `http://127.0.0.1:3003${url}`;
  }

  const method = meth.toUpperCase();
  const body = JSON.stringify(params); // params instanceof FormData ? formDataToJSON(params) :

  // for the credentials param see :
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Access-Control-Allow-Credentials
  // https://fetch.spec.whatwg.org/#concept-request-credentials-mode
  const options = {
    method,
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  };
  return fetch(url, options)
    .then(responseStatus)
    .then(responseJSON);
}

function get(url, params) {
  const urlWithParams = `${url}${params ? createUrlQuery(params) : ''}`;
  return request(urlWithParams, undefined, undefined);
}

function responseStatus(response) {
  if (response.ok) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  return Promise.reject(error);
}

function responseJSON(response) {
  return response.json();
}

function createUrlQuery(params = {}) {
  const esc = encodeURIComponent;
  const joined = Object.keys(params)
    .map(k => `${esc(k)}=${esc(params[k])}`)
    .join('&');
  return `?${joined}`;
}

function formDataToJSON(formData) {
  const json = {};
  formData.forEach((value, key) => (json[key] = value));
  return JSON.stringify(json);
}

function getFormData(form) {
  const data = {};

  if (!form) return data;

  Array.from(form.elements).forEach(elm => {
    if (!elm.name || elm.disabled || elm.type === 'submit' || elm.type === 'button') return;

    if (elm.type === 'file') {
      (elm.files || []).forEach(file => {
        data[elm.name] = file;
      });
    } else if (elm.type === 'select-multiple' || elm.type === 'select-one') {
      elm.options.forEach(opt => {
        if (!opt.disabled && opt.selected) data[elm.name] = opt.value;
      });
    } else if (elm.type === 'checkbox' || elm.type === 'radio') {
      if (elm.checked) data[elm.name] = elm.value;
    } else {
      const value = elm.type === 'textarea' ? normalizeLinefeeds(elm.value) : elm.value;
      data[elm.name] = value;
    }
  });

  return data;
}

// normalize linefeeds for textareas
// https://html.spec.whatwg.org/multipage/form-elements.html#textarea-line-break-normalisation-transformation
function normalizeLinefeeds(value) {
  return value.replace(/\r\n/g, '\n').replace(/\n/g, '\r\n');
}

export { responseStatus, createUrlQuery, get, request, formDataToJSON, getFormData };
