// vendor
import React, { useState } from 'react';
import cc from 'classcat';

// components
import Button from '../button/button';
import Container from '../container/container';
import Heading from '../heading/heading';
import ImageSwitcher from '../imageSwitcher/imageSwitcher';
import Link from '../link';
import ProgressButtons from '../progressButtons/progressButtons';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './challengeSelection.scss';

function ChallengeSelection({ items, className }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hover, setHover] = useState(false);
  if (items[activeIndex] === null) return null;

  const image = items[activeIndex]?.thumbnail || items[activeIndex]?.image[0];

  return (
    <div className={className} stylvae={{ position: 'relative' }}>
      <BasicRevealItem>
        <Container classNameInner={style.container} padding="small">
          {items[activeIndex] && (
            <ImageSwitcher
              alt={image.alt}
              src={image.placeholder}
              {...image}
              width={undefined}
              height="inherit"
              className={style.image}
            />
          )}
          <div className={style.cardContainer}>
            <ProgressButtons
              className={style.buttons}
              count={items.length}
              onChange={setActiveIndex}
            />
            <div className={cc([style.cardContainerInner])}>
              {items &&
                items.map((item, i) => {
                  let slug =
                    item.linkSlug ||
                    (item.link && item.link.href !== null && item.link.href) ||
                    '/';
                  const type = item.link?.entityReference?.type;

                  if (type == 'page_approach') {
                    slug = '/approach';
                  }
                  if (type == 'story') {
                    const splittedSLug = slug.split('/');
                    const newSlug = `/stories/${splittedSLug[2]}`;
                    slug = newSlug;
                  }

                  return (
                    item && (
                      <Link
                        className={cc([style.card, i === activeIndex && style.active])}
                        href={slug}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        key={i.toString()}
                      >
                        <Heading level="h2">{item.title}</Heading>
                        <Button className={style.button} hovered={hover}>
                          {item.linkText || item.linkLabel}
                        </Button>
                      </Link>
                    )
                  );
                })}
            </div>
          </div>
        </Container>
      </BasicRevealItem>
    </div>
  );
}

export default ChallengeSelection;
