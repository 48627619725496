// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Button from '../../button/button';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';

// style
import style from './notFoundIntro.scss';

function NotFoundIntro() {
  const { t } = useGlossary();

  return (
    <Container mw="small" padding="small" className={style.container}>
      <div className={style.videoContainer}>
        <video className={style.video} src="/media/videos/404.mp4" muted loop autoPlay />
        <div className={style.content}>
          <Heading level="h2" size="h4" align="center">
            {t('404.heading')}
          </Heading>
          <HTML className={style.text}>{t('404.body')}</HTML>
          <Button href="/" icon="none" boxed>
            {t('404.button.label')}
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default NotFoundIntro;
