// vendor
import React from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import ContactCard from '../../contactCard/contactCard';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import PartnerGrid from '../../partnerGrid/partnerGrid';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';
import RevealGroup from '../../animation/revealGroup';

// style
import style from './capabilityContent.scss';

function CapabilityContent({ items, partners, contact, title, contactCaption }) {
  const { t } = useGlossary();

  return (
    <RevealGroup>
      <Container
        className={style.container}
        classNameInner={style.inner}
        mw="medium"
        padding="small"
      >
        <div className={style.content}>
          {items.map((item, i) => (
            <div className={style.unit} key={i.toString()}>
              {item.heading && (
                <BasicRevealItem as={Heading} level="h3" size="h5" weight="semibold">
                  {item.heading}
                </BasicRevealItem>
              )}
              {item.text && (
                <BasicRevealItem as={HTML} className={style.body}>
                  {item.text}
                </BasicRevealItem>
              )}
            </div>
          ))}

          {partners && partners.length > 0 && (
            <BasicRevealItem>
              <Heading className={style.heading} level="h3" size="h5" weight="semibold">
                {t('capability.partners.heading')}
              </Heading>
              <div className={style.partners}>
                <PartnerGrid items={partners} />
              </div>
            </BasicRevealItem>
          )}
        </div>
        {contact && (
          <ContactCard
            className={style.card}
            contact={contact}
            title={title}
            body={contactCaption}
          />
        )}
      </Container>
    </RevealGroup>
  );
}

export default CapabilityContent;
