import { useContext } from 'react';
import dataContext from '../context/dataContext';

function useData(dataKey) {
  const dataState = useContext(dataContext);
  const data = dataState.data[dataKey];
  const pending = dataState.pending[dataKey];
  const error = dataState.failed[dataKey];

  return { data, pending, error };
}

export default useData;
