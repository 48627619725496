// vendor
import React, { useState, useEffect } from 'react';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// components
import Button from '../../button/button';
import ContactCard from '../../contactCard/contactCard';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import { Image } from 'dpdk-react-library';

// utils
import scroll from '../../../utils/scroll';
import getFirstName from '../../../utils/getFirstName';

// animation
import BasicRevealItem from '../../animation/basicRevealItem';

// style
import style from './offeringIntro.scss';
import AutoPlayingVideo from '../../autoPlayingVideo/AutoPlayingVideo';
import dlv from 'dlv';

function OfferingIntro({ data }) {
  const {
    title,
    subtitle,
    headerImage,
    headerVideo = {},
    headerImageCaption,
    introQuote,
    introText,
    contactPerson,
  } = data;
  const { t } = useGlossary();
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    window.innerWidth < 800 && setMobile(true);
  }, []);

  return (
    <>
      <Container
        mw="medium"
        padding="small"
        className={style.topbar}
        classNameInner={style.topbarInner}
      >
        {title && !mobile && (
          <BasicRevealItem variation="fade" as={HTML} className={style.title}>
            {title}
          </BasicRevealItem>
        )}
        <BasicRevealItem variation="fade" as={HTML} className={style.question}>
          {t('offering.header.question')}
        </BasicRevealItem>
        <Button onClick={() => scroll('.js-section-contact')} icon="arrowBottom" boxed={!mobile}>
          {t('offering.button.contact.label', getFirstName(contactPerson.name))}
        </Button>
      </Container>

      <Container mw="medium" padding="small" className={style.wrapper} classNameInner={style.hero}>
        {title && (
          <BasicRevealItem variation="left" as={Heading} className={style.title}>
            {title}
          </BasicRevealItem>
        )}
        {subtitle && (
          <BasicRevealItem
            variation="left"
            as={Heading}
            className={style.subtitle}
            level="h4"
            size="h5"
          >
            {subtitle}
          </BasicRevealItem>
        )}
        {headerImage &&
          dlv(data, 'headerVideo.upload', null) === null &&
          dlv(data, 'headerVideo.url', null) === null && (
            <BasicRevealItem className={style.image}>
              <Image {...headerImage} />
            </BasicRevealItem>
          )}
        {dlv(headerVideo, 'upload', null) !== null && (
          <BasicRevealItem className={style.image}>
            <AutoPlayingVideo muted source={headerVideo.upload} />
          </BasicRevealItem>
        )}
        {dlv(headerVideo, 'url', null) !== null && (
          <BasicRevealItem className={style.image}>
            <AutoPlayingVideo muted source={headerVideo.url} />
          </BasicRevealItem>
        )}
        {headerImageCaption && (
          <BasicRevealItem as={HTML} className={style.caption}>
            {headerImageCaption}
          </BasicRevealItem>
        )}

        <div className={style.intro}>
          <div className={style.content}>
            {introQuote && (
              <HTML className={style.quote} as="blockquote">
                {introQuote}
              </HTML>
            )}
            {introText && <HTML className={style.text}>{introText}</HTML>}
          </div>
          <ContactCard
            className={style.card}
            contact={contactPerson}
            title={title}
            body={data.contactPersonCta1}
          />
        </div>
      </Container>
    </>
  );
}

export default OfferingIntro;
