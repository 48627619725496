// vendor
import React from 'react';
import cc from 'classcat';

// components
import { Image } from 'dpdk-react-library';
import Transition from 'react-transition-group/Transition';
import TransitionGroup from 'react-transition-group/TransitionGroup';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './imageSwitcher.scss';

function ImageSwitcher({ src, srcSet, className, classNameImage, ...imageProps }) {
  return (
    <BasicRevealItem className={cc([style.switcher, className])}>
      <TransitionGroup appear>
        {(src || srcSet) && (
          <Transition
            timeout={500}
            key={src || (typeof srcSet === 'string' ? srcSet : Object.values(srcSet)[0])}
          >
            {state => (
              <div className={cc([style.image, style[`is-${state}`]])}>
                <Image className={classNameImage} src={src} srcSet={srcSet} {...imageProps} />
              </div>
            )}
          </Transition>
        )}
      </TransitionGroup>
    </BasicRevealItem>
  );
}

export default ImageSwitcher;
