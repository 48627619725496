import React from 'react';
import { Image } from 'dpdk-react-library';
import style from './officeLocation.scss';
import Heading from '../../heading/heading';
import Button from '../../button/button';
import Icon from '../../icon/icon';
import Container from '../../container/container';
import cc from 'classcat';

const OfficeLocation = props => {
  return (
    <Container padding="small" className={cc(style.outer__wrapper)}>
      <div className={style.location__container}>
        {props.locationProps.directions_image && (
          <div className={style.location__map}>
            <Image
              width="100%"
              height="100%"
              className={style.map__image}
              {...props.locationProps.directions_image}
            />
            <a
              className={style.map__icon}
              href={props.locationProps.directions_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon width="100%" height="100%" fill="#2fd0b7" type="mapPointer" />
            </a>
          </div>
        )}
        <div className={style.location__directions}>
          <Heading level="h3" size="h3" weight="thin" className={cc(style.directions__heading)}>
            {props.locationProps.directions_title}
          </Heading>
          <div dangerouslySetInnerHTML={{ __html: props.locationProps.directions_desc }} />
          <a href={props.locationProps.directions_link} target="_blank" rel="noopener noreferrer">
            <Button className={style.directions__button}>
              {props.locationProps.directions_cta_link
                ? props.locationProps.directions_cta_link
                : 'Get directions'}
            </Button>
          </a>
        </div>
      </div>
    </Container>
  );
};

export default OfficeLocation;
