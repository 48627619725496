import React, { forwardRef } from 'react';
import style from './contentSlider.scss';
import Heading from '../../heading/heading';
import Icon from '../../icon/icon';
import Video from '../../video/video';
import Container from '../../container/container';
import ImageWithOverlay from './imageWithOverlay';
import WorkGridItem from '../../workGrid/workGridItem/workGridItem';
import cc from 'classcat';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use(Navigation);

const ContentSlider = forwardRef((props, ref) => {
  return (
    props.sliderContentArray &&
    props.sliderContentArray.length !== 0 && (
      <Container
        padding="small"
        className={cc([style.outer__wrapper, props.whiteBackground && style['wrapper--white']])}
      >
        <div className={style.slider__container} ref={ref}>
          {props.sliderTitle && (
            <Heading level="h2" size="h2" weight="thin">
              {props.sliderTitle}
            </Heading>
          )}
          {props.sliderSubtitle && (
            <div
              className={cc(style.slider__subheader)}
              dangerouslySetInnerHTML={{ __html: props.sliderSubtitle }}
            />
          )}
          <Swiper
            spaceBetween={60}
            breakpoints={props.slidesPerViewBreakpoints}
            navigation={{
              nextEl: '.button__next',
              prevEl: '.button__prev',
            }}
            className={cc(style.content__slider)}
            onSwiper={swiper => setTimeout(() => swiper.update())}
          >
            {props.sliderContentArray.map((contentPiece, i) => {
              const Comp =
                contentPiece.type === 'image'
                  ? ImageWithOverlay
                  : contentPiece.type === 'video'
                  ? Video
                  : contentPiece.client
                  ? WorkGridItem
                  : 'div';
              const contentProps =
                contentPiece.type === 'image'
                  ? {
                      imageProps: {
                        ...contentPiece.image,
                      },
                      ...(contentPiece.overlay && {
                        overlayProps: {
                          overlay_label: contentPiece.overlay_label,
                          overlay_title: contentPiece.overlay_title,
                          overlay_subtitle: contentPiece.overlay_subtitle,
                        },
                      }),
                    }
                  : contentPiece.type === 'video'
                  ? {
                      source: contentPiece.video_url,
                      disableReveal: true,
                      playsInline: true,
                      placeholder:
                        'https://dpdk.com/static/assets/380ef3ae074a5ebcf4479cc0c93ab858-1920.jpg',
                    }
                  : {
                      item: { ...contentPiece, highlighted: false },
                      workDetailAnimation: false,
                    };
              return (
                <SwiperSlide
                  className={cc([
                    style.content__piece,
                    contentPiece.type === 'image' && style.content__image,
                    contentPiece.type === 'video' && style.content__video,
                  ])}
                  key={i}
                >
                  <Comp {...contentProps} />
                </SwiperSlide>
              );
            })}
            <div className={style.slider__navigation}>
              <Icon fill="transparent" type="arrowRight" className="button__prev" />
              <Icon fill="transparent" type="arrowRight" className="button__next" />
            </div>
          </Swiper>
        </div>
      </Container>
    )
  );
});

export default ContentSlider;
