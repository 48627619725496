import React from 'react';

function IconPlus({ ...props }) {
  return (
      <svg
        {...props}
        viewBox="0 0 341.4 341.4"
      >
        <polygon points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192
          341.4,149.4"/>
    </svg>
  );
}

export default IconPlus;
