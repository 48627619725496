// vendor
import React, { useEffect, useLayoutEffect, useContext } from 'react';
// import { gtmContext } from 'dpdk-react-library';

// components
import Footer from '../components/footer/footer';
import RevealPage from './animation/revealPage';
import SEO from './seo';

// utils
import emitter from '../utils/emitter';

function Page({ children, newsletterHidden, SEOProps }) {
  // const { trackPageView } = useContext(gtmContext);
  // Don't call hook on the server to avoid pointless warnings
  if (typeof window !== 'undefined') {
    // useLayoutEffect is fine here, even with SSR.
    // It doesn't effect the rendered DOM.
    // Using useEffect here would cause glitchiness with page transitions.
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    //#region hubspot integration
    window.addEventListener('message', function(event) {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        window.dataLayer.push({
          event: 'hubspot-form-success',
          'hs-form-guid': event.data.id,
        });
      }
    });
    //#endregion hubspot integration
  }

  let pagePathUrl =
    typeof window !== 'undefined' && window.location.pathname ? window.location.pathname : '';

  useEffect(() => {
    emitter.emit('pageChange');
    if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: 'Pageview',
        url: pagePathUrl,
      });
      window.uetq = window.uetq || [];
      window.uetq.push('event', 'page_view', { page_path: pagePathUrl });
    }
  }, [pagePathUrl]);

  return (
    <RevealPage>
      <div className="page">
        <SEO {...SEOProps} />
        <main className="main">{children}</main>
        <Footer newsletterHidden={newsletterHidden} />
      </div>
    </RevealPage>
  );
}

export default Page;
