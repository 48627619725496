import React, { createContext, createElement, useContext, useMemo } from 'react';
import en from '../lang/en.json';
import { formatTime } from '../utils/formatTime';
import { t } from '../utils/language';

const translations = {
  en,
};

const glossaryContext = createContext({});
const { Provider, Consumer } = glossaryContext;

function GlossaryProvider({ language = 'en', children }) {
  const value = useMemo(() => {
    const terms = translations[language];
    return { language, t: t.bind(null, terms), formatTime: formatTime.bind(null, language) };
  }, [language]);

  return <Provider value={value}>{children}</Provider>;
}

function T({ id, values = [] }) {
  return <Consumer>{({ t }) => createElement('span', {}, ...t(id, ...values))}</Consumer>;
}

function useGlossary() {
  return useContext(glossaryContext);
}

export default glossaryContext;
export { T, GlossaryProvider, useGlossary };
