// vendor
import React from 'react';
import cc from 'classcat';

// static content
import { useGlossary } from '../../context/glossaryContext';
import staticImage from '../../../public/media/images/challenges-intro.jpg';

// components
import Container from '../container/container';
import Heading from '../heading/heading';
import HTML from '../html/html';
import Icon from '../icon/icon';
import { Image } from 'dpdk-react-library';
import Link from '../link';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './offeringBlock.scss';

function OfferingBlock({ offerings, className, title, paragraph, dynamicImage }) {
  const { t } = useGlossary();
  const image = dynamicImage ? dynamicImage : staticImage;

  return (
    <Container
      className={cc([style.offering, className])}
      classNameInner={style.inner}
      padding="small"
      spaced
    >
      <div className={style.content}>
        <Heading level="h2" size="h3">
          {title ? title : t('offering.block.heading')}
        </Heading>
        <HTML className={style.body}>{paragraph ? paragraph : t('offering.block.body')}</HTML>

        <div className={style.imageWrapper}>
          <Image {...dynamicImage} width="100%" height="100%" />
        </div>
      </div>
      <ul className={style.list}>
        {offerings.map(
          (anchor, i) =>
            anchor?.hideOnOverview || (
              <BasicRevealItem as="li" className={style.item} key={i.toString()}>
                <Link href={anchor.slug} className={style.link}>
                  {anchor.title}
                  <Icon className={style.icon} type="arrowRightSmall" />
                </Link>
              </BasicRevealItem>
            )
        )}
      </ul>
    </Container>
  );
}

export default OfferingBlock;
