// vendor
import React from 'react';
import cc from 'classcat';
import { bool, oneOf, string } from 'prop-types';

// style
import style from './container.scss';

Container.propTypes = {
  as: string,
  padding: oneOf(['default', 'small', 'large']),
  mw: oneOf(['small', 'medium', 'large']),
  spaced: bool,
  className: string,
  classNameInner: string,
};

function Container({
  children,
  as,
  innerAs: InnerComp = 'div',
  padding = 'default',
  mw = 'large',
  spaced,
  className,
  classNameInner,
  innerRef,
  relative = false,
  innerRelative = false,
}) {
  const Comp = as || 'section';

  return (
    <Comp
      className={cc([
        style.container,
        style[`padding-${padding}`],
        spaced && style.spaced,
        className,
        relative && style.relative,
      ])}
      ref={innerRef}
    >
      <InnerComp
        className={cc([
          style.content,
          style[`mw-${mw}`],
          classNameInner,
          innerRelative && style.innerRelative,
        ])}
      >
        {children}
      </InnerComp>
    </Comp>
  );
}

export default React.forwardRef((props, ref) => <Container {...props} innerRef={ref} />);
