// vendor
import React, { memo, useState } from 'react';

import Page from '../../components/page';
import StoriesHeader from '../../components/sections/storiesHeader/storiesHeader';
// components
import ContactBanner from '../../components/contactBanner/contactBanner';
import StoriesButtons from '../../components/sections/storiesButtons/storiesButtons';
import StoriesFeatured from '../../components/sections/storiesFeatured/storiesFeatured';
import StoriesGrid from '../../components/storiesGrid/storiesGrid';
// utils
import { get } from '../../utils/request';
// static content
import Tabs from '../../components/sections/Tabs/Tabs';
import StoryWhitepaper from '../../components/sections/storyWhitepaper/storyWhitepaper';

import style from './whitepaper.scss';

const LIMIT = 4;

const WhitePapers = memo(({ data }) => {
  const [skip, setSkip] = useState(4);
  const [fetchedWhitepapers, setFetchedWhitepapers] = useState([]);

  const navLabels = data.navigationLabels || [];
  const title = data.whitepapers_title || null;
  const contactCaption = data.contactCaption || null;
  const contactPerson = data.contact || null;
  const whitepaper = data.featured_whitepaper || null;
  const items = data.items || [];
  const itemsTotal = data.itemsTotal || 0;
  const loadedTotal = items.length + fetchedWhitepapers.length;

  const pages = [
    {
      href: '/insights',
      label: 'Featured',
      type: 'featured',
    },
    {
      href: '/stories',
      label: 'Stories',
      type: 'stories',
    },
    {
      href: '/whitepapers',
      label: 'Downloads',
      type: 'whitepapers',
    },
  ];

  return (
    <Page newsletterHidden={true}>
      <Tabs current="whitepapers" labels={navLabels} pages={pages} has1400MaxWidth />
      <StoriesHeader mainHeader={title} />

      <div className={style.desktopView}>
        <StoriesFeatured>
          <StoryWhitepaper whitepaper={whitepaper} />
        </StoriesFeatured>
      </div>

      <StoriesFeatured>
        <div className={style.whitepaperGrid}>
          <StoriesGrid items={items.concat(fetchedWhitepapers)} variation="whitepaper" />
        </div>
      </StoriesFeatured>

      {itemsTotal > loadedTotal && (
        <StoriesButtons
          loadMore={async () => {
            //up the skip in state by limit
            setSkip(skip + LIMIT);
            //fetch results from api
            const result = await get(`/api/insights?type=whitepapers&limit=${LIMIT}&skip=${skip}`);

            //combine the fetched results from request & state
            setFetchedWhitepapers(prev => prev.concat(result.items));
          }}
        />
      )}

      {itemsTotal <= loadedTotal && <div style={{ margin: '25px 0' }}>&nbsp;</div>}

      {contactPerson && (
        <ContactBanner
          caption={contactCaption ? contactCaption : contactPerson.contactCaption}
          title={contactPerson.contactFormTitle}
          person={contactPerson}
          hubspotFormId={contactPerson.hubspotFormId}
          noMarginBottom
        />
      )}
    </Page>
  );
});

WhitePapers.getPageData = (match, env, location) => {
  return get(`/api/page/whitepaper`);
};

export default WhitePapers;
