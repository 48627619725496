// vendor
import React from 'react';

// components
import Heading from '../heading/heading';

// style
import style from './basicVideo.scss';

function BasicVideo({ heading, youtube, vimeo, url, upload, lazyLoad = false }) {
  return (
    <>
      {heading && (
        <Heading className={style.heading} level="h3" size="h5" weight="semibold">
          {heading}
        </Heading>
      )}
      <div className={style.wrapper}>
        {youtube && (
          <iframe
            width="560"
            height="349"
            src={`https://www.youtube.com/embed/${youtube}`}
            frameBorder="0"
            allowFullScreen
            title="video-embed"
            loading={lazyLoad ? "lazy" : undefined}
          />
        )}
        {vimeo && (
          <iframe
            src={`https://player.vimeo.com/video/${vimeo}`}
            width="560"
            height="349"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title="video-embed"
            loading={lazyLoad ? "lazy" : undefined}
          />
        )}
        {upload && <video className={style.video} src={upload} controls playsInline />}
      </div>
    </>
  );
}

export default BasicVideo;
