// vendor
import dlv from 'dlv';
import { number, oneOf, shape, string } from 'prop-types';
import React, { memo, useContext } from 'react';
import Helmet from 'react-helmet';
import config from '../config';
import domainContext from '../context/domainContext';
import locationContext from '../context/locationContext';
import usePageData from '../hooks/usePageData';

SEO.propTypes = {
  title: string,
  type: oneOf(['website', 'article']),
  description: string,
  shareImage: shape({
    original: string,
    src: string,
    width: number.isRequired,
    height: number.isRequired,
  }),
};

function SEO({
  type = 'website',
  title: titleProp,
  description: descriptionProp,
  shareImage: shareImageProp,
}) {
  const { domain: domainData } = useContext(domainContext);
  const { data: pageData } = usePageData();
  const location = useContext(locationContext);
  const title = `${titleProp ||
    dlv(pageData, 'seo.title') ||
    dlv(pageData, 'title') ||
    dlv(domainData, 'seo.title') ||
    config.defaultPageTitle} - ${config.pageTitleSuffix}`;
  const desc =
    descriptionProp || dlv(pageData, 'seo.description') || dlv(domainData, 'seo.description');
  const shareImage =
    shareImageProp || dlv(pageData, 'seo.shareImage') || dlv(domainData, 'seo.shareImage');

  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: config.language }}
      meta={[
        { name: 'description', content: desc },
        { property: 'og:title', content: title },
        { property: 'og:type', content: type },
        { property: 'og:description', content: desc },
        shareImage && {
          property: 'og:image',
          content: `https://${location.host}${shareImage.original || shareImage.src}`,
        },
        shareImage && {
          property: 'og:image:width',
          content: shareImage.dimensions ? shareImage.dimensions.width : shareImage.width,
        },
        shareImage && {
          property: 'og:image:height',
          content: shareImage.dimensions ? shareImage.dimensions.height : shareImage.height,
        },
        { name: 'twitter:card', content: 'summary_large_image' },
      ].filter(Boolean)}
    />
  );
}

export default memo(SEO);
