// vendor
import React, { useRef } from 'react';
// animation
import BasicRevealItem from '../../../animation/basicRevealItem';
// components
import Heading from '../../../heading/heading';
import HTML from '../../../html/html';
import Hubspot from '../../hubspot/Hubspot';
// style
import style from './contactBusiness.scss';

function ContactBusiness({ data }) {
  const title = data.title;
  const caption = data.caption;
  const formId = data.formId;
  const heading = useRef(null);

  return (
    <div ref={heading}>
      {title && (
        <BasicRevealItem variation="left">
          <Heading className={style.title} size="h3" level="h3" weight="light">
            {title}
          </Heading>
        </BasicRevealItem>
      )}
      {caption && (
        <BasicRevealItem variation="left" as={HTML} className={style.text}>
          {caption}
        </BasicRevealItem>
      )}
      {formId && (
        <BasicRevealItem>
          <Hubspot
            appendId={'contact-business'}
            className={style.businessHubspotForm}
            portalId="6265534"
            formId={formId}
          />
        </BasicRevealItem>
      )}
    </div>
  );
}

export default ContactBusiness;
