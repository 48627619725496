// vendor
import React, { useContext, useRef, useState, useCallback, useEffect } from 'react';
import dlv from 'dlv';
import cc from 'classcat';

// static content
import { useGlossary } from '../../../context/glossaryContext';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// component
import Button from '../../button/button';
import Form from '../../form/form';
import { gtmContext } from 'dpdk-react-library';
import InputText from '../../form/inputText/inputText';

// style
import style from './newsletterForm.scss';
import DomainContext from "../../../context/domainContext";
import Html from '../../html/html';

function NewsletterForm() {
  const { t } = useGlossary();
  const { trackCustomEvent } = useContext(gtmContext);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [recaptchatoken, setRecaptchatoken] = useState(false);
  const input = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const data = useContext(DomainContext);

  const hubspotId = dlv(data, 'signUp.hubspotId', undefined);

  const handleReCaptchaVerify = useCallback(async () => {

    //checks if recaptcha is available
    if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
    }

    //await to get new token for BE to validate if the user is a bot
    await executeRecaptcha('submit').then(token => {
      setRecaptchatoken(token)
    })

}, [executeRecaptcha]);

// use useEffect to trigger the verification as soon as the component being loaded
useEffect(() => {
    handleReCaptchaVerify();
}, [handleReCaptchaVerify]);


  function validate(data) {
    const fields = Object.keys(data);
    const errors = [];
    for (let i = 0; i < fields.length; i += 1) {
      const field = fields[i];
      const value = data[field];
      if (value === '') errors.push(field);
    }
    
    if(!hubspotId) {
        errors.push("Hubspot id is not available");
    }

    return errors;
  }

  async function onSubmit(data) {
    await handleReCaptchaVerify()

    const errors = validate(data);
    data.hubspotId = hubspotId;

    if (errors.length >= 1) {
      setErrors(errors);
      return;
    }

    fetch('/api/mail', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(response => {
        onSucces(response)
      })
      .catch(error => {
        setErrors([error])
      });
  }

  function onSucces(response) {
    setSuccess(true);
    if(response.error) {
      setErrors(response?.error);
    } else {
      __C = response._csrfToken;
      trackCustomEvent('NewsletterSubscribe');
    }
    setTimeout(() => {
      input.current.value = '';
    }, 400);
    setTimeout(() => {
      setSuccess(false);
      setErrors([]);
    }, 2000);
  }

  return (
    <div className={cc([style.newsletterForm, success && style.success])}>
      <Form onSubmit={onSubmit} method="post" action="/success/mail" id="newsletterForm">
        <div className={style.content}>
          <Html className={style.paragraph} color = 'white'>{dlv(data, 'signUp.signUpDescription') }</Html>
          <InputText
            innerRef={input}
            className={cc([style.label, errors.indexOf('email') !== -1 && style.error])}
            inputClassName={style.input}
            name="email"
            type="email"
            placeholder={t('footer.newsletter.placeholder')}
          />
          <input type="hidden" name="token" value={recaptchatoken}/>
          <Button
            className={cc([style.submit, !recaptchatoken && style.disabled ])}
            id="newsletterFormSubmit"
            disabled={recaptchatoken ? false : true}
          >
              {dlv(data, 'signUp.signUpTitle')}
          </Button>
        </div>
        <div className={cc([style.messageContainer, errors?.length !== 0 && style.errorContainer])} />
        <p className={style.message}>
          {errors?.length !== 0 ? errors : t('footer.newsletter.success')}
        </p>
      </Form>
    </div>
  );
}

export default NewsletterForm;
