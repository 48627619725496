import React, { memo, useState } from 'react';

import ContactBanner from '../../components/contactBanner/contactBanner';
import Page from '../../components/page';
import Link from '../../components/link';
import Icon from '../../components/icon/icon';
import StoriesChips from '../../components/sections/storiesChips/storiesChips';
import StoriesButtons from '../../components/sections/storiesButtons/storiesButtons';
import StoriesExpert from '../../components/sections/storiesExpert/storiesExpert';
import StoriesFeatured from '../../components/sections/storiesFeatured/storiesFeatured';
import StoriesGrid from '../../components/storiesGrid/storiesGrid';
import StoriesHeader from '../../components/sections/storiesHeader/storiesHeader';
import StoriesHeaderItem from '../../components/sections/storiesHeader/storiesHeaderItem/storiesHeaderItem';
import StoriesMost from '../../components/sections/storiesMost/storiesMost';
import dlv from 'dlv';
import { get } from '../../utils/request';
import style from './storyCategories.scss';
import cc from 'classcat';
import BasicRevealItem from '../../components/animation/basicRevealItem';

const LIMIT = 9;

const StoryCategories = memo(({ data }) => {
  const [skip, setSkip] = useState(9);
  const [fetchedStories, setFetchedStories] = useState([]);

  const stories = dlv(data, 'items', []);
  const featuredStories = dlv(data, 'featuredItems', []);
  const category = dlv(data, 'category', '');
  const categoryName = dlv(data, 'category.title', '');
  const categoryId = dlv(data, 'category.id', '');
  const categories = data.categories || [];
  const contactCaption = dlv(category, 'contactCaption', null);
  const contactPerson = dlv(category, 'contactPerson', null);
  const itemsTotal = data.itemsTotal || 0;
  const loadedTotal = stories.length + fetchedStories.length;

  return (
    <Page newsletterHidden={true} className={style.page}>
      <StoriesFeatured>
        <BasicRevealItem variation="right" className={style.backButtonContainer}>
          <Link className={cc([style.backButton])} href="/stories">
            <Icon className={style.icon} type="chevRon" />
            To Overview
          </Link>
        </BasicRevealItem>
      </StoriesFeatured>

      <StoriesHeader mainHeader={categoryName} />

      {stories && (
        <>
          <StoriesFeatured>
            <StoriesHeaderItem title="JUMP TO" />
            <StoriesChips
              categories={categories}
              selected={category.categorySlug}
              whiteBackground={true}
            />
            <StoriesGrid items={stories.concat(fetchedStories)} variation="wide" />
          </StoriesFeatured>
        </>
      )}

      {itemsTotal > loadedTotal && (
        <StoriesButtons
          loadMore={async () => {
            //up the skip in state by limit
            setSkip(skip + LIMIT);
            //fetch results from api
            const result = await get(
              `/api/insights?type=stories&limit=${LIMIT}&skip=${skip}&category=${categoryId}`
            );
            //combine the fetched results from request & state
            setFetchedStories(prev => prev.concat(result.items));
          }}
        />
      )}

      {itemsTotal <= loadedTotal && <div style={{ margin: '25px 0' }}>&nbsp;</div>}

      {featuredStories.length > 0 && (
        <div className={style.mostExpertContainer}>
          <StoriesFeatured storiesHomePage={false}>
            <div className={style.innerContainer}>
              <StoriesMost
                items={featuredStories.slice(0, 3)}
                storiesHomePage={false}
                title="MOST READ STORIES"
              />
              <StoriesExpert items={featuredStories.slice(3, 6)} />
            </div>
          </StoriesFeatured>
        </div>
      )}

      {contactPerson && (
        <ContactBanner
          caption={contactCaption ? contactCaption : contactPerson.contactCaption}
          title={contactPerson.contactFormTitle}
          person={contactPerson}
          hubspotFormId={contactPerson.hubspotFormId}
          noMarginBottom
        />
      )}
    </Page>
  );
});

StoryCategories.getPageData = (match, env, location) => {
  const categorySlug = dlv(match, 'params.slug', '');
  return get(`/api/page/storyCategories/${categorySlug}`);
};

export default StoryCategories;
