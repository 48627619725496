// vendor
import React, { useState, useRef, useEffect } from 'react';
import cc from 'classcat';
import anime from 'animejs';

// components
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';

// static content
import { useGlossary } from '../../../context/glossaryContext';

// style
import style from './companyAwards.scss';

// animation
import RevealGroup from '../../animation/revealGroup';

function CompanyAwards({ items }) {
  const { t } = useGlossary();
  const min = 10;
  const [open, setOpen] = useState(false);
  const listing = useRef();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (!open) {
      const height = calcHeight(min);
      animate(height);
    }
    if (open) {
      const height = calcHeight(items.length);
      animate(height);
    }
  }, [open]);

  function clickHandler() {
    setOpen(!open);
  }

  function calcHeight(range) {
    let height = 0;
    const allItems = listing.current.querySelectorAll('[data-item]');
    if (range > allItems.length) range = allItems.length;
    for (let i = 0; i < range; i++) {
      height += allItems[i].clientHeight;
    }
    return height;
  }

  function animate(height) {
    anime({
      begin: () => {
        setDisable(true);
      },
      targets: listing.current,
      height: height,
      easing: 'easeInOutCubic',
      duration: 400,
      complete: () => {
        setDisable(false);
      },
    });
  }

  return (
    <RevealGroup>
      <Container
        className={style.container}
        classNameInner={style.inner}
        mw="medium"
        padding="small"
        spaced
      >
        <Heading className={style.heading} level="h3" size="h3">
          {t('company.story.awards.heading')}
        </Heading>
        <HTML className={style.body} component="div">
          {t('company.story.awards.body')}
        </HTML>
        {items && items.length > 0 && (
          <div className={cc([style.list, open && style.open])} ref={listing}>
            {items.map((item, i) => (
              <p className={style.award} key={i.toString()} data-item>
                {item.organisation && (
                  <HTML className={style.title} as="span">
                    {item.organisation}
                  </HTML>
                )}
                {item.award && (
                  <HTML className={style.type} as="span">
                    {item.award}
                  </HTML>
                )}
                {item.client && (
                  <HTML className={style.client} as="span">
                    {item.client}
                  </HTML>
                )}
                {item.year && (
                  <HTML className={style.year} as="span">
                    {item.year}
                  </HTML>
                )}
              </p>
            ))}
          </div>
        )}
        {items && items.length > 0 && (
          <button className={cc([style.button, disable && style.disable])} onClick={clickHandler}>
            {open ? t('company.story.awards.less') : t('company.story.awards.more')}
          </button>
        )}
      </Container>
    </RevealGroup>
  );
}

export default CompanyAwards;
