// vendor
import React, { useContext } from 'react';

// static content
import { useGlossary } from '../../context/glossaryContext';

// components
import Button from '../button/button';
import HTML from '../html/html';
import Link from '../link';
import { gtmContext } from 'dpdk-react-library';

// animation
import BasicRevealItem from '../animation/basicRevealItem';

// style
import style from './consentBar.scss';

function ConsentBar() {
  const { status, types, setStatus } = useContext(gtmContext);
  const { t } = useGlossary();

  return status === types.INDETERMINATE ? (
    <BasicRevealItem delay={600} className={style.container}>
      <div className={style.cookieBar}>
        <div className={style.content}>
          <HTML className={style.heading}>{t('trackingConsent.heading')}</HTML>
          <HTML as="span" className={style.body}>
            {t('trackingConsent.body')}
          </HTML>
          <Link
            className={style.link}
            href="/privacy-policy"
            onClick={() => setStatus(types.REJECTED)}
          >
            {t('trackingConsent.reject')}
          </Link>
        </div>
        <Button
          className={style.button}
          type="button"
          icon="none"
          boxed
          onClick={() => setStatus(types.ACCEPTED)}
        >
          {t('trackingConsent.accept')}
        </Button>
      </div>
    </BasicRevealItem>
  ) : null;
}

export default ConsentBar;
