import dlv from 'dlv';
import React from 'react';
import { useGlossary } from '../../../context/glossaryContext';
import BasicRevealItem from '../../animation/basicRevealItem';
import ContactBanner from '../../contactBanner/contactBanner';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import HTML from '../../html/html';
import Icon from '../../icon/icon';
import Link from '../../link';
import Logo from '../../logo/logo';
import style from './workFooter.scss';

function WorkFooter({ data }) {
  const { t } = useGlossary();
  const { body, services, media } = data.template.highlights;
  const { awards, year } = data;

  return (
    <>
      <Container className={style.container} padding="small" mw="medium">
        <BasicRevealItem className={style.card}>
          <Heading className={style.heading} level="h2" size="h2">
            {t('work.detail.highlights')}
          </Heading>
          {body && <HTML className={style.list}>{body}</HTML>}

          <div className={style.distinctions}>
            {Array.isArray(services) && services.length > 0 && (
              <div className={style.services}>
                <Heading className={style.subheading} level="h3" size="h6" weight="semibold" caps>
                  {t('work.detail.services')}
                </Heading>
                <div className={style.serviceList}>
                  {services.map((service, i) => (
                    <Link
                      href={
                        service.slug
                          ? `/services/${service.slug.replace(/\/service[^s]/, '')}`
                          : '/'
                      }
                      className={style.service}
                      key={i.toString()}
                    >
                      {service.title}
                      <Icon className={style.icon} type="arrowRightSmall" />
                    </Link>
                  ))}
                </div>
              </div>
            )}
            {(awards || media) && (
              <div className={style.other}>
                {awards && ((Object.keys(awards).length !== 1 || awards.awardTerms.length !== 0) && (
                  <div className={style.awards}>
                    <Heading
                      className={style.subheading}
                      level="h5"
                      size="h6"
                      weight="semibold"
                      caps
                    >
                      {t('work.detail.awards')}
                    </Heading>
                    { Object.keys(awards).filter((key) => awards[key].label).map((key, index) => {
                    
                    return (
                        <HTML className={style.item} key={`${key}-${index}`}>
                          {awards[key].label}
                        </HTML>
                      )}
                    )}
                    {awards &&
                      awards.awardTerms &&
                      Object.values(awards.awardTerms).map(
                        (o) =>
                          o.label && (
                            <HTML className={style.item} key={o.label}>
                              {o.label}
                            </HTML>
                          )
                      )}
                  </div>
                ))}
                {Array.isArray(media) && media.length > 0 && (
                  <div className={style.medias}>
                    <Heading
                      className={style.subheading}
                      level="h5"
                      size="h6"
                      weight="semibold"
                      caps
                    >
                      {t('work.detail.press')}
                    </Heading>
                    {media.map((media, i) => {
                      const segments = media.title.split(' | ');
                      const linkText = dlv(segments, '0');
                      const mediumText = dlv(segments, '1', false);
                      return (
                        <div className={style.media} key={`${segments}-${i}`}>
                          <Link href={media.href} className={style.link}>
                            {linkText}
                          </Link>
                          {mediumText && <span className={style.medium}> - {mediumText}</span>}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </BasicRevealItem>

        <BasicRevealItem className={style.label}>
          <Logo className={style.logo} type="boxed" />
          <HTML>{`DPDK | ${year}`}</HTML>
        </BasicRevealItem>
      </Container>

      <ContactBanner
        title={data.title}
        person={data.contactPerson}
        caption={data.contactFormTitle}
        hubspotFormId={data.hubspotFormId}
        footer
      />
    </>
  );
}

export default WorkFooter;
