import React from 'react';
import RevealGroup from '../animation/revealGroup';
// components
import StoriesGridItem from './storiesGridItem/storiesGridItem';
// vendor
import cc from 'classcat';
// style
import style from './storiesGrid.scss';

function StoriesGrid({ items, variation, grayLabel = false }) {
  return (
    items &&
    items.length > 0 && (
      <RevealGroup className={cc([style.stories, style[variation]])} stagger={100}>
        {items.map((item, i) => (
          <StoriesGridItem
            item={item}
            variation={variation}
            i={i}
            key={item.title}
            grayLabel={grayLabel}
            grayBackground
          />
        ))}
      </RevealGroup>
    )
  );
}

export default StoriesGrid;
