// vendor
import React, { memo, useState } from 'react';

import Page from '../../components/page';
import StoriesHeader from '../../components/sections/storiesHeader/storiesHeader';
// components
import StoriesButtons from '../../components/sections/storiesButtons/storiesButtons';
// import
import ContactBanner from '../../components/contactBanner/contactBanner';
import StoriesFeatured from '../../components/sections/storiesFeatured/storiesFeatured';
import StoriesChips from '../../components/sections/storiesChips/storiesChips';
import StoriesGrid from '../../components/storiesGrid/storiesGrid';
import StoriesHeaderItem from '../../components/sections/storiesHeader/storiesHeaderItem/storiesHeaderItem';
// utils
import { get } from '../../utils/request';
// static content
import Tabs from '../../components/sections/Tabs/Tabs';

const LIMIT = 9;

const Stories = memo(({ data }) => {
  const [skip, setSkip] = useState(9);
  const [fetchedStories, setFetchedStories] = useState([]);

  const navLabels = data.navigationLabels || [];
  const title = data.stories_title || null;
  const subtitle = data.stories_subtitle || null;
  const items = data.items || [];
  const categories = data.categories || [];
  const contactCaption = data.contactCaption || null;
  const contactPerson = data.contact || null;
  const itemsTotal = data.itemsTotal || 0;
  const loadedTotal = items.length + fetchedStories.length;

  const pages = [
    {
      href: '/insights',
      label: 'Featured',
      type: 'featured',
    },
    {
      href: '/stories',
      label: 'Stories',
      type: 'stories',
    },
    {
      href: '/whitepapers',
      label: 'Downloads',
      type: 'whitepapers',
    },
  ];

  return (
    <Page newsletterHidden={true}>
      <Tabs current="stories" labels={navLabels} pages={pages} has1400MaxWidth/>
      <StoriesHeader mainHeader={title} />

      {items && (
        <>
          <StoriesFeatured>
            <StoriesHeaderItem title={subtitle ? subtitle : 'JUMP TO'} />
            <StoriesChips categories={categories} whiteBackground={true} />
            <StoriesGrid items={items.concat(fetchedStories)} variation="wide" />
          </StoriesFeatured>

          {itemsTotal > loadedTotal && (
            <StoriesButtons
              loadMore={async () => {
                //up the skip in state by limit
                setSkip(skip + LIMIT);
                //fetch results from api
                const result = await get(`/api/insights?type=stories&limit=${LIMIT}&skip=${skip}`);
                //combine the fetched results from request & state
                setFetchedStories(prev => prev.concat(result.items));
              }}
            />
          )}

          {contactPerson && (
            <ContactBanner
              caption={contactCaption ? contactCaption : contactPerson.contactCaption}
              title={contactPerson.contactFormTitle}
              person={contactPerson}
              hubspotFormId={contactPerson.hubspotFormId}
              noMarginBottom
            />
          )}
        </>
      )}
    </Page>
  );
});

Stories.getPageData = (match, env, location) => {
  return get(`/api/page/stories`);
};

export default Stories;
