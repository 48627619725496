// vendor
import React from 'react';
import cc from 'classcat';
import { string, oneOf, func, node, bool } from 'prop-types';

// components
import Icon from '../icon/icon';
import Link from '../link';

// style
import style from './button.scss';

Button.propTypes = {
  children: node,
  href: string,
  as: string,
  onClick: func,
  display: oneOf(['default', 'start', 'end', 'space']),
  caps: bool,
  boxed: bool,
  rounded: bool,
  hovered: bool,
  icon: oneOf(['none', 'arrowRight', 'arrowBottom', 'play']),
  className: string,
};

function Button({
  children,
  href,
  as,
  onClick,
  display = 'default',
  caps,
  boxed,
  rounded,
  hovered,
  hoverHighlight,
  className,
  icon = 'arrowRight',
  ...rest
}) {
  const Comp = as || (href ? Link : 'button');

  return (
    <Comp
      className={cc([
        style.container,
        style[display],
        boxed && style.boxed,
        rounded && style.rounded,
        caps && style.caps,
        hovered && style.hover,
        hoverHighlight && style.highlight,
        icon === 'arrowBottom' && style.iconBottom,
        className,
      ])}
      href={href}
      onClick={onClick}
      {...rest}
    >
      <span className={style.label}>{children}</span>
      {icon === 'none' || <Icon className={cc([style.icon])} type={icon} />}
    </Comp>
  );
}

export default Button;
