import React, { useRef } from 'react';
import Page from '../components/page';
import { get } from '../utils/request';
import ContactBanner from '../components/contactBanner/contactBanner';
import CapabilityServices from '../components/sections/capabilityServices/capabilityServices';
import OfficeStories from '../components/sections/officeStories/officeStories';
import ClientSlider from '../components/sections/contentSlider/clientSlider';
import HomeWorkGrid from '../components/sections/homeWorkGrid/homeWorkGrid';
import CreativeHeader from '../components/sections/creativeHeader/creativeHeader';
import CreativeLocation from '../components/sections/creativeLocation/creativeLocation';

const Creative = ({ data }) => {
  const contactRef = useRef();

  let selectedStories;

  if (data.cases && data.cases.length > 0) {
    selectedStories = data.cases;

    if (selectedStories.length > 3) {
      selectedStories.splice(3);
    }

    if (selectedStories[0]?.highlighted !== true) {
      selectedStories[0].highlighted = true;
    }

    if (selectedStories[1]?.highlighted || selectedStories[2]?.highlighted === true) {
      selectedStories[1].highlighted = false;
      selectedStories[2].highlighted = false;
    }
  }

  return (
    <Page>
      {data.header_image && (
        <CreativeHeader
          scrollToOfficeSlider={() =>
            contactRef.current && contactRef.current.scrollIntoView({ behavior: 'smooth' })
          }
          headerProps={{
            title: data.pageTitle,
            titleClean: data.pageTitle,
            address: data.address,
            email: data.email,
            telephone: data.telephone,
            timezoneOffset: data.timezoneOffset,
            contactCTALabel: data.contactButtonLabel,

            header_image: data.header_image,
            card_image: data.card_image,
            where_we_started_title: data.introTitle,
            where_we_started: data.intro,
          }}
        />
      )}
      {data.services && data.services.length > 0 && (
        <CapabilityServices
          images={data.servicesImages}
          items={data.services}
          title={data.servicesTitle}
        />
      )}
      {data.testimonials && data.testimonials.length > 0 && (
        <ClientSlider testimonials={data.testimonials} title={data.testimonialsTitle} />
      )}
      {data.cases && <HomeWorkGrid data={selectedStories} title={data.casesTitle} />}
      {data.stories && (
        <OfficeStories storiesContentArray={data.stories} title={data.storiesTitle} />
      )}
      {data.directionsImage && data.directionsTitle && (
        <CreativeLocation
          locationProps={{
            directions_title: data.directionsTitle,
            directions_desc: data.directionsDesc,
            directions_image: data.directionsImage,
            directions_link: data.directionsLink,
            directions_cta_link: data.directionsCtaLink,
          }}
        />
      )}
      {data.contactPerson && (
        <ContactBanner
          ref={contactRef}
          caption={data.contactCaption}
          customTitle={data.contactFormTitle}
          index={1}
          person={data.contactPerson}
          noMarginBottom={true}
        />
      )}
    </Page>
  );
};

Creative.getPageData = (match, env, location) => {
  return get(`/api/page/creative/${match.params.city}`);
};

export default Creative;
