import cc from 'classcat';
import React from 'react';
import { useGlossary } from '../../context/glossaryContext.js';
import Link from '../link';
import style from './disclaimer.scss';

function Disclaimer({ className = '' }) {
  const { t } = useGlossary();
  return (
    <div className={cc([style.container, className])}>
      <span className={style.label}>{t('footer.nav.brand', new Date().getFullYear())}</span>
      <Link className={style.anchor} href="/terms-and-conditions">
        {t('footer.nav.disclaimer')}
      </Link>
      <Link className={style.anchor} href="/privacy-policy">
        {t('footer.nav.privacy')}
      </Link>
    </div>
  );
}

export default Disclaimer;
