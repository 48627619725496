import anime from 'animejs';
import { useEffect, useRef, useState } from 'react';
import { getCSS } from '../utils/animation';
import createDeferredPromise from '../utils/createDeferredPromise';

function useSimpleRevealAnimation(styles, duration = 600, delay = 0) {
  const [hasAnimated, setHasAnimated] = useState(false);
  const ref = useRef();
  function animate() {
    const promise = createDeferredPromise();
    anime({
      targets: ref.current,
      duration,
      easing: 'easeOutCubic',
      delay,
      ...styles.to,
      complete() {
        setHasAnimated(true);
        promise.resolve();
      },
    });
    return promise;
  }

  useEffect(() => {
    if (matchMedia('(prefers-reduced-motion: reduce)').matches) {
      setHasAnimated(true);
    }
  });

  const inlineStyle = getCSS(hasAnimated ? styles.to : styles.from);

  return { animate, inlineStyle, ref };
}

export default useSimpleRevealAnimation;
