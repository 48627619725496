// vendor
import React from 'react';

// components
import BasicContent from '../../basicContent/basicContent';
import Container from '../../container/container';
import Heading from '../../heading/heading';
import CtaBlock from '../../ctaBlock/ctaBlock';

// style
import style from './basicPageContent.scss';

function BasicPageContent({ data }) {
  return (
    <div>
      <Container mw="small" className={style.container}>
        {data.title && <Heading className={style.heading}>{data.title}</Heading>}
      </Container>
      <BasicContent sections={data.content} />

      <Container className={style.containerCtaBlock} mw="medium" padding="large" spaced>
        <CtaBlock className={style.cta} type="combined" />
      </Container>
    </div>
  );
}

export default BasicPageContent;
