// vendor
import React, { useState } from 'react';

// static content
import { useGlossary } from '../../../../context/glossaryContext';

// components
import Heading from '../../../heading/heading';
import HTML from '../../../html/html';
import ReadMore from '../../../readMore/readMore';
import Link from '../../../link';

// animation
import BasicRevealItem from '../../../animation/basicRevealItem';

// style
import style from './approachListItem.scss';

function ApproachListItem({ item, item: { link } }) {
  const { t } = useGlossary();
  const [hover, setHover] = useState(false);

  let href;
  switch (link.entityReference.type) {
    case 'capability':
      href = `/capabilities${link.href.replace('/capability', '')}`;
      break;
    case 'story':
      href = `/stories${link.href ? link.href.replace('/story', '') : ''}`;
      break;
    case 'challenge':
      href = `/challenges${link.href ? link.href.replace('/challenge', '') : ''}`;
      break;
    default:
      href = link.href;
  }

  return (
    <Link href={href} className={style.anchor}>
      <BasicRevealItem
        className={style.approach}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Heading className={style.title} level="h5" size="h5" weight="semibold">
          {item.heading}
        </Heading>
        <div className={style.content}>
          <HTML className={style.intro}>{item.body}</HTML>
          <ReadMore className={style.button} label={t('label.readMore')} hovered={hover} />
        </div>
      </BasicRevealItem>
    </Link>
  );
}

export default ApproachListItem;
